<div class="h-full pb-10 transition-all ease-in-out duration-150 absolute lg:relative z-50 bg-white border-r"
    [ngClass]="{'w-full md:w-64 px-4': utilService.navbarOpen, 'w-0': !utilService.navbarOpen}">

    <div [ngClass]="{'opacity-0 delay-300 hidden': !utilService.navbarOpen}" class="space-y-1 h-full flex flex-col">
        <div class="flex py-4 space-y-2 self-center" [routerLink]="'/'">
            <div class="w-full flex items-center justify-start">
                <img src="assets/images/logo2.png" class="w-32">
            </div>
        </div>

        <div class="flex-1 overflow-y-auto min-h-0 h-full pb-10 space-y-1">
            <div *ngFor="let menu of menuList">
                <ng-container *ngIf="menu.visible && menu.access">
                    <button
                        [ngClass]="{'bg-primary-500 text-white hover:bg-primary-500': activeMainMenu === menu.path &&  !menu.isShowSub, 'hover:bg-gray-200 text-gray-700': activeMainMenu !== menu.path, 'text-black': utilService.navbarOpen && menu.submenu && menu.isShowSub}"
                        class="flex flex-row space-x-2 h-10 w-full items-center px-3 rounded"
                        (click)="menu.submenu ? openSubMenu(menu) : routerPath(menu)">
                        <div class="flex w-7 justify-center items-center">
                            <fa-icon [ngClass]="{'text-white' : activeMainMenu === menu.path && !menu.isShowSub }"
                                class="text-lg text-gray-300 duration-150" [icon]="menu.icon"></fa-icon>
                        </div>
                        <div *ngIf="utilService.navbarOpen" class="w-full flex flex-row items-center justify-between">
                            <div>{{menu.name}}</div>
                            <fa-icon *ngIf="menu.submenu" class="text-xs transition-all ease-in-out duration-300"
                                [icon]="faChevronDown" [ngClass]="{'rotate-180':menu.isShowSub}"></fa-icon>
                        </div>
                    </button>

                    <div class="transition-all ease-in-out duration-500 relative max-h-[1000px] opacity-0"
                        [ngClass]="{'h-fit opacity-100': menu.isShowSub, 'h-0': !menu.isShowSub}">
                        <div class="transition-all ease-in-out duration-300 max-h-0"
                            [ngClass]="{'max-h-[1000px]': menu.isShowSub, 'hidden': !menu.isShowSub}">
                            <ng-container class="" *ngFor="let sub of menu.submenu">
                                <button *ngIf="sub.visible && menu.access"
                                    [ngClass]="{'bg-primary-500 text-white hover:bg-primary-500': activePath === sub.path, '': activePath !== sub.path, 'pl-8' : utilService.navbarOpen}"
                                    class="flex space-x-1 h-10 w-full items-center px-3 rounded hover:bg-gray-200 mt-1 text-black text-sm"
                                    [routerLink]="[sub.path]">
                                    <div class="flex w-6 justify-center items-center">
                                        <fa-icon class="text-base text-gray-300 duration-150"
                                            [ngClass]="{'text-white': activePath === sub.path}"
                                            [icon]="faCaretRight"></fa-icon>
                                    </div>
                                    <ng-container *ngIf="utilService.navbarOpen">
                                        <div>{{sub.name}}</div>
                                    </ng-container>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>


        <div class="flex-none w-full bg-white absolute bottom-0 left-0 flex px-4 py-4">
            @if(utilService.navbarOpen){
            <div class="md:hidden w-full flex space-x-2 justify-center" (click)="switchSidenav()">
                <fa-icon class="" [icon]="faXmark"></fa-icon>
                <div class="">ปิด</div>
            </div>
            }
        </div>
    </div>

</div>
<div [ngClass]="{'fixed lg:hidden top-0 left-0 bg-black opacity-10 w-screen h-screen z-40': utilService.navbarOpen, 'hidden': !utilService.navbarOpen}"
    (click)="switchSidenav()">
</div>