import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent {

  faArrowRightFromBracket = faArrowRightFromBracket;

  user = this.authService.user;

  constructor(
    public authService: AuthService,
    private modalService: ModalService
  ) { }

  logout() {
    this.modalService.openModal('confirm', 'คุณต้องการออกจากระบบ?', '').then(res => {
      if (res) {
        this.authService.logout();
      }
    });

  }

}
