import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UpdateChannelDialogComponent } from '../../components/update-channel-dialog/update-channel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '../../../../core/services/modal/modal.service';

@Component({
  selector: 'app-setting-channel',
  templateUrl: './setting-channel.component.html',
  styleUrl: './setting-channel.component.css'
})
export class SettingChannelComponent implements OnInit {

  channels: any[] = this.platformService?.channels || [];

  constructor(
    public dialog: MatDialog,
    private platformService: PlatformService,
    private modalService: ModalService,
  ) {


  }

  ngOnInit(): void {
    console.log(this.channels);
  }


  openUpdateChannelDialog(channel: any) {
    const dialogRef = this.dialog.open(UpdateChannelDialogComponent, {
      data: channel
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.confirm) {
        this.platformService.updatePlatform(channel.platform_id, result.data).then((res: any) => {
          if (res.success) {
            const index = this.platformService.channels.findIndex(e => e.platform_id === channel.platform_id)
            if (index !== -1) {
              this.platformService.channels[index] = { ...channel, ...result.data }
            }
            this.modalService.openModal('success', 'สำเร็จ', 'อัพเดตร้านค้าสำเร็จ')
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถอัพเดตร้านค้าได้ กรุณาลองใหม่อีกครั้งในภายหลัง')
          }
        })
      }
    });
  }

}
