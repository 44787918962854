<div class="space-y-6">

    <div class="text-sm flex flex-col gap-5">
        @for (group of campaignGroups; track $index) {
        <div class="">
            <div class="flex flex-row gap-4">
                <div class="flex-1">
                    <div class="font-semibold">{{group.name}}</div>
                    <div class="text-gray-500">Description : {{group?.note || '-'}}</div>
                </div>
                <div class="flex-none">
                    <button class="btn-sm btn-light" (click)="openUpdateCampaignDialog(group)">แก้ไข</button>
                </div>
            </div>
            <div class="flex flex-row gap-2">
                <div class="label" [ngStyle]="{'background': group.color}">{{group.color}}</div>
                @for (item of filterPriceCampaigns(group.id); track $index) {
                <div class="label label-secondary">{{item.name}}</div>
                }
            </div>
        </div>
        }
    </div>

    <div class="">
        <button class="btn-sm btn-primary" (click)="openCreateCampaignDialog()">+ เพิ่มแคมเปญ</button>
    </div>

</div>