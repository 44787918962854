import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.css'
})
export class AccessDeniedComponent  implements OnInit {
  request: any = null;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.authService.getUserRequest().then((result: any) => {
      console.log(result)
      if (result.success) {
        this.request = result.data;
      }
    })
  }

  requestAccess() {
    const body = {
      "system_id": environment.systemId,
      "info": {
        "name": this.authService.user?.displayName,
        "image": this.authService.user?.pictureUrl
      }
    }
    this.authService.requestAccess(body).then((result: any) => {
      console.log(result)
      if (result.success) {
        this.request = result.data;
      }
    })

  }

}
