import { Component, OnInit } from '@angular/core';
import { faCaretRight, faChevronDown, faRightFromBracket, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../services/util/util.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthGuardService } from '../../services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit {

  menuList: any = []
  selectSubMenu: any;

  faRightFromBracket = faRightFromBracket;
  faXmark = faXmark;
  faChevronDown = faChevronDown;
  faCaretRight = faCaretRight;


  currentPath: string = '';
  activePath: string = ''
  activeMainMenu: string = ''
  isSet = false

  constructor(
    protected utilService: UtilService,
    private router: Router,
    public authGuardService: AuthGuardService
  ) {
    /* set default asideOpen */
    if (window.innerWidth > 1024) {
      this.utilService.navbarOpen = true;
    } else {
      this.utilService.navbarOpen = false;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.router.url.split('?')[0];
        this.menuList = this.utilService.menuList;
        this.recheck()
      }
    });
  }

  ngOnInit(): void {
    this.menuList = this.utilService.menuList;
    this.menuList.forEach((element: any, index: number) => {
      element.id = index;
      element.isShowSub = false;
    });
    this.recheck()
  }

  recheck() {
    this.currentPath = this.router.url.split('?')[0];
    this.menuList.forEach((element: any) => {
      if (element.path === this.currentPath) {
        this.activePath = element.path
        this.activeMainMenu = element.path
      } else {
        const activeSubmenu = element?.submenu?.find((e: any) => e.path === this.currentPath)
        if (activeSubmenu) {
          this.activePath = activeSubmenu?.activePath || activeSubmenu.path
          this.activeMainMenu = element.path
          if (!this.isSet) {
            element.isShowSub = true;
            this.isSet = true
          }
        }
      }
    });
  }

  switchSidenav(): any {
    this.utilService.navbarOpen = !this.utilService.navbarOpen;
  }

  openSubMenu(menu: any) {
    const index = this.menuList.findIndex((e: any) => e.id === menu.id);
    this.menuList[index].isShowSub = !menu.isShowSub;
  }

  routerPath(menu: any) {
    this.router.navigate([menu.path])
  }


  closeAside(): any {
    this.utilService.navbarOpen = !this.utilService.navbarOpen;
  }

  subMenuCanAccess(submenu: any[]) {
    if (submenu.length === 0) return false
    let canAccess = false
    for (const menu of submenu) {
      if (this.authGuardService.canAccessRoute(menu.path)) {
        canAccess = true
      }
    }
    return canAccess
  }

}
