<div
    class="fixed flex flex-col justify-center items-center w-full h-full top-0 left-0 bg-white z-[100] overflow-y-auto">
    <div class="relative h-2/4 w-full flex justify-center -mt-20">
        <div class="flex flex-col justify-start items-center z-0">
            <img class="max-w-md"
                src="assets/images/403.jpg"
                alt="">
            <div class="text-3xl font-extrabold">
                ขออภัยค่ะ คุณยังไม่มีสิทธิ์ในการเข้าใช้งานระบบ
            </div>
            <div class="h-12 mt-4 md:mt-10 space-x-4">
                <button class="btn btn-primary" *ngIf="request === null" (click)="requestAccess()">
                    ขอสิทธิ์เข้าใช้งานระบบ
                </button>
                <a href="/">
                    <button class="btn btn-secondary">
                        กลับสู่หน้าหลัก
                    </button>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="absolute bottom-3 right-3 sm:bottom-6 sm:right-6 z-[100]">
    <div class="flex flex-col items-end justify-start gap-2 h-14 scale-90">
        <div>
            <img src="../../../assets/images/logo1.png" class="w-36">
        </div>
    </div>
</div>