import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-channel-dialog',
  templateUrl: './update-channel-dialog.component.html',
  styleUrl: './update-channel-dialog.component.css'
})
export class UpdateChannelDialogComponent implements OnInit {

  platform_name: string = '';
  shop_ref: string = '';
  member_id: string = '';
  color: string = '#000000';

  tmpPriceCampaign: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { platform_name: string; shop_ref: string; member_id: string; logo_url: string; color: string },
    private ref: MatDialogRef<UpdateChannelDialogComponent>
  ) {

  }


  ngOnInit(): void {
    this.platform_name = this.data.platform_name;
    this.shop_ref = this.data.shop_ref;
    this.member_id = this.data.member_id;
    this.color = this.data.color;
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    const dataUpdate = {
      platform_name: this.platform_name,
      color: this.color
    }
    this.ref.close({ confirm: true, data: dataUpdate });
  }

}
