<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/hierarchy.svg" alt="" class="w-5">
            </div>
            <div class="">รายการเซ็ตสินค้า</div>
        </div>
        <a class="btn-sm btn-primary w-fit self-end content-center" [routerLink]="['/product-set/create']">
            + เพิ่มสินค้าใหม่
        </a>
    </div>

    <div class="card">
        <form [formGroup]="form" class="flex gap-4">
            <div class="flex-1 grid grid-cols-2 md:grid-cols-4 gap-4">
                <div class="w-full">
                    <label class="text-sm">สินค้า</label>
                    <input class="input" type="text" placeholder="กรอกชื่อ/รหัสเซ็ต/รหัสสินค้า"
                        formControlName="keyword">
                </div>
                <div class="">
                    <label class="text-sm">การใช้งาน</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือกการใช้งาน" formControlName="active">
                            <mat-option>ทั้งหมด</mat-option>
                            <mat-option [value]="true">เปิดใช้งาน</mat-option>
                            <mat-option [value]="false">ยกเลิกใช้งาน</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="">
                    <label class="text-sm">สถานะ</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือกสถานะ" formControlName="price_change">
                            <mat-option>ทั้งหมด</mat-option>
                            <mat-option [value]="true">ทุนเปลี่ยน</mat-option>
                            <mat-option [value]="false">ทุนเท่าเดิม</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="">
                    <label class="text-sm">Check List</label>
                    <mat-form-field class="w-full">
                        <mat-select class="" placeholder="เลือก Check List" formControlName="checked_all">
                            <mat-option>ทั้งหมด</mat-option>
                            <mat-option [value]="true">เสร็จแล้ว</mat-option>
                            <mat-option [value]="false">ยังไม่เสร็จ</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <button class="flex-none btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>

    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && datasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && datasets.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr divide-x">
                            <th class="text-left">
                                SET
                            </th>
                            <th class="text-left">
                                Product
                            </th>
                            <th class="text-right">
                                ราคาขาย
                            </th>
                            <th class="text-right">
                                ราคาเซ็ต
                            </th>
                            <th class="text-right">
                                ราคา SRP/ชิ้น
                            </th>
                            <th class="text-right">
                                ราคา SRP
                            </th>
                            <th class="text-right">
                                ทุน(ช่อง 5)
                            </th>
                            <th class="text-right">
                                ทุน(+{{webConfig.percentSetList}}%)
                            </th>
                            <th class="text-right">
                                ทุนรวม
                            </th>
                            <th class="text-center">
                                Check List
                            </th>
                            <th class="text-right">
                                เปิดใช้งาน
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of datasets; track $index) {
                        <tr class="tbody-tr" [ngClass]="{'!bg-orange-100':productSetDetial?.set_sku === row.set_sku}">
                            <td class="text-left border-r">
                                <div class="">
                                    @if (row.price_change) {
                                    <div class="flex space-x-1">
                                        <div class="hover:text-primary-500 hover:underline hover:cursor-pointer text-primary-500 font-bold"
                                            (click)="showPriceChangeDetail(row.set_sku)">{{row.set_sku}}</div>
                                        <span class="h-2 w-2 rounded-full opacity-75 label-danger"></span>
                                    </div>
                                    }@else {
                                    <div class="hover:text-primary-500 hover:underline hover:cursor-pointer text-primary-500 font-bold"
                                        (click)="showPriceChangeDetail(row.set_sku)">{{row.set_sku}}</div>
                                    }
                                    <div class="max-w-lg">{{row.name}}</div>

                                </div>
                            </td>
                            <td class="text-left border-r">
                                <div class="w-[250px] lg:w-[300px] 2xl:w-full">
                                    @for (product of row.products; track $index) {
                                    <div class="whitespace-nowrap w-full truncate"
                                        matTooltip="{{product.name}} - {{product.sku}}">
                                        x{{product.qty}} {{product.name}}
                                    </div>
                                    }
                                </div>
                            </td>
                            <td class="text-right  border-r">
                                @for (product of row.products; track $index) {
                                <div class="font-semibold"
                                    [ngClass]="{'text-red-500' : product.price<(product.current_cost_percent * product.qty)}">
                                    {{product.price | number}}
                                </div>
                                }
                            </td>
                            <td class="text-right text-primary-500 font-semibold  border-r">
                                {{row.total_price | number}}
                            </td>
                            <td class="text-right  border-r ">
                                @for (product of row.products; track $index) {
                                <div>{{product.srp_price | number}}</div>
                                }
                            </td>
                            <td class="text-right  border-r">
                                {{row.total_srp | number}}
                            </td>
                            <td class="text-right  border-r">
                                @for (product of row.products; track $index) {
                                <div>{{product.cost | number}}</div>
                                }
                            </td>
                            <td class="text-right  border-r">
                                @for (product of row.products; track $index) {
                                <div>{{product.cost_percent | number}}</div>
                                }
                            </td>
                            <td class="text-right text-red-500  border-r">
                                @for (product of row.products; track $index) {
                                <div>{{(product.cost_percent * product.qty ) | number}}</div>
                                }
                            </td>
                            <td class="border-r">
                                @if(row?.check_list?.length > 0) {
                                <div class="w-full flex justify-center">
                                    <button class="flex justify-center items-center space-x-2"
                                        (click)="openCheckLictDialog(row)"
                                        [ngClass]="{'text-lime-600': row.check_list.length === getCheckedCheckList(row.check_list) }">
                                        <fa-icon [icon]="faCheckSquare"></fa-icon>
                                        <div>
                                            {{getCheckedCheckList(row.check_list)}}/{{row.check_list.length}}
                                        </div>
                                    </button>
                                </div>
                                }
                            </td>
                            <td class="text-right">
                                <div class="flex justify-end gap-2">
                                    <app-toggle [id]="'toggle-set-'+row.set_sku" [callbackToggle]="callbackActiveToggle"
                                        [defaultValue]="row.active"></app-toggle>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }
</div>