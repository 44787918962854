<div class="content flex justify-center items-center"
    [ngStyle]="{'height': 'calc(100vh - ' + header.clientHeight + 'px)'}">
    <div class="text-center space-y-6 -mt-14">
        <img class="max-w-md" src="assets/images/404.png" alt="">
        <div>
            <h2 class="font-bold text-2xl">
                Page Not Found
            </h2>
            <div class="text-gray-600">
                Sorry! We could not find this page
            </div>
        </div>
        <div class="">
            <a href="/">
                <button class="btn btn-secondary">
                    กลับสู่หน้าหลัก
                </button>
            </a>
        </div>
    </div>
</div>