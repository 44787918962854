import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  // กำนหนด guard ในส่วนของการใช้งานกับ  canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url; // เก็บ url ที่พยายามจะเข้าใช้งาน
    return this.checkPermission(url, route);
  }

  // ฟังก์ชั่นเช็คสิทธิ์เข้าถึง รับค่า url ที่ผู้ใช้พยายามจะเข้าใช้งาน
  checkPermission(url: string, route: any): any {
    return new Promise((resolve, reject) => {
      if (route.data.expectedRole === undefined) {
        resolve(true);
      } else {
        const login = setInterval(() => {
          if (this.authService.isLogin) {
            clearInterval(login);
            if (!this.authService.user || !this.authService.user.role) {
              this.router.navigate(['/access-denied'], { queryParams: {} })
              resolve(false);
            } else if (this.authService?.user?.role && route.data.expectedRole.indexOf(this.authService.user.role) > -1) {
              resolve(true);
            } else {
              this.router.navigate(['/forbidden'], { queryParams: {} })
              resolve(false);
            }
          }
        }, 100);
      }
    });
  }


}
