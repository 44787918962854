import { Injectable } from '@angular/core';
import { default as dayjs } from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { faAngry, faListAlt, faUser, } from '@fortawesome/free-regular-svg-icons';
import { faBoxOpen, faCartShopping, faChartLine, faGears, faTags } from '@fortawesome/free-solid-svg-icons';
import { PermissionService } from '../permission/permission.service';

dayjs.extend(buddhistEra);
dayjs.extend(relativeTime);
dayjs.locale("th");

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  dayjs = dayjs;
  navbarOpen = false;
  loading = false;

  menuList: any[] = [
    {
      path: '/main',
      name: 'ตัวอย่าง',
      icon: faAngry,
      visible: false,
      access: true
    },
    {
      path: '/profile',
      name: 'ข้อมูลส่วนตัว',
      icon: faUser,
      visible: false,
      access: this.permissionService.activatedRoute('/profile')
    },
    {
      path: '/data-analysis',
      name: 'วิเคราะห์ข้อมูล',
      icon: faChartLine,
      visible: true,
      access: this.permissionService.activatedRoute('/data-analysis')
    },
    {
      path: '/order',
      name: 'คำสั่งซื้อ',
      icon: faListAlt,
      visible: false,
      access: this.permissionService.activatedRoute('/order')
    },
    {
      path: '/product',
      name: 'สินค้า',
      icon: faCartShopping,
      visible: true,
      access: true,
      isShowSub: true,
      submenu: [
        {
          path: "/product/list",
          name: 'รายการสินค้า',
          description: 'รายการสินค้า',
          visible: true,
          access: this.permissionService.activatedRoute('/product/list')
        },
        {
          path: "/product/stock",
          name: 'สต๊อกสินค้า',
          description: 'สต๊อกสินค้า',
          visible: true,
          access: this.permissionService.activatedRoute('/product/stock')
        },
      ],
    },
    {
      path: '/product-set',
      name: 'สินค้าเซ็ต',
      icon: faBoxOpen,
      visible: true,
      access: true,
      isShowSub: true,
      submenu: [
        {
          path: "/product-set/list",
          name: 'รายการเซ็ต',
          description: 'รายการเซ็ต',
          visible: true,
          access: this.permissionService.activatedRoute('/product-set/list')
        },
        {
          path: "/product-set/stock",
          name: 'สต๊อกเซ็ต',
          description: 'สต๊อกเซ็ต',
          visible: true,
          access: this.permissionService.activatedRoute('/product-set/stock')
        },
        {
          path: "/product-set/create",
          name: 'สร้างเซ็ตสินค้า',
          description: 'สร้างเซ็ตสินค้า',
          visible: false,
          access: this.permissionService.activatedRoute('/product-set/create')
        },
        {
          path: "/product-set/detail",
          name: 'รายละเอียดเซ็ตสินค้า',
          description: 'รายละเอียดเซ็ตสินค้า',
          visible: false,
          access: this.permissionService.activatedRoute('/product-set/detail')
        },
      ],
    },
    {
      path: '/price-list',
      name: 'Price List',
      icon: faTags,
      visible: true,
      access: this.permissionService.activatedRoute('/price-list')
    },
    {
      path: '/setting',
      name: 'ตั้งค่า',
      icon: faGears,
      visible: true,
      access: this.permissionService.activatedRoute('/setting')
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      icon: faGears,
      visible: false,
      access: true
    }
  ]

  constructor(
    private permissionService: PermissionService
  ) { }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeToggleNevbar() {
    this.navbarOpen = false
  }

  screenLoader(value: boolean) {
    this.loading = value;
  }

}
