import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PriceListApiService } from '../../services/api/price-list-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import * as ExcelJS from 'exceljs';
import { faArrowUpFromBracket, faChevronLeft, faDownLong, faExclamationCircle, faFileCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { UtilService } from '../../../../core/services/util/util.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-price-list-mapping',
  templateUrl: './price-list-mapping.component.html',
  styleUrl: './price-list-mapping.component.css'
})
export class PriceListMappingComponent implements OnInit {
  faArrowUpFromBracket = faArrowUpFromBracket;
  faFileExcel = faFileExcel;
  faDownLong = faDownLong;
  faFileCircleExclamation = faFileCircleExclamation;
  faExclamationCircle = faExclamationCircle;
  faChevronLeft = faChevronLeft;

  @ViewChild('fileUpload', { static: true, read: ElementRef }) fileUpload: any;

  loading: boolean = false;
  submitted: boolean = false;
  form: UntypedFormGroup = new UntypedFormGroup({
    platform: new UntypedFormControl('', Validators.required),
    campaign_price_id: new UntypedFormControl('', Validators.required),
    column_sku: new UntypedFormControl(''),
    column_platform_sku: new UntypedFormControl(''),
    column_platform_product_id: new UntypedFormControl(''),
    column_result: new UntypedFormControl('', Validators.required),
  });

  excelData: any[] | undefined;
  workbook: ExcelJS.Workbook | null = null;
  columns: { column: string; header: string }[] = [];
  fileName: string = '';
  fileSize: number = 0;
  processing: boolean = false;
  converted: boolean = false;
  newFileName: string = '';
  newFileSize: number = 0;

  campaignGroup: Array<{ id: string; name: string; note: string; priority: number; color: string; }> = [];
  priceCampaign: Array<{ id: string; name: string; campaign_group_id: string; }> = []
  campaignData: Array<{ id: string; name: string; campaign_group_id: string; priority: number; color: string; group_name: string; }> = [];

  constructor(
    private priceListApiService: PriceListApiService,
    private modalService: ModalService,
    private location: Location,
    private utilService: UtilService
  ) {
    this.f['platform'].setValue('shopee')
    this.platformOnchange();

  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.getPriceCampaign(),
      this.getCampaignGroup()
    ])
    this.setFormattingCampaign();
    this.form.get('platform')?.valueChanges.subscribe(() => {
      this.platformOnchange();
    });
  }

  back() {
    this.location.back();
  }

  async getPriceCampaign() {
    await this.priceListApiService.getAllPriceCampaign().then((res: any) => {
      if (res.success) {
        this.priceCampaign = res.data;
      }
    })
  }

  async getCampaignGroup() {
    await this.priceListApiService.getAllCampaignGroup().then((res: any) => {
      if (res.success) {
        this.campaignGroup = res.data;
      }
    })
  }

  setFormattingCampaign() {
    this.campaignData = []
    for (const group of this.campaignGroup) {
      const filteredData = this.priceCampaign.filter(e => e.campaign_group_id === group.id).map(e => ({ ...e, priority: group.priority, color: group.color, group_name: group.name }))
      this.campaignData.push(...filteredData)
    }
    this.campaignData.sort((a, b) => a.priority - b.priority)
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  platformOnchange() {
    if (this.f['platform'].value === 'shopee') {
      this.f['column_sku'].disable();
      this.f['column_platform_sku'].enable();
      this.f['column_platform_product_id'].enable();

      this.f['column_sku'].clearValidators();
      this.f['column_platform_sku'].setValidators(Validators.required);
      this.f['column_platform_product_id'].setValidators(Validators.required);

    } else if (this.f['platform'].value === 'lazada') {
      this.f['column_sku'].enable();
      this.f['column_platform_sku'].disable();
      this.f['column_platform_product_id'].disable();

      this.f['column_sku'].setValidators(Validators.required);
      this.f['column_platform_sku'].clearValidators();
      this.f['column_platform_product_id'].clearValidators();
    }

    this.form.updateValueAndValidity();

  }

  formOnchange() {
    this.updateUppercaseFields();
    this.form.updateValueAndValidity();
  }

  updateUppercaseFields() {
    const controlsToUpdate = [
      'column_sku',
      'column_platform_sku',
      'column_platform_product_id',
      'column_result',
    ];

    controlsToUpdate.forEach((controlName) => {
      const control = this.f[controlName];
      if (control?.value) {
        const value = control.value.toUpperCase();
        control.setValue(value, { emitEvent: false });
      }
    });
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected');
      alert('No file selected')
      return;
    }

    this.fileName = file.name;
    this.fileSize = +((file.size / 1024).toFixed(2));
    this.converted = false;

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const buffer = e.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      this.workbook = workbook;
      const worksheet = workbook.worksheets[0];

      const headers: string[] = [];
      this.columns = [];
      worksheet.getRow(1).eachCell((cell) => {
        const column = cell.address.replace(/[0-9]/g, ''); // ดึงเฉพาะตัวอักษร (column)
        const header = cell.value?.toString() || '';
        headers.push(header);
        this.columns.push({ column: column, header: header, })
      });

      const jsonData: any[] = [];
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return; // ข้ามแถวแรก (header)

        const rowData: any = {};
        row.eachCell((cell, colNumber) => {
          rowData[headers[colNumber - 1]] = cell.value; // colNumber เริ่มจาก 1
          rowData.row = rowNumber;
        });
        jsonData.push(rowData);
      });

      this.excelData = jsonData;
      console.log('Excel data:', this.excelData);
    };

    reader.readAsArrayBuffer(file);
  }

  editCell(cellAddress: string, newValue: any) {
    if (!this.workbook) {
      alert('No workbook loaded. Please upload a file first.');
      return;
    }

    const sheetName = this.workbook.worksheets[0].name;
    const worksheet: any = this.workbook.getWorksheet(sheetName);
    const cell = worksheet.getCell(cellAddress);

    cell.value = newValue;
  }

  exportFile() {
    if (!this.workbook) {
      this.modalService.openModal('info', `เกิดข้อผิดพลาด`, 'ไม่มีการโหลด workbook กรุณาอัปโหลดไฟล์ก่อน')
      return;
    }

    this.workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.newFileName;
      link.click();
    });
  }

  resetForm() {
    this.form.reset()
    this.f['platform'].setValue('shopee')

    this.excelData = undefined;
    this.workbook = null;
    this.columns = [];
    this.fileName = '';
    this.fileSize = 0;
    this.processing = false;
    this.converted = false;
    this.newFileName = '';
    this.newFileSize = 0;
    this.submitted = false;
  }

  async submitForm() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (!this.workbook) {
      this.modalService.openModal('info', `เกิดข้อผิดพลาด`, 'ไม่มีการโหลด workbook กรุณาอัปโหลดไฟล์ก่อน')
      return;
    }

    this.processing = true;
    const value = this.form.value;

    if (this.excelData && value.platform === 'lazada') {
      const key = this.columns.find(e => e.column === value.column_sku)?.header;
      if (key) {
        const filteredData = this.excelData.map((e: any) => {
          if (e[key] && (e[key].startsWith('P') || e[key].startsWith('D'))) {
            return { sku: e[key], row: e.row };
          }
          return null;
        }).filter((item: any) => item !== null);

        const body = {
          price_campaign_id: value.campaign_price_id,
          skus: [...new Set(filteredData.map(e => e?.sku))]
        }
        await this.priceListApiService.getPriceByCampaign(body).then(async (res: any) => {
          if (res.success && res.data?.length > 0) {
            for (const item of res.data) {
              // const row = filteredData.find(e => e?.sku === item.sku)?.row
              // if (row) this.editCell(`${value.column_result}${row}`, item.price_campaign.price);
              const rows = filteredData.filter(e => e?.sku === item.sku)
              for (const el of rows) {
                const row = el?.row
                if (row) this.editCell(`${value.column_result}${row}`, item.price_campaign.price);
              }
            }
            this.newFileName = `ไฟล์แคมเปญ_Lazada_${this.utilService.dayjs().format('YYYY-MM-DDTHH:mm:ss')}.xlsx`
            this.newFileSize = await this.getUpdatedFileSize();
          } else {
            this.modalService.openModal('info', `เกิดข้อผิดพลาด`, 'ไม่สามารถดึงข้อมูลราคาสินค้าได้')
            return;
          }
        })
      }

    }

    if (this.excelData && value.platform === 'shopee') {
      const keyProductId = this.columns.find(e => e.column === value.column_platform_product_id)?.header;
      const keySkuId = this.columns.find(e => e.column === value.column_platform_sku)?.header;
      if (keyProductId && keySkuId) {

        const filteredData: any[] = this.excelData.map((e: any) => {
          if (e[keyProductId] && e[keySkuId] && /^[0-9]+$/.test(e[keyProductId]) && /^[0-9]+$/.test(e[keySkuId])) {
            return { platform_product_id: e[keyProductId], platform_sku: e[keySkuId], row: e.row };
          }
          return null;
        }).filter((item: any) => item !== null);

        const bodyProduct = {
          platform_sku: filteredData.map((e: any) => e.platform_sku).join(','),
          fields: 'sku,platform_sku,platform_product_id'
        }
        const resProduct: any = await this.priceListApiService.getAllProductPlatform(bodyProduct);
        if (!resProduct.success || resProduct.count === 0) {
          this.modalService.openModal('info', `เกิดข้อผิดพลาด`, 'ไม่สามารถดึงรหัสสินค้าได้')
          return;
        }

        const body = {
          price_campaign_id: value.campaign_price_id,
          skus: resProduct.data.map((e: any) => e?.sku)
        }
        await this.priceListApiService.getPriceByCampaign(body).then(async (res: any) => {
          if (res.success && res.data?.length > 0) {
            for (const item of filteredData) {
              const sku = resProduct.data.find((e: any) => e.platform_product_id === item.platform_product_id && e.platform_sku === item.platform_sku)?.sku
              if (sku) {
                const price = res.data.find((e: any) => e.sku === sku)?.price_campaign?.price || '';
                this.editCell(`${value.column_result}${item.row}`, price);
              }
            }
            this.newFileName = `ไฟล์แคมเปญ_Shopee_${this.utilService.dayjs().format('YYYY-MM-DDTHH:mm:ss')}.xlsx`
            this.newFileSize = await this.getUpdatedFileSize();
          } else {
            this.modalService.openModal('info', `เกิดข้อผิดพลาด`, 'ไม่สามารถดึงข้อราคาสินค้าได้')
            return;
          }
        })
      }
    }

    this.processing = false;
    this.converted = true;

  }

  async getUpdatedFileSize(): Promise<number> {
    if (!this.workbook) {
      this.modalService.openModal('info', 'No workbook loaded.', 'Please upload a file first.')
      return 0;
    }

    try {
      const buffer = await this.workbook.xlsx.writeBuffer();
      const sizeInBytes = buffer.byteLength;
      const sizeInKB = +((sizeInBytes / 1024).toFixed(2));
      return sizeInKB;
    } catch (error) {
      console.error('Error calculating file size:', error);
      this.modalService.openModal('info', 'An error occurred while calculating the file size.', '')
      return 0;
    }
  }
}
