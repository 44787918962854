import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faArchive, faCalendar, faCaretDown, faCaretUp, faSort, faXmark } from '@fortawesome/free-solid-svg-icons';
import { LegendPosition } from '@swimlane/ngx-charts';
import { finalize } from 'rxjs/operators';

import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { PuchaseAnalysisService } from '../../services/api/puchase-analysis.service';
import { PlatformGroupService } from '../../../../core/services/platform-group/platform-group.service';
import { CategoryService } from '../../../../core/services/category/category.service';

//=========================================================
// Interfaces
//=========================================================

interface ChartDataset {
  name: string;
  series: Series[];
}

interface Series {
  name: string;
  value: number;
}

interface GroupSummary {
  platform_group_id: string;
  total_amount: number;
  total_qty: number;
}

interface TableDataset {
  sku: string;
  name: string;
  stock: number;
  groups: GroupSummary[];
  total_qty: number;
  total_amount: number;
  avg_qty_per_day: number;
  avg_amount_per_day: number;
  sale_in_day: number;
  tags: string[];
}

interface ProductInfo {
  sku: string;
  name: string;
  in_stock?: number;
  tags: string[];
}

interface ProductSummary {
  platform_group_id: string;
  sku: string;
  total_qty: number;
  total_amount: number;
}

@Component({
  selector: 'app-purchase-analysis-product-overview',
  templateUrl: './purchase-analysis-product-overview.component.html',
  styleUrl: './purchase-analysis-product-overview.component.css'
})
export class PurchaseAnalysisProductOverviewComponent implements OnInit {
  //=========================================================
  // Icons and Constants
  //=========================================================
  readonly faCalendar = faCalendar;
  readonly faCaretDown = faCaretDown;
  readonly faCaretUp = faCaretUp;
  readonly faSort = faSort;
  readonly faXmark = faXmark;
  readonly faArchive = faArchive;
  readonly legendPosition = LegendPosition.Right;

  //=========================================================
  // Platform and Channel Data
  //=========================================================
  channels: any[] = [];
  platformGroup: any[] = [];
  platformGroupFiltered: any[] = [];
  selectedPlatfromGroup: any = [];
  toggleAllPlatformGroup = true;


  //=========================================================
  // filetr tags
  //=========================================================
  tagFiltered: any[] = [];
  selectedTag: any = [];
  toggleAllTag = true;

  //=========================================================
  // Product Data
  //=========================================================
  products: ProductInfo[] = [];
  keyword = '';
  productsFilter: ProductInfo[] = [];
  showProductFilter = false;
  productSelected: ProductInfo[] = [];
  productSummary: ProductSummary[] = [];
  productFiltered: Array<{ sku: string; total_qty: number; total_amount: number, avg_qty_per_day: number, avg_amount_per_day: number }> = [];
  categories: Array<{ id: string; name: string; }> = this.categoryService?.categories || []

  //=========================================================
  // Table Data
  //=========================================================
  tableDatasets: TableDataset[] = [];
  displayData: 'total_qty' | 'total_amount' = 'total_qty';

  //=========================================================
  // Pagination
  //=========================================================
  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  //=========================================================
  // Date Selection
  //=========================================================
  selectedStartDate: any;
  selectedEndDate: any;
  maxDate: any;

  //=========================================================
  // Form and UI State
  //=========================================================
  form: FormGroup;
  loading = false;

  //=========================================================
  // Chart and Performance Data
  //=========================================================
  selectedRow = '';
  period = 30;
  performanceRows: Array<{
    sku: string;
    platform_group_id: string;
    date: string;
    total_amount: number;
    total_qty: number;
  }> = [];
  performanceDatasets: ChartDataset[] = [];
  chartLoaded = false;
  chartDataset: ChartDataset[] = []

  //=========================================================
  // Sort Options
  //=========================================================
  selectedSortFiled = '';
  selectedSortPlatfromGroup = '';
  sortBy: 'max' | 'min' = 'max';

  //=========================================================
  // product Tags
  //=========================================================

  tags: string[] = []

  //=========================================================
  // Constructor and Lifecycle Methods
  //=========================================================
  constructor(
    private purchaseAnalysisService: PuchaseAnalysisService,
    private utilService: UtilService,
    private platformService: PlatformService,
    private fb: FormBuilder,
    private platformGroupService: PlatformGroupService,
    private categoryService: CategoryService
  ) {
    //=========================================================
    // Initialization Methods
    //=========================================================
    this.selectedStartDate = this.utilService.dayjs().subtract(30, 'day').format();
    this.selectedEndDate = this.utilService.dayjs().format();
    this.maxDate = this.utilService.dayjs().format();

    const dateRange = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`;

    this.form = this.fb.group({
      date: [dateRange],
      sku: [''],
      channel: [''],
      category_id: ['']
    });
  }

  ngOnInit(): void {
    this.getProductTag();
    this.initializePlatformData();
    this.getDataReport();
  }

  getProductTag() {
    this.tags = []
    this.purchaseAnalysisService.getProductTag({}).then((res: any) => {
      const tags: Array<{ name: string }> = res?.data || []
      this.tags = tags.map(item => item.name)
    })
  }

  private initializePlatformData(): void {
    this.platformGroup = this.platformGroupService.platformGroup;
    const groupIds = this.platformGroup.map(item => item.id);
    this.channels = this.platformService.channelsAll.filter(item =>
      groupIds.includes(item.platform_group_id)
    );
    this.platformGroupFiltered = this.platformGroup;
  }

  //=========================================================
  // Data Fetching Methods
  //=========================================================
  /**
   * หลักการทำงานหลักในการดึงข้อมูลรายงาน
   */
  getDataReport(): void {
    this.loading = true;
    const formValues = this.form.getRawValue();
    const filter = this.buildReportFilter(formValues);

    // รีเซ็ตข้อมูลก่อนเรียก API
    this.resetReportData();

    this.purchaseAnalysisService.getProductSummaryReportByGroup(filter)
      .then(async (res: any) => {
        if (res.success) {
          this.processSuccessResponse(res.data);

          // ถ้ามีข้อมูลสินค้า ให้ดึงรายละเอียดสินค้า
          const uniqueSku = [...new Set(this.productFiltered.map(e => e.sku))];
          if (uniqueSku.length > 0) {
            await this.findAllProduct({ sku: uniqueSku.join(), filed: 'sku,name' });
          }
        }
        this.setFormattingData();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * ดึงข้อมูลสรุปรายวันของสินค้า
   */
  getAllSummaryReport(sku: string): void {
    this.chartLoaded = false;
    const startDate = this.utilService.dayjs().subtract(this.period, 'day').format('YYYY-MM-DD');
    const endDate = this.utilService.dayjs().format('YYYY-MM-DD');
    let filters: any = {
      sku,
      date: `${startDate},${endDate}`
    };
    if (this.selectedPlatfromGroup?.length > 0) {
      filters['platform_group_id'] = this.selectedPlatfromGroup.toString()
    } else {
      filters['platform_group_id'] = this.platformGroup.map((item) => item.id).toString()
    }
    console.log(filters)
    this.purchaseAnalysisService.getProductDailyReportByGroup(filters)
      .then((res: any) => {
        if (res.success) {
          this.performanceRows = res.data;
          this.setFormattingPerformanceChart();
        } else {
          this.performanceRows = [];
        }
      })
      .finally(() => {
        this.chartLoaded = true;
      });

    // Mock data for demonstration
    setTimeout(() => {
      this.chartLoaded = true;
    }, 500);
  }

  /**
   * ค้นหาสินค้าตาม keyword
   */
  async searchProduct(keyword: string): Promise<ProductInfo[]> {
    try {
      const response: any = await this.purchaseAnalysisService.searchProduct({
        keyword,
        limit: 20
      });

      return response.success ? response.data : [];
    } catch (error) {
      console.error('Error searching products:', error);
      return [];
    }
  }

  /**
   * ดึงข้อมูลสินค้าทั้งหมดตาม filter
   */
  async findAllProduct(filter: any): Promise<void> {
    try {
      const response: any = await this.purchaseAnalysisService.getAllProduct(filter);
      this.products = response.success ? response.data : [];
    } catch (error) {
      console.error('Error finding products:', error);
      this.products = [];
    }
  }

  //=========================================================
  // Filter and Parameter Building Methods
  //=========================================================
  /**
   * สร้าง filter สำหรับดึงข้อมูลรายงาน
   */
  private buildReportFilter(formValues: any): any {
    const filter: any = {
      limit: this.limit,
      offset: this.offset,
      sort_field: this.displayData,
      sort_by: this.sortBy
    };

    if (formValues.date) {
      filter.date = formValues.date;
    }

    if (this.selectedPlatfromGroup?.length > 0) {
      filter.platform_group_id = this.selectedPlatfromGroup.join();
      this.platformGroupFiltered = this.platformGroup.filter(group =>
        this.selectedPlatfromGroup.includes(group.id)
      );
    } else {
      filter.platform_group_id = this.platformGroup.map(group => group.id).toString();
      this.platformGroupFiltered = this.platformGroup;
    }

    if (this.productSelected.length > 0) {
      filter.sku = this.productSelected.map(e => e.sku).join();
    }

    if (this.selectedSortFiled) {
      filter.sort_field = this.selectedSortFiled;
    }

    if (this.selectedSortPlatfromGroup) {
      filter.sort_platform_group_id = this.selectedSortPlatfromGroup;
    }

    if (this.form?.getRawValue()?.category_id) {
      filter.category_id = this.form?.getRawValue()?.category_id
    }

    if (this.selectedTag?.length > 0) {
      filter.tags = this.selectedTag.join()
    }

    return filter;
  }

  /**
   * รีเซ็ตข้อมูลก่อนดึงข้อมูลใหม่
   */
  private resetReportData(): void {
    this.productSummary = [];
    this.productFiltered = [];
    this.countDataSource = 0;
    this.selectedRow = '';
  }

  /**
   * ประมวลผลข้อมูลที่ได้จาก API
   */
  private processSuccessResponse(data: any): void {
    this.productFiltered = data.products;
    this.productSummary = data.summary;
    this.countDataSource = data.count;
  }

  //=========================================================
  // Data Formatting Methods
  //=========================================================
  /**
   * จัดรูปแบบข้อมูลสำหรับแสดงในตาราง
   */
  setFormattingData(): void {
    this.tableDatasets = [];
    this.chartDataset = []
    this.chartDataset = this.formatGraphData(this.productSummary)
    console.log(this.chartDataset)

    for (const product of this.productFiltered) {
      const productInfo = this.products.find(p => p.sku === product.sku);
      const groups: GroupSummary[] = this.platformGroupFiltered.map(group => {
        const summary = this.productSummary.find(e => e.platform_group_id === group.id && e.sku === product.sku);
        return {
          platform_group_id: group.id,
          total_amount: summary?.total_amount || 0,
          total_qty: summary?.total_qty || 0
        };
      })

      this.tableDatasets.push({
        sku: product.sku,
        stock: productInfo?.in_stock || 0,
        name: productInfo?.name || '',
        groups,
        total_qty: product.total_qty,
        total_amount: product.total_amount,
        avg_qty_per_day: product.avg_qty_per_day,
        avg_amount_per_day: product.avg_amount_per_day,
        sale_in_day: Math.round((productInfo?.in_stock || 0) / product.avg_qty_per_day),
        tags: productInfo?.tags || []
      });
    }
    this.tableDatasets.push({
      name: 'รวม',
      sku: 'NULL',
      stock: this.products.filter(item => this.productFiltered.find(el => el.sku === item.sku)).reduce((n, { in_stock }) => n + (in_stock || 0), 0),
      groups: this.platformGroupFiltered.map(e => ({
        platform_group_id: e.id,
        total_amount: this.productSummary.filter(p => p.platform_group_id === e.id).reduce((n, { total_amount }) => n + total_amount, 0),
        total_qty: this.productSummary.filter(p => p.platform_group_id === e.id).reduce((n, { total_qty }) => n + total_qty, 0)
      })),
      total_amount: this.productFiltered.reduce((n, { total_amount }) => n + total_amount, 0),
      total_qty: this.productFiltered.reduce((n, { total_qty }) => n + total_qty, 0),
      avg_amount_per_day: 0,
      avg_qty_per_day: 0,
      sale_in_day: 0,
      tags: []
    })
    console.log(this.tableDatasets)
  }

  formatGraphData(rawData: any[]) {
    return rawData.reduce((acc, item) => {
      let group = acc.find((d: any) => d.name === item.platform_group_id);
      if (!group) {
        group = { name: this.platformGroup.find(el => el.id === item.platform_group_id)?.name, series: [] };
        acc.push(group);
      }
      group.series.push({
        name: this.products.find(el => el.sku === item.sku)?.name,
        value: item[`${this.displayData}`]
      });
      return acc;
    }, []);
  }

  /**
   * จัดรูปแบบข้อมูลสำหรับแสดงในกราฟ
   */
  setFormattingPerformanceChart(): void {
    this.performanceDatasets = [];

    for (const group of this.platformGroup) {
      console.log(group)
      const groupChannelData = this.performanceRows.filter(e => e.platform_group_id === group.id);
      console.log(groupChannelData)
      const performanceByDate = this.groupPerformanceDataByDate(groupChannelData);
      const series: Series[] = this.generateTimeSeriesData(performanceByDate);

      this.performanceDatasets.push({
        name: group.name,
        series
      });
    }
  }


  /**
   * จัดกลุ่มข้อมูลประสิทธิภาพตามวันที่
   */
  private groupPerformanceDataByDate(channelData: any[]): Record<string, any> {
    return channelData.reduce((acc, item) => ({
      ...acc,
      [item.date]: item
    }), {});
  }

  /**
   * สร้างข้อมูลอนุกรมเวลาสำหรับกราฟ
   */
  private generateTimeSeriesData(performanceByDate: Record<string, any>): Series[] {
    const series: Series[] = [];

    for (let index = this.period; index >= 0; index--) {
      const date = this.utilService.dayjs().subtract(index, 'day').format('YYYY-MM-DD');
      const value = this.displayData === 'total_qty'
        ? (performanceByDate[date]?.total_qty || 0)
        : (performanceByDate[date]?.total_amount || 0);

      series.push({ name: date, value });
    }

    return series;
  }

  //=========================================================
  // Event Handlers - Platform Group
  //=========================================================
  /**
   * ลบกลุ่มแพลตฟอร์มที่เลือก
   */
  removePlatfromGroupSelected(group: any): void {
    this.selectedPlatfromGroup = this.selectedPlatfromGroup.filter((id: any) => id !== group.id);

    if (this.selectedPlatfromGroup.length === 0) {
      this.toggleAllPlatformGroupSelection();
    }
  }

  /**
   * ลบกลุ่มแพลตฟอร์มที่เลือกทั้งหมด
   */
  removePlatfromGroupSelectedAll(): void {
    this.toggleAllPlatformGroupSelection();
  }

  /**
   * แสดงกลุ่มแพลตฟอร์มที่เลือก
   */
  displayPlatfromGroup(): any[] {
    return this.platformGroup.filter(g => this.selectedPlatfromGroup.includes(g.id));
  }

  /**
   * สลับการเลือกกลุ่มแพลตฟอร์มทั้งหมด
   */
  toggleAllPlatformGroupSelection(): void {
    this.toggleAllPlatformGroup = !this.toggleAllPlatformGroup;

    if (this.toggleAllPlatformGroup) {
      this.selectedPlatfromGroup = [];
    }
  }

  toggleAllTagSelection(): void {
    this.toggleAllTag = !this.toggleAllTag;

    if (this.toggleAllTag) {
      this.selectedTag = [];
    }
  }

  /**
   * สร้างสตริงของกลุ่มแพลตฟอร์มที่เลือก
   */
  formCreateStringPlatformGroup(value: any): void {
    this.selectedPlatfromGroup = value;
    console.log(this.selectedPlatfromGroup)
  }

  formCreateStringTag(value: any): void {
    this.selectedTag = value;
    console.log(this.selectedTag)
  }

  //=========================================================
  // Event Handlers - Product Selection
  //=========================================================
  /**
   * ค้นหาสินค้าเมื่อกรอกคีย์เวิร์ด
   */
  async productSearch(): Promise<void> {
    if (this.keyword.length > 2) {
      const selectedSkus = this.productSelected.map(e => e.sku);
      const searchResults = await this.searchProduct(this.keyword);

      this.productsFilter = searchResults.filter(product =>
        !selectedSkus.includes(product.sku)
      );

      this.showProductFilter = this.productsFilter.length > 0;
    } else {
      this.showProductFilter = false;
    }
  }

  /**
   * เลือกสินค้า
   */
  selectProduct(product: any): void {
    this.productSelected.push(product);
    this.productsFilter = this.productsFilter.filter(p => p.sku !== product.sku);

    if (this.productsFilter.length === 0) {
      this.showProductFilter = false;
    }
  }

  /**
   * ลบสินค้าที่เลือก
   */
  removeProductSelected(product: any): void {
    this.productSelected = this.productSelected.filter(p => p.sku !== product.sku);
  }

  /**
   * ลบสินค้าที่เลือกทั้งหมด
   */
  removeProductSelectedAll(): void {
    this.productSelected = [];
  }

  /**
   * ล้างการค้นหา
   */
  clearSearch(): void {
    this.keyword = '';
    this.showProductFilter = false;
  }

  //=========================================================
  // Event Handlers - Date Selection
  //=========================================================
  /**
   * เมื่อเลือกช่วงวันที่
   */
  onDateRangeSelected(): void {
    if (this.selectedEndDate && this.selectedStartDate) {
      const dateRange = `${this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')},${this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')}`;
      this.form.get('date')?.setValue(dateRange);
      this.form.get('date')?.updateValueAndValidity();
    }
  }

  /**
   * จัดรูปแบบวันที่สำหรับแสดงผล
   */
  onDateSelected(date: any): string {
    return this.utilService.dayjs(date).format('DD-MM-YYYY');
  }

  //=========================================================
  // Event Handlers - Table and Display
  //=========================================================
  /**
   * เปลี่ยนการแสดงผลข้อมูล (จำนวนชิ้น/มูลค่า)
   */
  displayDataOnchange(event: any): void {
    this.displayData = event.value;
    this.getDataReport();
  }

  /**
   * เมื่อเลือกแถวในตาราง
   */
  selectedRowOnChange(row: any): void {
    if (this.selectedRow === row.sku) {
      this.selectedRow = '';
    } else {
      this.selectedRow = row.sku;
      this.getAllSummaryReport(this.selectedRow);
    }
  }

  /**
   * เรียงลำดับข้อมูล
   */
  sortData(sortFiled: string): void {
    if (this.selectedSortFiled !== sortFiled) {
      this.selectedSortFiled = sortFiled;
      this.sortBy = 'max';
    } else {
      if (this.selectedSortPlatfromGroup) {
        this.selectedSortPlatfromGroup = '';
        this.sortBy = 'max';
      } else {
        this.sortBy = this.sortBy === 'max' ? 'min' : 'max';
      }
    }
    this.getDataReport();
  }

  sortByPlatformGroup(sortFiled: string): void {
    if (this.selectedSortPlatfromGroup !== sortFiled) {
      this.selectedSortPlatfromGroup = sortFiled;
      this.sortBy = 'max';
    } else {
      this.sortBy = this.sortBy === 'max' ? 'min' : 'max';
    }
    this.getDataReport();
  }

  //=========================================================
  // Event Handlers - Form and Pagination
  //=========================================================
  /**
   * ส่งฟอร์ม
   */
  submitForm(): void {
    this.offset = 0;
    this.pageIndex = 0;
    this.selectedSortFiled = '';
    this.selectedSortPlatfromGroup = '';
    this.sortBy = 'max';
    this.getDataReport();
  }

  /**
   * Callback เมื่อเปลี่ยนหน้า
   */
  callBackPageChange = (data: any): void => {
    let shouldRefresh = false;

    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      shouldRefresh = true;
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }

    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }

    if (shouldRefresh) {
      this.getDataReport();
    }
  }

  tagIncludes(tag: string, sku: string) {
    const tags = this.products.find(item => item.sku === sku)?.tags || []
    return tags.includes(tag)
  }

  async addTag(sku: string, tag: string) {
    const product: any = await this.purchaseAnalysisService.getAllProduct({ sku: sku })
    if (product.success) {
      if (product?.data?.length) {
        const productDetial = product.data[0]
        const tags: string[] = productDetial?.tags || []
        if (tags.includes(tag)) {
          const result: any = await this.purchaseAnalysisService.removeProductTag({ sku, tag })
          const index = this.products.findIndex(item => item.sku === result.data.sku)
          this.products[index] = result.data
        } else {
          const result: any = await this.purchaseAnalysisService.addProductTag({ sku, tag })
          const index = this.products.findIndex(item => item.sku === result.data.sku)
          this.products[index] = result.data
        }
      }
    }
  }
}