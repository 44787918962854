<div class="bg-white w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="font-medium">
        อัพเดตร้านค้า
    </div>

    <div class="flex flex-col gap-4 w-full ">
        <div class="relative w-full">
            <label class="text-sm">ชื่อร้านค้า *</label>
            <input type="text" id="platform-name" class="input" autocomplete="off" [(ngModel)]="platform_name">
        </div>
        <div class="relative w-full">
            <label class="text-sm">รหัสร้านค้า</label>
            <input type="text" id="shop-ref" class="input" autocomplete="off" [(ngModel)]="shop_ref" disabled>
        </div>
        <div class="relative w-full">
            <label class="text-sm">รหัสสมาชิก</label>
            <input type="text" id="member-id" class="input" autocomplete="off" [(ngModel)]="member_id" disabled>
        </div>
        <div class="relative w-full">
            <label class="text-sm">Color *</label>
            <input type="color" id="color" name="color" [(ngModel)]="color" />
        </div>

    </div>

    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-dialog-close class="btn-sm btn-light min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]"  [disabled]="!platform_name"(click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>