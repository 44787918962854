<div class="bg-white w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div class="font-medium">
        อัพเดตแคมเปญ
    </div>

    <div class="flex flex-col gap-4 w-full ">
        <div class="relative w-full">
            <label class="text-sm">ชื่อแคมเปญ *</label>
            <input type="text" id="campaign-name" class="input" autocomplete="off" [(ngModel)]="name">
        </div>
        <div class="relative w-full">
            <label class="text-sm">Description</label>
            <textarea class="text-area" name="campaign-note" id="campaign-note" rows="3"
            [(ngModel)]="note"></textarea>
        </div>
        <div class="relative w-full">
            <label class="text-sm">Color *</label>
            <input type="color" id="campaign-color" name="campaign-color" [(ngModel)]="color" />
        </div>

        <div class="relative w-full">
            <label class="text-sm">ช่องราคา</label>
            <div class="flex flex-col gap-1">
                @for (item of tmpPriceCampaign; track $index) {
                <div class="flex flex-row gap-2">
                    <input type="text" id="price-campaign-name" class="input" autocomplete="off" [(ngModel)]="item.name">
                    <button [disabled]="!item.id.startsWith('new_')" class="flex-none btn-danger !w-9 !h-9 rounded-lg text-sm" (click)="removePrice(item.id)">ลบ</button>
                </div>
                }
                <div class="flex flex-row gap-2">
                    <button class="btn-sm btn-light w-full" (click)="addPrice()">+ เพิ่มช่องราคา</button>
                </div>
            </div>
        </div>

    </div>

    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-dialog-close class="btn-sm btn-light min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]"  [disabled]="!name"(click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>