<div class="content space-y-4">

    <div class="flex font-medium text-primary-400 gap-2">
        <div class="">
            <img src="assets/svg/analytics.svg" alt="" class="w-5">
        </div>
        <div class="">การวิเคราะห์ข้อมูลเชิงลึก</div>
    </div>

    <div class="w-full text-base font-medium text-center text-gray-500 border-b border-gray-200">
        <div class="w-full md:w-fit flex flex-wrap -mb-px">
            <button class="flex-1 me-2" (click)="state='sale'">
                <p [ngClass]="{'text-black font-semibold border-primary-500':state==='sale' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='sale'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    ยอดขาย</p>
            </button>
            <button class="flex-1 me-2" (click)="state='product-overview'">
                <p [ngClass]="{'text-black font-semibold border-primary-500':state==='product-overview' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='product-overview'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    ภาพรวมสินค้า</p>
            </button>
            <button class="flex-1 me-2" (click)="state='product'">
                <p [ngClass]="{'text-black font-semibold border-primary-500':state==='product' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='product'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    สินค้า</p>
            </button>
            <button class="flex-1 me-2" (click)="state='product-set'">
                <p [ngClass]="{'text-black font-semibold border-primary-500':state==='product-set' , 'border-transparent hover:text-gray-600 hover:border-gray-300':state!=='product-set'}"
                    class="inline-block whitespace-nowrap p-4 border-b-2 rounded-t-lg">
                    เซ็ตสินค้า</p>
            </button>
        </div>
    </div>

    @if(state==='sale'){
    <app-data-analysis-sale></app-data-analysis-sale>
    }
    @if(state==='product-overview'){
    <app-data-analysis-product-overview></app-data-analysis-product-overview>
    }
    @if(state==='product'){
    <app-data-analysis-product></app-data-analysis-product>
    }
    @if(state==='product-set'){
        <app-data-analysis-product-set></app-data-analysis-product-set>
    }

</div>

