<div class="content space-y-4">

    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/tags.svg" alt="" class="w-5">
            </div>
            <div class="">Price List</div>
        </div>
        <div class="flex gap-2">
            @if (canAccess()) {
            <button class="btn-sm btn-primary w-fit self-end content-center"
                (click)="openValidateDialog()">ตรวสอบราคา</button>
            }
        </div>
    </div>

    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสสินค้า" formControlName="keyword">
            </div>
            <div class="">
                <label class="text-sm">การใช้งาน</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกการใช้งาน" formControlName="visible">
                        <mat-option value="">ทั้งหมด</mat-option>
                        <mat-option value="true">เปิดใช้งาน</mat-option>
                        <mat-option value="false">ปิดใช้งาน</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="">
                <label class="text-sm">ราคาแคมเปญ</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกราคาแคมเปญ" formControlName="is_price">
                        <mat-option value="">ทั้งหมด</mat-option>
                        <mat-option value="true">มีราคาแคมเปญ</mat-option>
                        <mat-option value="false">ยังไม่มีราคาแคมเปญ</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>


    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && dataSource.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && dataSource.length > 0){
    <div class="card">
        <div class="flex justify-end gap-2">
            <div class="flex space-x-2 items-center">
                <div class="text-sm whitespace-nowrap">การแสดงผล : </div>
                <mat-form-field class="w-full">
                    <mat-select class="" (selectionChange)="formCreateString($event.value)" [value]='fieldSelected'
                        multiple>
                        @for (item of displayedColumns; track $index) {
                        <mat-option class="s" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mt-2">
            <div class="div-table">
                <table>
                    <thead>
                        <tr>
                            <th rowspan="2" class="border-r sticky left-0 w-[320px] truncate bg-inherit"
                                style="background-color: #f7d7d7;">รายการ</th>
                            <th rowspan="2" class="border-r" style="background-color: #fffae9;">Date</th>
                            @for (item of displayedColumns; track $index) {
                            @if(checkDisplayColumn(item.id)){
                            <th [attr.rowspan]="item.rowspan" [attr.colspan]="getColSpan(item.id)" class="border-r"
                                [ngStyle]="{'background-color': item.color}">
                                {{item.name}}
                                <fa-icon *ngIf="item.note" [icon]="faQuestionCircle" class="text-gray-500"
                                    [matTooltip]="item.note" matTooltipClass="text-sm"></fa-icon>
                            </th>
                            }
                            }
                            <th rowspan="2" class="border-r">Note</th>
                            <th rowspan="2">การใช้งาน</th>
                        </tr>
                        <tr>
                            @for (item of campaignData; track $index) {
                            <th *ngIf="checkDisplayColumn(item.campaign_group_id)" class="border-r text-xs"
                                [ngStyle]="{'background-color': item.color}">{{item.name}}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        @for (product of dataSource; track $index) {
                        <tr class="tbody-tr">
                            <td class="sticky left-0 w-[320px] bg-inherit border-r !border-b !border-t">
                                <div class="w-[320px] flex items-center">
                                    <button (click)="addPriceList(product)" class="mr-2"> <fa-icon [icon]="faPlusCircle"
                                            class="text-primary-500 text-lg"></fa-icon></button>
                                    <div class="w-full truncate flex items-center cursor-pointer" (click)="selectedRowOnChange(product)"
                                        [matTooltip]="product.name" matTooltipClass="text-sm">
                                        <img [src]="'https://storage.maxxgadget.com/img/product/' + product.sku + '/1.jpg'"
                                            alt="" class="flex-none w-8 h-8 mr-2"
                                            onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                        <div class="w-full flex flex-col truncate">
                                            <div class="w-full truncate whitespace-nowrap">{{product.name}}</div>
                                            <div class="flex gap-2 items-center">
                                                <span class="text-xs pr-2 text-blue-500">{{product.sku}}</span>
                                                <span *ngIf="!product.visible"
                                                    class="label label-danger">ปิดใช้งาน</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="border-r">
                                <div class="whitespace-nowrap">{{dateFormat(product.created.date)}}</div>
                            </td>
                            <td *ngIf="checkDisplayColumn('retail_price')" class="border-r text-right">
                                <button *ngIf="!isEditing[product.id + '' + 'retail']"
                                    class="text-right font-semibold !w-16" [disabled]="!canAccess()"
                                    [ngClass]="{'text-black': product.retail_price > 0, 'text-red-500': product.retail_price == 0 || !product.retail_price}"
                                    (click)="toggleEditPrice(product.id, 'retail')">
                                    {{product.retail_price | number}}
                                </button>

                                <input *ngIf="isEditing[product.id + '' + 'retail']" type="number" id="price-add"
                                    class="input !w-16 text-right" autocomplete="off" [(ngModel)]="price" [min]="0"
                                    [placeholder]="product.retail_price | number"
                                    (focusout)="toggleEditPrice(product.id, 'retail')"
                                    (keyup.enter)="updateRetail(product.id)" />
                            </td>
                            <td *ngIf="checkDisplayColumn('online_price')" class="border-r text-right">
                                <div class="">{{product.online_price | number}}</div>
                            </td>
                            <td *ngIf="checkDisplayColumn('cost')" class="border-r text-right">
                                <div class="">{{product.cost | number}}</div>
                            </td>
                            <td *ngIf="checkDisplayColumn('cost_platform')" class="border-r text-right">
                                <div class="">{{calculateCostPlatform(product.cost) | number}}</div>
                            </td>
                            @for (campaign of priceCampaign; track $index) {
                            <td *ngIf="checkDisplayColumn(campaign.campaign_group_id)" class="border-r text-right">
                                <button *ngIf="!isEditing[product.id + '' + campaign.id]"
                                    class="text-right font-semibold !w-16" [disabled]="!canAccess()"
                                    [ngClass]="{'text-black': getProductPrice(product.price_campaign, campaign.id) > 0, 'text-red-500': getProductPrice(product.price_campaign, campaign.id) == 0 || !getProductPrice(product.price_campaign, campaign.id)}"
                                    (click)="toggleEditPrice(product.id, campaign.id)">
                                    {{ getProductPrice(product.price_campaign, campaign.id) | number }}
                                </button>

                                <input *ngIf="isEditing[product.id + '' + campaign.id]" type="number" id="price-add"
                                    class="input !w-16 text-right" autocomplete="off" [(ngModel)]="price" [min]="0"
                                    [placeholder]="getProductPrice(product.price_campaign, campaign.id) | number"
                                    (focusout)="toggleEditPrice(product.id, campaign.id)"
                                    (keyup.enter)="updatePrice(product.id, campaign.id)" />
                            </td>
                            }
                            <td class="border-r w-fit">
                                <div class="w-max text-red-500 cursor-pointer"
                                    (click)="openNoteDialog(product.id,product.note)"
                                    [innerHTML]="setFormattingNote(product?.note)"></div>
                            </td>
                            <td class="w-fit">
                                <app-toggle [id]="'toggle-'+product.id" [callbackToggle]="callbackActiveToggle"
                                    [defaultValue]="product.visible"></app-toggle>
                            </td>
                        </tr>

                        <tr class="text-gray-600/60">
                            @if (selectedRow === product.id && selectedPriceList.length === 0){
                            <td class="sticky left-0 w-[320px] bg-white"> ไม่มีการแก้ไขราคา </td>
                            <td colspan="100"></td>
                            }
                            @else if (selectedRow === product.id && selectedPriceList.length > 0) {
                            <ng-container *ngTemplateOutlet="rowPriceListTemplate"> </ng-container>
                            }
                        </tr>

                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }

</div>




<ng-template #rowPriceListTemplate>
    <td class="sticky left-0 w-[320px] bg-white border-r">
        ...
    </td>
    <td class="border-r">
        @for (row of selectedPriceList; track $index) {
        <div class="whitespace-nowrap">{{dateFormat(row.created.date)}}</div>
        }
    </td>
    <td *ngIf="checkDisplayColumn('retail_price')" class="border-r text-right">
        @for (row of selectedPriceList; track $index){
        <div>{{row.retail_price | number}}</div>
        }
    </td>
    <td *ngIf="checkDisplayColumn('online_price')" class="border-r text-right">
        @for (row of selectedPriceList; track $index){
        <div>{{row.online_price | number}}</div>
        }
    </td>
    <td *ngIf="checkDisplayColumn('cost')" class="border-r text-right">
        @for (row of selectedPriceList; track $index){
        <div>{{row.cost | number}}</div>
        }
    </td>
    <td *ngIf="checkDisplayColumn('cost_platform')" class="border-r text-right">
        @for (row of selectedPriceList; track $index){
        <div>{{calculateCostPlatform(row.cost) | number}}</div>
        }
    </td>
    @for (campaign of priceCampaign; track $index) {
    <td *ngIf="checkDisplayColumn(campaign.campaign_group_id)" class="border-r text-right">
        @for (row of selectedPriceList; track $index){
        <div>{{ getProductPrice(row.price_campaign,
            campaign.id) | number }}</div>
        }
    </td>
    }
    <td class="border-r text-left">
        @for (row of selectedPriceList; track $index){
        <div class="whitespace-nowrap text-red-500">{{row?.note || '-'}}</div>
        }
    </td>
    <td></td>
</ng-template>