import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.css'
})
export class ToggleComponent implements OnInit {
  
  @Input()
  callbackToggle!: ((args: any, id: string) => void);
  @Input()
  defaultValue: boolean;
  @Input()
  id: string;

  constructor() {
    this.defaultValue = false;
    this.id = 'toggle'
  }

  ngOnInit(): void {

  }

}
