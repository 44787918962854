<div class=" bg-white w-screen max-w-lg h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        {{modalName}}
    </div>

    <div class="flex flex-col gap-4 w-screen max-w-lg text-sm md:text-base px-6">
        @if (type === 'textarea') {
        <textarea class="text-area" name="" id="" rows="4" [(ngModel)]="value"></textarea>
        }@else {
        <input class="text-area" name="" id="" rows="4" [(ngModel)]="value">
        }
        @if (displayCheck) {
        <div class="w-full flex space-x-2">
            <input type="checkbox" name="hs-checked-checkbox" id="hs-checked-checkbox" [(ngModel)]="updateAll"
                class="" />
            <label for="hs-checked-checkbox" class="">{{checkName}}</label>
        </div>
        }
    </div>

    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-dialog-close class="btn-sm btn-light min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>