import { Component } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { PuchaseAnalysisService } from '../../services/api/puchase-analysis.service';
import { PlatformGroupService } from '../../../../core/services/platform-group/platform-group.service';

@Component({
  selector: 'app-purchase-analysis-sale',
  templateUrl: './purchase-analysis-sale.component.html',
  styleUrl: './purchase-analysis-sale.component.css'
})
export class PurchaseAnalysisSaleComponent {
  channels: any[] = [];
  platformGroup: any[] = [];

  saleDatasets: { name: string, series: { name: string, value: number }[] }[] = [];

  formattedData: { date: string, channel: string, total_order: number, total_amount: number, subtotal: number, total_discount: number }[] = []

  selectedDate = { type: 'daypass', value: '0' };
  selectedPlatfromGroup: any;
  toggleAll: boolean = true;

  toggleAllPlatformGroup = true;

  chartType: 'bar' | 'line' = 'bar'

  summary = {
    totalOrder: 0,
    subtotal: 0,
    totalDiscount: 0,
    totalAmount: 0,
    avgAmount: 0
  }

  displayData: 'total_order' | 'total_amount' = 'total_order';

  colorScheme: Color = {
    domain: [],
    group: ScaleType.Ordinal,
    selectable: true,
    name: 'platform color'
  }

  loading = false

  constructor(
    private utilService: UtilService,
    private platformService: PlatformService,
    private puchaseAnalysisService: PuchaseAnalysisService,
    private platfromGroupService: PlatformGroupService,
  ) { }

  ngOnInit(): void {
    this.platformGroup = this.platfromGroupService.platformGroup;
    const groupIds = this.platformGroup.map(item => item.id);
    this.channels = this.platformService.channelsAll.filter(item => groupIds.includes(item.platform_group_id));
    this.getDataReport();
  }

  async getDataReport() {

    this.loading = true;

    let filters: any = {}

    if (this.selectedPlatfromGroup?.length > 0) {
      filters['platform_group_id'] = this.selectedPlatfromGroup.toString()
    } else {
      filters['platform_group_id'] = this.platformGroup.map((item) => item.id).toString()
    }

    if (this.selectedDate.type === 'daypass') {

      if (this.selectedDate.value === '0') {
        this.chartType = 'bar'
        const date = this.utilService.dayjs().format('YYYY-MM-DD')
        const dateRange = [date, date].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.puchaseAnalysisService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)

      } else if (this.selectedDate.value === '1') {
        this.chartType = 'bar'
        const date = this.utilService.dayjs().subtract(parseInt(this.selectedDate.value), 'day').format('YYYY-MM-DD')
        const dateRange = [date, date].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.puchaseAnalysisService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)
      } else {
        this.chartType = 'line'
        const startDate = this.utilService.dayjs().subtract(parseInt(this.selectedDate.value), 'day').format('YYYY-MM-DD')
        const endDate = this.utilService.dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        const dateRange = [startDate, endDate].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.puchaseAnalysisService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)
      }
    }
    if (this.selectedDate.type === 'date-range') {
      const startDate = this.utilService.dayjs(this.selectedDate.value.split(',')[0]).format('YYYY-MM-DD')
      const endDate = this.utilService.dayjs(this.selectedDate.value.split(',')[1]).format('YYYY-MM-DD')

      if (startDate != endDate) {
        this.chartType = 'line'
        const dateRange = [startDate, endDate].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.puchaseAnalysisService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)

      } else {
        this.chartType = 'bar'
        const dateRange = [startDate, endDate].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.puchaseAnalysisService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)
      }
    }
    this.loading = false;
  }

  formatData(data: { date: string, channel: string, total_order: number, total_amount: number, subtotal: number, total_discount: number }[]) {

    this.summary = {
      totalOrder: 0,
      subtotal: 0,
      totalDiscount: 0,
      totalAmount: 0,
      avgAmount: 0
    }
    for (const item of data) {
      this.summary.totalOrder += item.total_order;
      this.summary.subtotal += item.subtotal;
      this.summary.totalDiscount += item.total_discount;
      this.summary.totalAmount += item.total_amount;
    }
    this.summary.totalOrder = parseFloat((this.summary.totalOrder).toFixed(2))
    this.summary.subtotal = parseFloat((this.summary.subtotal).toFixed(2))
    this.summary.totalDiscount = parseFloat((this.summary.totalDiscount).toFixed(2))
    this.summary.totalAmount = parseFloat((this.summary.totalAmount).toFixed(2))
    this.summary.avgAmount = parseFloat((this.summary.totalAmount / this.summary.totalOrder).toFixed(2))

    const uniqueDate = [...new Set(data.map(item => item.date))].sort()
    const result = []
    const platfromGroup: string[] = this.selectedPlatfromGroup?.length > 0 ? this.selectedPlatfromGroup : this.platformGroup.map(item => item.id)

    this.colorScheme.domain = []


    for (const groupId of platfromGroup) {
      const channels = this.channels.filter(item => item.platform_group_id === groupId).map(el => el.shop_ref)
      const reportGroup = data.filter(item => channels.includes(item.channel))
      console.log(reportGroup)

      const platfromGroupDetial = this.platformGroup.find(item => item.id === groupId)
      this.colorScheme.domain.push(platfromGroupDetial.color || '#32436a')
      const series = []
      for (const date of uniqueDate) {
        const reportDate = reportGroup.find(item => item.date === date)
        series.push({
          name: date,
          value: reportDate ? (reportDate[this.displayData] || 0) : 0
        })
      }

      result.push({
        name: platfromGroupDetial.name,
        series
      })
    }
    console.log(result)
    return result
  }

  handleOptionSelected(selectedDate: { type: string, value: string }) {
    console.log('Selected Date Range:', selectedDate);
    this.selectedDate = selectedDate
  }

  formCreateStringPlatformGroup(value: any) {
    this.selectedPlatfromGroup = value;
  }


  toggleAllPlatformGroupSelection() {
    this.toggleAllPlatformGroup = !this.toggleAllPlatformGroup
    if (this.toggleAllPlatformGroup) {
      this.selectedPlatfromGroup = []
    } else {
      delete this.selectedPlatfromGroup
    }
  }

  displayDataOnchange(event: any) {
    this.displayData = event.value
    this.saleDatasets = this.formatData(this.formattedData)
  }
}
