import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) { }


  getAPIHeader(): any {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.token}`
    }
    return headers
  }

  parseFilters(filters: any) {
    if (filters && Object.keys(filters).length > 0) {
      const filtersItem = []
      for (const key of Object.keys(filters)) {
        filtersItem.push(`${key}=${filters[key]}`)
      }
      return filtersItem.join("&");
    } else {
      return ''
    }
  }

  postCall(url: string, body: any) {
    const headers = this.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(url, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  patchCall(url: string, body: any) {
    const headers = this.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(url, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getCall(url: string, params: any) {
    const headers = this.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(url, { headers, params }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
