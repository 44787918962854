import { Component } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.css'
})
export class MainComponent {

  faMagnifyingGlass = faMagnifyingGlass

  countDataSource = 100;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  constructor(
    private modalService: ModalService,
  ) { }

  exampleModal(type: any) {
    this.modalService.openModal(type, 'Info Title', 'Replace info contents here.').then((result) => {
      console.log(result);
    })
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      // to do something
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
  }

  callbackActiveToggle = (event: any) => {
    console.log(event);
  }
}
