<div class="w-full">
    <label>{{label}}</label>
    <form [formGroup]="form" class="flex relative w-full">
        <input class="input" [ngClass]="{'!bg-secondary-900 !text-white': !form.get('localSelectedDate')}"
            [matDatepicker]="picker" placeholder="Month and Year" formControlName="localSelectedDate" [max]="maxDate"
            [min]="minDate" (click)="picker.open()">
        <div class="absolute right-3 h-full flex items-center hover:cursor-pointer" (click)="picker.open()">
            <fa-icon [icon]="faCalendarDay"
                class="text-lg text-basic-500 flex justify-center items-center focus:text-secondary-500"></fa-icon>
        </div>
        <mat-datepicker #picker startView="year" (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, picker)" panelClass="example-month-picker">
        </mat-datepicker>
    </form>
</div>