import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';

interface Series {
  name: string;
  value: number;
}
interface Dataset {
  name: string;
  series: Series[]
}

@Component({
  selector: 'app-group-bar-chart',
  templateUrl: './group-bar-chart.component.html',
  styleUrl: './group-bar-chart.component.css',
  encapsulation: ViewEncapsulation.None
})
export class GroupBarChartComponent {

  @Input() chartId: string = '';
  @Input() datasets: Dataset[] = [];
  @Input() legendTitle: string = '';


  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  roundDomains: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  showDataLabel: boolean = true;
  legendPosition = LegendPosition.Below

  colorScheme = 'vivid';

  constructor() {
    // Object.assign(this, { multi })
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }


}
