import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UtilService } from '../../services/util/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export const MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMMM, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-select-date-option',
  templateUrl: './select-date-option.component.html',
  styleUrls: ['./select-date-option.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMATS },
  ]
})
export class SelectDateOptionComponent {

  faCalendar = faCalendar;

  @Input() selectedOption: { type: string, value: string } | null = null;
  @Output() selectOption = new EventEmitter<{ type: string, value: string }>();

  dateOptions = [
    { id: '0', name: 'วันนี้', type: 'today' },
    { id: '1', name: 'เมื่อวาน', type: 'daypass' },
    { id: '7', name: '7 วันที่ผ่านมา', type: 'daypass' },
    { id: '30', name: '30 วันที่ผ่านมา', type: 'daypass' },
  ];

  maxDate = new Date();
  minDate = null;

  formMonthYear: FormGroup;
  formDateRange: FormGroup;

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  constructor(
    private utilService: UtilService,
    private fb: FormBuilder,
  ) {
    this.formMonthYear = this.fb.group({
      localSelectedDate: [new Date()],
    });
    this.formDateRange = this.fb.group({
      startDate: [new Date()],
      endDate: [new Date()],
    });
  }

  onSelectDateOption(id: string) {
    this.selectOption.emit({
      type: 'daypass',
      value: id
    });
  }

  selectDateMonthYearChange(selectedDate: string) {
    const start = this.utilService.dayjs(selectedDate).startOf('month');
    this.selectOption.emit({
      type: 'month-year',
      value: start.format('YYYY-MM-DD')
    });
  }

  selectYearChange(selectedDate: string) {
    const start = this.utilService.dayjs(selectedDate).startOf('year');
    this.selectOption.emit({
      type: 'year',
      value: start.format('YYYY-MM-DD')
    });
  }

  chosenYearHandler(normalizedDate: any) {
    const ctrlValue = this.formMonthYear.get('localSelectedDate')?.value;
    ctrlValue?.setFullYear(normalizedDate.year());
    this.formMonthYear.get('localSelectedDate')?.setValue(ctrlValue);
    this.selectDateMonthYearChange(this.utilService.dayjs(this.formMonthYear.get('localSelectedDate')?.value).format('YYYY-MM-DD'));
  }

  chosenMonthHandler(normalizedDate: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.formMonthYear.get('localSelectedDate')?.value;
    ctrlValue?.setMonth(normalizedDate.month());
    ctrlValue?.setFullYear(normalizedDate.year());
    this.formMonthYear.get('localSelectedDate')?.setValue(ctrlValue);
    this.selectDateMonthYearChange(this.utilService.dayjs(this.formMonthYear.get('localSelectedDate')?.value).format('YYYY-MM-DD'));
    datepicker.close();
  }


  // Function to format the selected date into a readable string (or placeholder)
  onDateSelected(date: Date | null): string {
    return date ? this.utilService.dayjs(date).format('YYYY-MM-DD') : 'Select Date';
  }

  // Triggered when the date range changes
  onDateRangeSelected() {
    // You can add any logic here if needed after a new range is selected
    console.log('Selected start date:', this.selectedStartDate);
    console.log('Selected end date:', this.selectedEndDate);
    const startDate = this.utilService.dayjs(this.selectedStartDate).format('YYYY-MM-DD')
    const endDate = this.utilService.dayjs(this.selectedEndDate).format('YYYY-MM-DD')
    this.selectOption.emit({
      type: 'date-range',
      value: [startDate, endDate].toString()
    });
  }

  trackByFn(index: number) {
    return index;
  }
}
