<div class="mt-4 space-y-4">
    <!-- ส่วนของฟอร์มค้นหา -->
    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 gap-4">
            <!-- กลุ่มช่องทางการขาย -->
            <div class="w-full">
                <label class="text-sm whitespace-nowrap w-full">กลุ่มช่องการทางขาย</label>
                <div class="w-full">
                    <mat-form-field class="w-full !text-sm !h-9">
                        <mat-select class="!h-9" (selectionChange)="formCreateStringPlatformGroup($event.value)"
                            [value]='toggleAllPlatformGroup ? 0 : selectedPlatfromGroup' multiple>
                            <mat-option class="!text-sm" #allSelectedPlatformGroup
                                (click)="toggleAllPlatformGroupSelection()" [value]="0">ทั้งหมด</mat-option>
                            @for (channel of platformGroup; track channel.id) {
                            <mat-option class="!text-sm" [disabled]="allSelectedPlatformGroup.selected"
                                [value]="channel.id">
                                {{channel.name}}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- หมวดสินค้าสินค้า -->
            <div class="w-full">
                <label class="text-sm">หมวดหมู่</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกหมวดหมู่" formControlName="category_id">
                        <mat-option value="">ทั้งหมด</mat-option>
                        @for (item of categories; track $index) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- ค้นหาสินค้า -->
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <div class="flex gap-2 w-full relative">
                    <input #product type="text" class="input" placeholder="SKU, ชื่อสินค้า" [(ngModel)]="keyword"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="productSearch()">

                    <!-- รายการสินค้าที่ค้นหาเจอ -->
                    @if (showProductFilter) {
                    <ul
                        class="absolute z-50 w-96 !rounded-lg bg-white mat-elevation-z8 max-h-72 overflow-y-auto top-11 py-2">
                        @for (product of productsFilter; track product.sku) {
                        <li class="min-h-[48px] whitespace-nowrap hover:bg-secondary-100 hover:cursor-pointer flex items-center p-2 text-sm"
                            (click)="selectProduct(product)">
                            <img [src]="'https://storage.maxxgadget.com/img/product/' + product.sku + '/1.jpg'" alt=""
                                class="flex-none h-11 w-11 mr-2"
                                onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                            <div class="w-full flex flex-col truncate">
                                <span class="pr-2 text-blue-500">{{ product.sku }} :</span>
                                <span class="w-full truncate whitespace-nowrap">{{ product.name }}</span>
                            </div>
                        </li>
                        }
                    </ul>
                    }

                    <!-- ปุ่มล้างการค้นหา -->
                    @if (keyword.length > 0) {
                    <div class="absolute flex justify-center flex-col right-2 top-1">
                        <button type="button" (click)="clearSearch()">
                            <fa-icon [icon]="faXmark" class="text-secondary-900"></fa-icon>
                        </button>
                    </div>
                    }
                </div>
            </div>

            <!-- tag สินค้า -->
            <div class="w-full">
                <label class="text-sm whitespace-nowrap w-full">Tags</label>
                <div class="w-full">
                    <mat-form-field class="w-full !text-sm !h-9">
                        <mat-select class="!h-9" (selectionChange)="formCreateStringTag($event.value)"
                            [value]='toggleAllTag ? 0 : selectedTag' multiple>
                            <mat-option class="!text-sm" #allSelectedTag (click)="toggleAllTagSelection()"
                                [value]="0">ทั้งหมด</mat-option>
                            @for (tag of tags; track tag) {
                            <mat-option class="!text-sm" [disabled]="allSelectedTag.selected" [value]="tag">
                                {{tag}}
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- เลือกวันที่ -->
            <div class="">
                <label class="text-sm">วันที่</label>
                <mat-form-field class="w-full">
                    <button type="button" class="input !p-2" (click)="picker.open()">
                        <div class="text-nowrap gap-2">
                            <span class="whitespace-nowrap mr-2">{{onDateSelected(selectedStartDate)}} ถึง
                                {{onDateSelected(selectedEndDate)}}</span>
                            <fa-icon [icon]="faCalendar" class="text-gray-400"></fa-icon>
                        </div>
                    </button>
                    <mat-date-range-input class="!hidden" id="datepicker" [rangePicker]="picker" [max]="maxDate">
                        <input matStartDate [(ngModel)]="selectedStartDate" [max]="maxDate"
                            [ngModelOptions]="{standalone: true}">
                        <input matEndDate [(ngModel)]="selectedEndDate" [max]="maxDate"
                            [ngModelOptions]="{standalone: true}" (dateChange)="onDateRangeSelected()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <!-- ปุ่มค้นหา -->
            <button type="button" class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>

        <!-- แสดงตัวกรองที่เลือก -->
        <div class="mt-4 w-full bg-gray-100 rounded-lg p-4 text-sm">
            <!-- กลุ่มช่องทางการขายที่เลือก -->
            <div class="flex gap-4 mt-2">
                <div class="whitespace-nowrap text-gray-500">กลุ่มช่องทางการขาย : </div>
                @if (selectedPlatfromGroup?.length > 0) {
                <div class="w-full flex flex-row gap-1 flex-wrap">
                    @for (item of displayPlatfromGroup(); track item.id) {
                    <div class="label label-secondary w-fit !text-xs text-nowrap">
                        {{item.name}}
                        <span class="cursor-pointer ml-3" (click)="removePlatfromGroupSelected(item)">&#x2715;</span>
                    </div>
                    }
                    @if (selectedPlatfromGroup?.length > 1) {
                    <button class="label label-light w-fit !text-xs text-nowrap border" type="button"
                        (click)="removePlatfromGroupSelectedAll()">ลบทั้งหมด</button>
                    }
                </div>
                } @else {
                ทั้งหมด
                }
            </div>

            <!-- สินค้าที่เลือก -->
            <div class="flex gap-4 mt-2">
                <div class="whitespace-nowrap text-gray-500">สินค้าที่เลือก : </div>
                @if (productSelected.length > 0) {
                <div class="w-full flex flex-row gap-1 flex-wrap">
                    @for (item of productSelected; track item.sku) {
                    <div class="label label-primary w-fit !text-xs text-nowrap">
                        {{item.name}}
                        <span class="cursor-pointer ml-3" (click)="removeProductSelected(item)">&#x2715;</span>
                    </div>
                    }
                    @if (productSelected.length > 1) {
                    <button class="label label-light w-fit !text-xs text-nowrap border" type="button"
                        (click)="removeProductSelectedAll()">ลบทั้งหมด</button>
                    }
                </div>
                } @else {
                ทั้งหมด
                }
            </div>
        </div>
    </div>

    <!-- แสดงการโหลดข้อมูล -->
    @if (loading) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">กำลังโหลดข้อมูล</div>
        </div>
    </div>
    }

    <!-- กรณีไม่พบข้อมูล -->
    @if (!loading && tableDatasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">ไม่พบข้อมูล</div>
        </div>
    </div>
    }

    <!-- แสดงข้อมูลในตาราง -->
    @if (!loading && tableDatasets.length > 0) {
    <div class="grid grid-cols-1">
        <div class="card">
            <div class="w-full">
                <div class="flex space-x-2 items-center justify-between">
                    <div class="font-medium">
                        TOP สินค้าขายดี
                    </div>
                    <div class="flex space-x-2 items-center">
                        <div class="text-sm">การแสดงผล : </div>
                        <mat-form-field class="w-fit !h-9 !text-sm">
                            <mat-select class="!h-9" [value]="displayData"
                                (selectionChange)="displayDataOnchange($event)">
                                <mat-option class="!text-sm" value="total_amount">มูลค่า</mat-option>
                                <mat-option class="!text-sm" value="total_qty">จำนวนชิ้น</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- ส่วนกราฟ -->
            <div class="mt-4">
                <app-group-bar-chart [chartId]="'purchaseProductOverviewChart'" [datasets]="chartDataset"
                    [legendTitle]="'สินค้า'"></app-group-bar-chart>
            </div>

            <!-- ตาราง -->
            <div class="mt-4">
                <div class="div-table">
                    <div class="relative w-full h-full">
                        <div class="w-full h-full overflow-auto">
                            <table class="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr class="thead-tr">
                                        <!-- คอลัมน์ที่ตรึงไว้ (sticky) -->
                                        <th
                                            class="sticky left-0 top-0 z-20 bg-gray-100 border-r border-gray-200 text-left shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                                            สินค้า
                                        </th>
                                        <!-- คอลัมน์ที่เหลือ -->
                                        <th class="sticky top-0 z-10 bg-gray-100 text-center border-b border-gray-200">
                                            สต๊อก
                                        </th>
                                        @for (item of platformGroupFiltered; track item.id) {
                                        <th class="sticky top-0 z-10 bg-gray-100 text-right border-b border-gray-200">
                                            <button type="button"
                                                class="w-full text-right flex flex-row space-x-1 items-center justify-end"
                                                (click)="sortByPlatformGroup(item.id)">
                                                <div class="">{{item.name}}</div>
                                                <div class="gap-0">
                                                    @if (selectedSortPlatfromGroup === item.id && sortBy) {
                                                    @if (sortBy === 'min') {
                                                    <fa-icon [icon]="faCaretUp" class="text-primary-500"></fa-icon>
                                                    } @else if (sortBy === 'max') {
                                                    <fa-icon [icon]="faCaretDown" class="text-primary-500"></fa-icon>
                                                    }
                                                    } @else {
                                                    <fa-icon [icon]="faSort" class="text-gray-400"></fa-icon>
                                                    }
                                                </div>
                                            </button>
                                        </th>
                                        }
                                        <th class="sticky top-0 z-10 bg-gray-100 text-right border-b border-gray-200">
                                            <button type="button"
                                                class="w-full text-right flex flex-row space-x-1 items-center justify-end"
                                                (click)="sortData('')">
                                                <div class="">รวม</div>
                                                <div class="gap-0">
                                                    @if (selectedSortFiled === '' && sortBy &&
                                                    !selectedSortPlatfromGroup) {
                                                    @if (sortBy === 'min') {
                                                    <fa-icon [icon]="faCaretUp" class="text-primary-500"></fa-icon>
                                                    } @else if (sortBy === 'max') {
                                                    <fa-icon [icon]="faCaretDown" class="text-primary-500"></fa-icon>
                                                    }
                                                    } @else {
                                                    <fa-icon [icon]="faSort" class="text-gray-400"></fa-icon>
                                                    }
                                                </div>
                                            </button>
                                        </th>
                                        <th class="sticky top-0 z-10 bg-gray-100 text-right border-b border-gray-200">
                                            <button type="button"
                                                class="w-full text-right flex flex-row space-x-1 items-center justify-end"
                                                (click)="sortData('avg_qty_per_day')">
                                                <div class="">จำนวน<br>เฉลี่ย/วัน</div>
                                                <div class="gap-0">
                                                    @if (selectedSortFiled === 'avg_qty_per_day' && sortBy) {
                                                    @if (sortBy === 'min') {
                                                    <fa-icon [icon]="faCaretUp" class="text-primary-500"></fa-icon>
                                                    } @else if (sortBy === 'max') {
                                                    <fa-icon [icon]="faCaretDown" class="text-primary-500"></fa-icon>
                                                    }
                                                    } @else {
                                                    <fa-icon [icon]="faSort" class="text-gray-400"></fa-icon>
                                                    }
                                                </div>
                                            </button>
                                        </th>
                                        <th class="sticky top-0 z-10 bg-gray-100 text-right border-b border-gray-200">
                                            <div class="whitespace-nowrap">จำนวนวัน<br>ที่จะขายได้</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (row of tableDatasets; track row.sku) {
                                    <tr class="tbody-tr hover:bg-gray-50">
                                        <!-- คอลัมน์ที่ตรึงไว้ (sticky) -->
                                        <td
                                            class="min-w-[25rem] sticky left-0 z-10 hover:bg-gray-50 bg-white border-r border-gray-200 border-b border-solid shadow-[2px_0_5px_-2px_rgba(0,0,0,0.1)]">
                                            <div class="flex items-center gap-2">
                                                @if (row.sku) {
                                                <img [src]="'https://storage.maxxgadget.com/img/product/' + row.sku + '/1.jpg'"
                                                    alt="" class="w-8 h-8"
                                                    onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                                }
                                                <div class="flex-1 w-0">
                                                    <div class="truncate cursor-pointer hover:text-primary-500 max-w-[20rem] md:max-w-max"
                                                        (click)="selectedRowOnChange(row)">
                                                        {{ row.name }}
                                                    </div>
                                                    <div class="flex space-x-1 flex-wrap">
                                                        @for (tag of tags; track tag) {
                                                        <button
                                                            [ngClass]="{'bg-amber-500': tagIncludes(tag, row.sku),'bg-gray-300':  !tagIncludes(tag, row.sku)}"
                                                            class="text-white space-x-2 pl-2.5 pr-2 font-light text-xs rounded-lg hover:"
                                                            (click)="addTag(row.sku, tag)">
                                                            {{tag}}
                                                        </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- คอลัมน์ที่เหลือ -->
                                        <td class="w-24 border-b border-gray-200 border-solid font-semibold">
                                            <div [ngClass]="{'text-lime-500': row.stock > 0, 'text-red-600': row.stock <= 0}"
                                                class="text-center">{{row.stock | number}}</div>
                                        </td>

                                        @for (item of row.groups; track $index) {
                                        <td class="w-24 border-b border-gray-200 border-solid">
                                            <div class="text-right w-full">
                                                @if (displayData === 'total_qty') {
                                                {{item.total_qty | number}}
                                                } @else {
                                                {{item.total_amount | number}}
                                                }
                                            </div>
                                        </td>
                                        }
                                        <td class="w-28 border-b border-gray-200 border-solid">
                                            <div class="w-full text-right text-primary-500 font-semibold">
                                                @if (displayData === 'total_qty') {
                                                {{row.total_qty| number}}
                                                } @else {
                                                {{row.total_amount| number}}
                                                }
                                            </div>
                                        </td>
                                        <td class="w-28 border-b border-gray-200 border-solid">
                                            <div class="w-full text-right" *ngIf="row.name !== 'รวม'">
                                                @if (displayData === 'total_qty') {
                                                {{row.avg_qty_per_day| number}}
                                                } @else {
                                                {{row.avg_amount_per_day| number}}
                                                }
                                            </div>
                                        </td>
                                        <td class="w-28 text-right border-b border-gray-200 border-solid font-semibold"
                                            [ngClass]="{'text-primary-500': row.sale_in_day > 0, 'text-red-600': row.sale_in_day <= 3}">
                                            <div *ngIf="row.name !== 'รวม'">
                                                {{row.sale_in_day | number}}
                                            </div>
                                        </td>
                                    </tr>

                                    <!-- แสดงกราฟเมื่อคลิกที่แถว -->
                                    <tr>
                                        <td colspan="100"
                                            class="transition-all ease-in-out duration-500 relative overflow-hidden border-b border-gray-200 border-solid"
                                            [ngClass]="{ 'max-h-96 opacity-100': selectedRow === row.sku, 'max-h-0 opacity-0 hidden': selectedRow !== row.sku }">
                                            <div class="px-4 py-8">
                                                @if (chartLoaded && performanceDatasets.length > 0) {
                                                <div class="text-center font-medium py-2">ยอดขาย 30 วันย้อนหลัง</div>
                                                <div class="h-48">
                                                    <app-line-chart [chartId]="'chart-performance-'+row.sku"
                                                        [legendPosition]="legendPosition"
                                                        [datasets]="performanceDatasets"></app-line-chart>
                                                </div>
                                                } @else if (chartLoaded && performanceDatasets.length === 0) {
                                                <div
                                                    class="h-48 w-full flex items-center justify-center bg-gray-100 rounded-lg">
                                                    <div class="">ไม่พบข้อมูล</div>
                                                </div>
                                                } @else {
                                                <div
                                                    class="h-48 w-full flex items-center justify-center bg-gray-100 rounded-lg">
                                                    <app-loader></app-loader>
                                                </div>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
</div>