<ngx-charts-bar-vertical-2d
  class="block w-full mb-36"
  [scheme]="colorScheme"
  [results]="datasets"
  [gradient]="gradient"
  [roundDomains]="roundDomains"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [showDataLabel]="showDataLabel"
  [legendTitle]="legendTitle"
  [legendPosition]="legendPosition"
  (select)="onSelect($event)">
</ngx-charts-bar-vertical-2d>