import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-product-set-name',
  templateUrl: './update-product-set-name.component.html',
  styleUrl: './update-product-set-name.component.css'
})
export class UpdateProductSetNameComponent {

  modalName = ''
  displayCheck = false
  checkName = ''
  value: string = '';
  updateAll = false;
  type: 'textarea' | 'text' = 'textarea'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<UpdateProductSetNameComponent>
  ) {
    console.log(data)
  }


  ngOnInit(): void {
    this.value = this.data.value
    this.modalName = this.data.modalName
    this.displayCheck = this.data.displayCheck
    this.checkName = this.data.checkName
    this.type = this.data.type
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    this.ref.close({ confirm: true, data: { value: this.value, updateAll: this.updateAll } });
  }
}
