import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core/services/api/api.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PuchaseAnalysisService {


  private endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getSaleReportByDateRange(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/sale-daily-report/between`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  async getProductSummaryReportByGroup(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-daily-report/group/summary`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductDailyReportByGroup(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-daily-report/group/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  addProductTag(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product/tags/add`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeProductTag(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.patch(`${this.endpoint}/product/tags/remove`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductTag(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/tags`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  createTag(body: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.post(`${this.endpoint}/tags`, body, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  removeTag(tag: string) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.delete(`${this.endpoint}/tags/${tag}`, { headers }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
