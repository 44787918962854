<div class="content" [ngStyle]="{'height': 'calc(100vh - ' + header.clientHeight + 'px)'}">
    <div class="card h-full w-full space-x-2 flex">
        <div class='flex-none w-72 h-full bg-gray-100 p-2 rounded-lg'>
            <div class="font-medium py-4 text-center">ตั้งค่า</div>
            <div class="overflow-y-auto scrollbar space-y-1">
                <button *ngFor="let item of menus" class="w-full text-left hover:cursor-pointer h-10 px-4 rounded-md"
                    [ngClass]="{'bg-primary-500 text-white':menuSelected.key===item.key, 'hover:bg-gray-300':menuSelected.key!==item.key}"
                    (click)="selectedMenuOnChange(item)">{{item.name}}</button>
            </div>
        </div>
        <div class="flex-1 w-full h-full">
            <div class="px-8 h-full overflow-y-auto scrollbar">
                <div class='py-3 border-b border-primary-500'>
                    <div class='font-bold'>
                        {{menuSelected.name}}
                    </div>
                    <div class='text-gray-400 text-sm'>
                        {{menuSelected.detail}}
                    </div>
                </div>
                <div class='pt-4'>
                    <ng-container *ngIf="menuSelected.key==='channel'">
                        <app-setting-channel></app-setting-channel>
                    </ng-container>
                    <ng-container *ngIf="menuSelected.key==='permission'">
                        <app-setting-permission></app-setting-permission>
                    </ng-container>
                    <ng-container *ngIf="menuSelected.key==='campaign'">
                        <app-setting-campaign></app-setting-campaign>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>