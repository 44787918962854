import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../core/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DataAnalysisApiService {

  private endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }

  getSaleReportByDate(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/sale-daily-report/date`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductSummaryReport(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-daily-report/summary`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getSaleReportByDateRange(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/sale-daily-report/between`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductDailyReport(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-daily-report/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProduct(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getAllProductSet(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  searchProductSet(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set/search`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductSetSummaryReport(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set-daily-report/summary`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

  getProductSetDailyReport(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/product-set-daily-report/all`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
