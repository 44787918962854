import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformGroupService {

  platformGroup: Array<{ id: string; name: string, color: string }> = []

  private endpoint = environment.endpoint;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) { }


  getAllPlatformGroup(filters: any) {
    const headers = this.apiService.getAPIHeader();
    return new Promise((resolve, reject) => {
      return this.http.get(`${this.endpoint}/platform-group`, { headers, params: filters }).subscribe((data) => {
        resolve(data);
      });
    })
  }
}
