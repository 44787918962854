import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductApiService } from '../../../product/services/api/product-api.service';
import { CategoryService } from '../../../../core/services/category/category.service';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UpdateStockModalComponent, UpdateStockModalModel } from '../../../product/components/update-stock-modal/update-stock-modal.component';
import { MatDialog } from '@angular/material/dialog';
interface ProductSet {
  active: boolean;
  name: string;
  products: ProductInfo[]
  set_sku: string;
  total_price: number;
  product_info: object[];
  in_stock: number
}
interface ProductInfo {
  cost: number;
  name: string;
  price: number;
  qty: number;
  sku: string;
  srp_price: number;
}

@Component({
  selector: 'app-product-set-stock',
  templateUrl: './product-set-stock.component.html',
  styleUrl: './product-set-stock.component.css'
})
export class ProductSetStockComponent implements OnInit {

  loading: boolean = false;
  form: FormGroup;
  datasets: any[] = [];
  productSet: Array<ProductSet> = [];
  stockPlatform: any[] = []
  channels: any[] = this.platformService?.channels || [];
  channelsHeaders: any[] = []

  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  constructor(
    private fb: FormBuilder,
    private productApiService: ProductApiService,
    private platformService: PlatformService,
    public dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      keyword: [''],
      min_stock: [null],
      max_stock: [null]
    });
    this.channelsHeaders = [...new Set(this.channels.map(item => item.platform))]
  }

  ngOnInit(): void {
    this.getDataProductSet()
  }

  getDataProductSet() {
    this.loading = true;
    const value = this.form.getRawValue();
    const filters: any = { limit: this.limit, offset: this.offset };
    if (value.keyword) {
      filters['keyword'] = value.keyword
    }
    if (value.active !== undefined && value.active !== null) {
      filters['active'] = value.active
    }
    if (value.price_change !== undefined && value.price_change !== null) {
      filters['price_change'] = value.price_change
    }
    if (value.checked_all !== undefined && value.checked_all !== null) {
      filters['checked_all'] = value.checked_all
    }
    if (value.min_stock !== undefined && value.min_stock !== null) {
      filters['min_stock'] = value.min_stock
    }
    if (value.max_stock !== undefined && value.max_stock !== null) {
      filters['max_stock'] = value.max_stock
    }
    this.productApiService.getAllProductSet(filters).then(async (res: any) => {
      if (res.success) {
        this.productSet = res.data;
        this.countDataSource = res.count;
      } else {
        this.productSet = [];
        this.countDataSource = 0;
      }
      const uniqueSku = res.data.map((e: { set_sku: any; }) => e.set_sku)
      if (uniqueSku.length > 0) {
        this.stockPlatform = await this.getAllProductPlatform(uniqueSku)
      }
      console.log(this.productSet)
      console.log(this.stockPlatform)
      this.reformattingDataset();
      this.loading = false;
    })
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getDataProductSet()
  }

  reformattingDataset() {
    this.datasets = this.productSet.map(item => ({
      ...item,
      stocks: this.stockPlatform.filter(el => el.sku === item.set_sku).map(el => ({
        ...el,
        channel_name: this.channels.find(channel => channel.shop_ref === el.channel)?.platform_name,
        channel_logo_url: this.channels.find(channel => channel.shop_ref === el.channel)?.logo_url,
        platform: this.channels.find(channel => channel.shop_ref === el.channel)?.platform
      }))
    }))
    console.log(this.datasets)
    console.log(this.channels)
  }

  async getAllProductPlatform(sku: string[]) {
    const filters: any = { sku: sku.join(), fields: 'sku,channel,sellable,platform_product_id,platform_sku,updated' }
    return await this.productApiService.getAllProductPlatform(filters).then((res: any) => {
      if (res.success) {
        return res.data;
      }
    })
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getDataProductSet()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  getStockByPlatform(platform: string, stocks: any[]) {
    return stocks.filter(item => item.platform === platform)
  }

  openUpdateStockDialog(set_sku: string) {
    const product = this.productSet.find(item => item.set_sku === set_sku)
    const stocks = this.stockPlatform.filter(item => item.sku === set_sku)
    if (stocks.length === 0) return
    console.log(this.channels)
    console.log(product)
    console.log(stocks)
    if (product) {
      const data: UpdateStockModalModel = {
        sku: set_sku,
        name: product?.name,
        stock: product?.in_stock,
        products: product.products.map(item => ({ sku: item.sku })),
        channels: stocks.map(item => ({
          channel: item.channel,
          name: this.channels.find(el => el.shop_ref === item.channel)?.platform_name,
          platform: this.channels.find(el => el.shop_ref === item.channel)?.platform,
          stock: item.sellable,
          updated_date: item.updated.date
        }))
      }
      const dialogRef = this.dialog.open(UpdateStockModalComponent, {
        data,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result.confirm) {

        }
      });
    }
  }

  getTooltipText(product: any): string {
    return `ID: ${product.platform_product_id}\nSKU: ${product.platform_sku}`;
  }

}

