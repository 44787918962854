<div class="mt-4 space-y-4">
    <div class="card grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        <div class="w-full col-span-full">
            <label class="text-sm">ช่วงเวลาข้อมูล</label>
            <app-select-date-option [selectedOption]="selectedDate"
                (selectOption)="handleOptionSelected($event)"></app-select-date-option>
        </div>
        <div class="w-full">
            <label class="text-sm whitespace-nowrap w-full">ช่องทางการขาย</label>
            <div class="w-full">
                <mat-form-field class="w-full !text-sm !h-9">
                    <mat-select class="!h-9" (selectionChange)="formCreateString($event.value)"
                        [value]='toggleAll? 0: selectedChannel' multiple>
                        <mat-option class="!text-sm" #allSelected (click)="toggleAllSelection()"
                            [value]="0">ทั้งหมด</mat-option>
                        @for (channel of channels; track $index) {
                        <mat-option class="!text-sm" [disabled]="allSelected.selected" [value]="channel.shop_ref">
                            {{channel.platform_name}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="flex justify-start">
            <button class="btn-sm btn-primary w-full md:w-fit self-end" (click)="getDataReport()">ประมวลผล</button>
        </div>
    </div>

    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }@else {

    <div class="grid  grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div class="card">
            <div class="text-sm">คำสั่งซื้อ</div>
            <div class="text-xl font-semibold text-indigo-500">{{summary.totalOrder| number}}</div>
        </div>
        <div class="card">
            <div class="text-sm">ยอดขายสุทธิ</div>
            <div class="text-xl font-semibold text-blue-500">{{summary.totalAmount| number}}</div>
        </div>
        <div class="card">
            <div class="text-sm">ส่วนลด</div>
            <div class="text-xl font-semibold text-amber-500">{{summary.totalDiscount| number}}</div>
        </div>
        <div class="card">
            <div class="text-sm">ยอดขายเฉลี่ยต่อคำสั่งซื้อ</div>
            <div class="text-xl font-semibold text-green-500">{{summary.avgAmount| number}}</div>
        </div>
    </div>

    <div class="card">
        <div class="grid grid-cols-1">
            <div class="w-full">
                <div class="grid gap-2 md:flex md:space-x-2 items-center justify-between pb-4">
                    <div class="font-medium text-left">ยอดขาย (คำสั่งซื้อ)</div>
                    <div class="flex items-center justify-start">
                        <div class="hidden md:block pr-2 text-sm">การแสดงผล : </div>
                        <mat-form-field class="w-fit !h-9 !text-sm">
                            <mat-select class="!h-9" [value]="displayData"
                                (selectionChange)="displayDataOnchange($event)">
                                <mat-option class="!text-sm" value="total_order">คำสั่งซื้อ</mat-option>
                                <mat-option class="!text-sm" value="total_amount">ยอดขายสุทธิ</mat-option>
                                <mat-option class="!text-sm" value="total_discount">ส่วนลด</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="h-96 pb-28 md:pb-20">
                    <app-line-chart [chartId]="'sale1'" [datasets]="saleDatasets"
                        [colorScheme]="colorScheme"></app-line-chart>
                </div>
            </div>
        </div>
    </div>
    }
</div>