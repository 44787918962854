<div class="space-y-6">

    <div class="text-sm flex flex-col gap-5">
        @for (channel of channels; track $index) {
        <div class="">
            <div class="flex flex-row gap-4">
                <div class="flex-1">
                    <div class="font-semibold flex space-x-2">
                        <img src="{{channel.logo_url}}" alt="logo" class="h-4 w-auto">
                        <span>{{channel.platform_name}}</span>
                    </div>
                    <div class="text-gray-500 space-x-2">
                        <span class="text-gray-500">Channel ID : {{channel.shop_ref}}</span>
                        <span>|</span>
                        <span class="text-gray-500">Member ID : {{channel.member_id}}</span>
                    </div>
                </div>
                <div class="flex-none">
                    <button class="btn-sm btn-light" (click)="openUpdateChannelDialog(channel)">แก้ไข</button>
                </div>
            </div>
            <div class="flex flex-row gap-2">
                <div class="label" [ngStyle]="{'background': channel.color}">{{channel.color}}</div>
            </div>
        </div>
        }
    </div>

</div>