<div class=" bg-white w-fit h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        ซิงค์รายการสินค้า
    </div>

    <div class="w-full space-y-2 text-sm">
        <div class="font-semibold">การเพิ่มสินค้าใหม่</div>
        @for (item of syncProductList; track $index) {
        <div class="grid grid-cols-5 gap-10 items-center">
            <div class="col-span-2">{{$index+1}}. {{item.name}}</div>
            <div class="flex items-center gap-2">
                @if(loading === 'sync-product-'+$index){
                <button class="btn-sm btn-warning flex items-center gap-2">
                    <mat-spinner [diameter]="20"></mat-spinner>กำลังโหลด
                </button>
                }
                @else{
                <button class="btn-sm btn-warning" id="sync-product-{{$index}}"
                    (click)="callApi($event,item.delay, item.link)">ซิงค์ข้อมูล</button>
                }
                @if(checkCalledApi('sync-product-'+$index)){
                <fa-icon [icon]="faCheckCircle" class="text-green-500"></fa-icon>
                }
            </div>
            <div class="col-span-2 text-gray-500">{{item.note}}</div>
        </div>
        }
    </div>

    <div class="w-full space-y-2 text-sm">
        <div class="font-semibold">การอัพเดตจำนวนสินค้า</div>
        @for (item of syncStockList; track $index) {
        <div class="grid grid-cols-5 gap-10 items-center">
            <div class="col-span-2">{{$index+1}}. {{item.name}}</div>
            <div class="flex items-center gap-2">
                @if(loading === 'sync-stock-'+$index){
                <button class="btn-sm btn-warning flex items-center gap-2">
                    <mat-spinner [diameter]="20"></mat-spinner>กำลังโหลด
                </button>
                }
                @else{
                <button class="btn-sm btn-warning" id="sync-stock-{{$index}}"
                    (click)="callApi($event,item.delay, item.link)">ซิงค์ข้อมูล</button>
                }
                @if(checkCalledApi('sync-stock-'+$index)){
                <fa-icon [icon]="faCheckCircle" class="text-green-500"></fa-icon>
                }
            </div>
            <div class="col-span-2 text-gray-500">{{item.note}}</div>
        </div>
        }
    </div>

</div>