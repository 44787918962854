import { Injectable } from '@angular/core';
import liff from '@line/liff';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLogin: boolean = false;
  loaded: boolean = false;

  token: string = '';
  user: { userId: string; displayName: string; pictureUrl: string; statusMessage: string; role?: string | null } | undefined;

  authEndpoint: string = environment.authEndpoint;

  roles = [
    { id: 'admin', name: 'Admin' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'purchase', name: 'Purchase' }
  ]

  constructor(
    private http: HttpClient

  ) { }

  header(): any {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.token}`
    }
    return { headers };
  }

  getRole(role: string) {
    return this.roles.find(e => e.id === role)?.name || role;
  }

  logout() {
    return new Promise(async (resolve, reject) => {
      const liffId: string = environment.liffId;
      liff.init({ liffId }).then(() => {
        liff.logout()
        localStorage.removeItem('user')
        localStorage.removeItem('method')
        resolve(true)
        window.location.reload();
      })
    })
  }

  getAccessToken(user_id: string, channel: string, info: any) {
    return new Promise((resolve, _reject) => {
      this.http.post(`${this.authEndpoint}/auth/token`, { user_id, channel, info }).subscribe({
        next: (result: any) => {
          this.token = result.access_token;
          resolve(result)
        }
      })
    })
  }

  getUserInfo() {
    return new Promise((resolve, _reject) => {
      const systemId = environment.systemId;
      return this.http.get(`${this.authEndpoint}/user/info?system_id=${systemId}`, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

  getUserRequest() {
    return new Promise((resolve, _reject) => {
      const systemId = environment.systemId;
      return this.http.get(`${this.authEndpoint}/request/one?system_id=${systemId}`, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

  requestAccess(body: any) {
    return new Promise((resolve, _reject) => {
      return this.http.post(`${this.authEndpoint}/request/create`, body, this.header()).subscribe((result: any) => {
        console.log(result)
        resolve(result);
      })
    })
  }

  getAllUserRequest() {
    return new Promise((resolve, _reject) => {
      const systemId = environment.systemId;
      return this.http.get(`${this.authEndpoint}/request/all?system_id=${systemId}`, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }


  getAllUserInfo() {
    return new Promise((resolve, _reject) => {
      const systemId = environment.systemId;
      return this.http.get(`${this.authEndpoint}/user/info/all?system_id=${systemId}`, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

  updatePermission(body: any) {
    return new Promise((resolve, _reject) => {
      return this.http.patch(`${this.authEndpoint}/user/update-permissions`, body, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

  updateRoleMemberSelected(body: any) {
    return new Promise((resolve, _reject) => {
      return this.http.patch(`${this.authEndpoint}/user/update-role-member`, body, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

  updateLineInfo(body: { userId: string; displayName: string; pictureUrl: string; statusMessage: string; }) {
    return new Promise((resolve, _reject) => {
      return this.http.patch(`${this.authEndpoint}/user/update-line-info`, body, this.header()).subscribe((result: any) => {
        resolve(result);
      })
    })
  }

}
