import { Component } from '@angular/core';

@Component({
  selector: 'app-validating-dialog',
  templateUrl: './validating-dialog.component.html',
  styleUrl: './validating-dialog.component.css'
})
export class ValidatingDialogComponent {

}
