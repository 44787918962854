<!-- tag-management.component.html -->
<div class="bg-white w-screen max-w-lg p-6 rounded-lg shadow-lg">
    <h2 class="text-lg font-semibold text-center mb-4">จัดการแท็กสินค้า</h2>

    <!-- Input และปุ่มเพิ่มแท็ก -->
    <form [formGroup]="tagForm" (ngSubmit)="addTag()">
        <div class="flex gap-2">
            <input type="text" formControlName="tag" class="border p-2 rounded w-full" placeholder="เพิ่มแท็กใหม่"
                (keyup.enter)="addTag()">
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded"
                [disabled]="tagForm.invalid">เพิ่ม</button>
        </div>
    </form>

    <!-- แสดงรายการแท็ก -->
    <div class="flex flex-wrap gap-2 mt-4">
        <span *ngFor="let tag of tags" class="bg-gray-200 px-3 py-1 rounded-full flex items-center space-x-2">
            <span>{{ tag }}</span>
            <button class="text-red-500 font-bold" (click)="removeTag(tag)">×</button>
        </span>
        <span *ngIf="tags.length === 0" class="text-gray-500 italic">ไม่มีแท็ก</span>
    </div>

    <!-- ปุ่มบันทึกและยกเลิก -->
    <div class="flex justify-end space-x-2 mt-6">
        <button class="bg-gray-300 px-4 py-2 rounded" (click)="cancel()">ยกเลิก</button>
        <button class="bg-green-500 text-white px-4 py-2 rounded" (click)="save()">บันทึก</button>
    </div>
</div>