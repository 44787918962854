import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductApiService } from '../../../product/services/api/product-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { environment } from '../../../../../environments/environment';
import { faChevronDown, faRightLong, faEye, faEyeSlash, faCopy, faCheck, faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductService } from '../../../product/services/product/product.service';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { UpdateProductSetCheckListComponent } from '../../../product/components/update-product-set-check-list/update-product-set-check-list.component';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { UserService } from '../../../../core/services/user/user.service';

interface ProductSet {
  active: boolean;
  name: string;
  products: ProductInfo[]
  set_sku: string;
  total_price: number;
  product_info: object[];
}
interface ProductInfo {
  cost: number;
  name: string;
  price: number;
  qty: number;
  sku: string;
  srp_price: number;
}
@Component({
  selector: 'app-product-set',
  templateUrl: './product-set.component.html',
  styleUrl: './product-set.component.css'
})
export class ProductSetComponent implements OnInit, OnDestroy {

  webConfig = environment.webConfig;

  faChevronDown = faChevronDown;
  faRightLong = faRightLong;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faCopy = faCopy;
  faCheck = faCheck;
  faCheckSquare = faCheckSquare

  faLongArrowAltUp = faLongArrowAltUp
  faLongArrowAltDown = faLongArrowAltDown

  form: FormGroup;
  loading: boolean = true;

  datasets: any[] = [];
  productSet: Array<ProductSet> = [];

  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  productSetDetial: any = null

  constructor(
    private fb: FormBuilder,
    private productApiService: ProductApiService,
    private productService: ProductService,
    private modalService: ModalService,
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.form = this.fb.group({
      keyword: [this.productService.productSetfilteres.keyword],
      active: [this.productService.productSetfilteres.active],
      price_change: [this.productService.productSetfilteres.price_change],
      checked_all: [this.productService.productSetfilteres.checked_all]
    });
    this.setDefaultPagination()
  }

  async ngOnInit(): Promise<void> {
    this.getDataProductSet()
  }

  ngOnDestroy(): void {
    this.storeFilter()
    this.storePagination()
  }


  storeFilter() {
    this.productService.productSetfilteres = this.form.value;
  }

  storePagination() {
    this.productService.productSetPagination.pageSize = this.pageSize;
    this.productService.productSetPagination.pageIndex = this.pageIndex;
    this.productService.productSetPagination.limit = this.limit;
    this.productService.productSetPagination.offset = this.offset;
  }

  setDefaultPagination() {
    this.pageSize = this.productService.productSetPagination.pageSize
    this.pageIndex = this.productService.productSetPagination.pageIndex
    this.limit = this.productService.productSetPagination.limit
    this.offset = this.productService.productSetPagination.offset
  }

  getDataProductSet() {
    this.loading = true;
    const value = this.form.getRawValue();
    const filters: any = { limit: this.limit, offset: this.offset };
    if (value.keyword) {
      filters['keyword'] = value.keyword
    }
    if (value.active !== undefined && value.active !== null) {
      filters['active'] = value.active
    }
    if (value.price_change !== undefined && value.price_change !== null) {
      filters['price_change'] = value.price_change
    }
    if (value.checked_all !== undefined && value.checked_all !== null) {
      filters['checked_all'] = value.checked_all
    }
    this.productApiService.getAllProductSet(filters).then((res: any) => {
      if (res.success) {
        this.productSet = res.data;
        this.countDataSource = res.count;
      } else {
        this.productSet = [];
        this.countDataSource = 0;
      }
      this.reformattingDataset();
      this.loading = false;
    })
  }

  reformattingDataset() {
    this.datasets = [];
    for (const row of this.productSet) {
      const value = (this.webConfig.percentSetList + 100) / 100;
      const products = row.products.map(e => {
        const product: any = row.product_info.find((item: any) => item.sku === e.sku);
        const currentCost = product ? product?.prices[this.webConfig.costPriceIndex] : 0
        return {
          ...e,
          cost_percent: (Math.round(e.cost * value * 10) / 10),
          current_cost_percent: (Math.round(currentCost * value * 10) / 10),
        }
      })
      this.datasets.push({
        ...row,
        products,
        total_srp: row.products.reduce((n, { srp_price, qty }) => n + (srp_price * qty), 0)
      })
    }
    console.log(this.datasets)
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getDataProductSet();
  }

  callbackActiveToggle = (event: boolean, id: string) => {
    const setSku = id.split('-')[2]
    this.productApiService.updateProductSet(setSku, { active: event }).then((res: any) => {
      if (res.success && res.data) {
        this.modalService.openModal('success', 'บันทึกสำเร็จ', '')
      } else {
        this.modalService.openModal('fail', 'บันทึกไม่สำเร็จ', '').then(res => {
          const element: any = document.querySelector(`#${id}`)
          element.checked = !event
        })
      }
    })
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getDataProductSet()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  showPriceChangeDetail(setSku: string) {
    this.router.navigate(['/product-set/detail'], { queryParams: { set_sku: setSku } })
  }

  async openCheckLictDialog(productSet: any) {
    const detials = JSON.parse(JSON.stringify(productSet))
    const dialogRef = this.dialog.open(UpdateProductSetCheckListComponent, {
      data: {
        check_list: JSON.parse(JSON.stringify(detials.check_list)),
        users: this.userService.users
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: { confirm: boolean, data?: Array<{ channel: string, name: string, checked: boolean }> }) => {
      console.log(result);
      if (result.confirm) {
        const oldCheckList = detials?.check_list || []
        const newCheckList = result?.data || []
        const updateCheckList = newCheckList.filter(item => oldCheckList.find((el: any) => el.channel === item.channel).checked !== item.checked).map(item => ({ channel: item.channel, checked: item.checked }))
        const updateResponse: any = await this.productApiService.updateProductSetCheckList(productSet.set_sku, { check_list: updateCheckList })
        console.log(updateResponse)
        if (updateResponse.success) {
          this.getDataProductSet();
        } else {

        }
      } else {
        this.reformattingDataset();
      }
    });
  }

  getCheckedCheckList(checkList: any[]) {
    return checkList.filter(item => item.checked).length
  }
}
