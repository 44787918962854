import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faCheck, faExclamation, faInfo, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from '../../components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private dialog: MatDialog
  ) { }

  openModal(type: "success" | "fail" | "info" | "confirm" | 'confirm-warning', title: string, content: string) {
    return new Promise((resolve, reject) => {

      let icon: any;
      let closeBtnLabel: string;
      let confirmBtnLabel: string = '';
      let showConfirmBtn: boolean;
      let iconClass: string;
      let btnConfirmClass: string = '';
      let disableClose: boolean;
      switch (type) {
        case 'success':
          icon = faCheck
          iconClass = 'bg-lime-500 bg-gradient-bl-lime'
          closeBtnLabel = 'ปิด'
          showConfirmBtn = false
          disableClose = false
          break;
        case 'confirm':
          icon = faQuestion
          iconClass = 'bg-primary-400 bg-gradient-bl-primary'
          closeBtnLabel = 'ยกเลิก'
          confirmBtnLabel = 'ยืนยัน'
          btnConfirmClass = 'btn-primary'
          showConfirmBtn = true
          disableClose = true
          break;
        case 'info':
          icon = faInfo;
          iconClass = 'bg-primary-400 bg-gradient-bl-primary'
          closeBtnLabel = 'เข้าใจแล้ว'
          showConfirmBtn = false
          disableClose = true
          break;
        case 'fail':
          icon = faXmark;
          iconClass = 'bg-red-500 bg-gradient-bl-red'
          closeBtnLabel = 'เข้าใจแล้ว'
          showConfirmBtn = false
          disableClose = true
          break;
        case 'confirm-warning':
          icon = faExclamation
          iconClass = 'bg-red-500 bg-gradient-bl-red'
          closeBtnLabel = 'ยกเลิก'
          confirmBtnLabel = 'ยืนยัน'
          btnConfirmClass = 'btn-danger'
          showConfirmBtn = true
          disableClose = true
          break;
        default:
          icon = faInfo
          iconClass = 'bg-green-500 bg-gradient-bl-green'
          closeBtnLabel = ''
          confirmBtnLabel = ''
          btnConfirmClass = ''
          showConfirmBtn = false
          disableClose = false
      }

      let _popup = this.dialog.open(ModalComponent, {
        width: '80%',
        maxWidth: '480px',
        enterAnimationDuration: '150ms',
        exitAnimationDuration: '100ms',
        data: {
          icon,
          iconClass,
          title,
          content,
          closeBtnLabel,
          confirmBtnLabel,
          btnConfirmClass,
          showConfirmBtn
        },
        disableClose
      });
      _popup.afterClosed().subscribe(result => {
        resolve(result)
      })
    })
  }

}
