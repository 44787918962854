import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../services/util/util.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMMM, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-month-year-picker',
  templateUrl: './month-year-picker.component.html',
  styleUrl: './month-year-picker.component.css',
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'th-TH' }, // Set the locale to Thai
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MonthYearPickerComponent {
  faCalendarDay = faCalendarDay

  @Input() label: string;
  @Input() maxDate?: Date | null;
  @Input() minDate?: Date | null;
  @Input() selectedDate?: Date | null;
  @Output() selectDateChange: EventEmitter<string> = new EventEmitter<string>();


  form: FormGroup;

  constructor(
    private utilService: UtilService,
    private fb: FormBuilder,
  ) {
    this.label = 'เดือน-ปี';
    this.form = this.fb.group({
      localSelectedDate: [new Date()],
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedDate']) {
      this.form.get('localSelectedDate')?.setValue(new Date(changes['selectedDate'].currentValue))
    }
  }

  onSelectedDateCange(): void {
    this.selectDateChange.emit(this.utilService.dayjs(this.form.get('localSelectedDate')?.value).format())
  }

  chosenYearHandler(normalizedDate: any) {
    const ctrlValue = this.form.get('localSelectedDate')?.value;
    ctrlValue?.setFullYear(normalizedDate.year())
    this.form.get('localSelectedDate')?.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedDate: any, datepicker: MatDatepicker<any>) {
    const ctrlValue = this.form.get('localSelectedDate')?.value;
    ctrlValue?.setMonth(normalizedDate.month())
    ctrlValue?.setFullYear(normalizedDate.year())
    this.form.get('localSelectedDate')?.setValue(ctrlValue);
    this.onSelectedDateCange()
    datepicker.close();
  }
}
