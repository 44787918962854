import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { MatSelectChange } from '@angular/material/select';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { environment } from '../../../../../environments/environment';

interface User {
  user_id: string;
  user_line_id: string;
  name: string;
  image: string;
  request_date: string;
  role: string;
}

@Component({
  selector: 'app-setting-permission',
  templateUrl: './setting-permission.component.html',
  styleUrl: './setting-permission.component.css'
})
export class SettingPermissionComponent implements OnInit {

  userRequest: any[] = [];
  userInfo: any[] = [];
  userDatasets: User[] = [];

  roles = this.authService.roles;

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private modalService: ModalService
  ) { }

  async ngOnInit(): Promise<void> {
    const [userRequest, userInfo]: any = await Promise.all([
      this.getAllUserRequest(),
      this.getAllUserInfo()
    ])
    this.userRequest = userRequest;
    this.userInfo = userInfo;
    this.reformattingDataset(userRequest, userInfo)
  }

  getAllUserRequest() {
    return new Promise((resolve, reject) => {
      this.authService.getAllUserRequest().then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve([])
        }
      })
    })
  }

  getAllUserInfo() {
    return new Promise((resolve, reject) => {
      this.authService.getAllUserInfo().then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve([])
        }
      })
    })
  }

  reformattingDataset(requests: any[], infos: any[]) {
    this.userDatasets = [];
    for (const request of requests) {
      const user = infos.find(e => e.user_id === request.user_id)?.channels?.find((e: any) => e.type === 'line')?.info || {};
      const permission = infos.find(e => e.user_id === request.user_id)?.permissions?.find((e: any) => e.active && e.selected) || {};

      this.userDatasets.push({
        user_id: request.user_id,
        user_line_id: user.userId,
        name: user?.displayName || request.info.name,
        image: user?.pictureUrl || request.info.image,
        request_date: this.utilService.dayjs(request.created.date).format('DD/MM/YYYY HH:mm'),
        role: permission.role
      })

    }
  }

  async selectionRoleChange(event: MatSelectChange, user: User) {
    if (event.value) {
      let count = 0;
      const responseRole = await this.addUserRole(event.value, user)
      if (responseRole) {
        count++;
        const reponseSelected = await this.selectRoleChange(event.value, user)
        if (reponseSelected) count++
      }

      if (count === 2) {
        this.modalService.openModal('success', 'สำเร็จ', `อัพเดตสำเร็จ`)
      } else {
        this.modalService.openModal('fail', 'ไม่สำเร็จ', `อัพเดตสิทธิ์การใช้งานไม่สำเร็จ`)
      }

    } else {
      let count = 0;
      const permissions = this.userInfo.find(e => e.user_id === user.user_id).permissions;
      for (const item of permissions) {
        const responseRole = await this.removeUserRole(item.role, user)
        if (responseRole) count++;
      }

      if (count === permissions.length) {
        this.modalService.openModal('success', 'สำเร็จ', `อัพเดตสำเร็จ`)
      } else {
        this.modalService.openModal('fail', 'ไม่สำเร็จ', `อัพเดตสิทธิ์การใช้งานไม่สำเร็จ`)
      }

    }
  }

  addUserRole(role: string, user: User) {
    const systemId = environment.systemId;
    const dataUpdatePermission = {
      "user_id": user.user_id,
      "system_id": systemId,
      "role": role,
      "active": true
    }

    return new Promise((resolve, reject) => {
      this.authService.updatePermission(dataUpdatePermission).then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve(false)
        }
      })
    })
  }

  removeUserRole(role: string, user: User) {
    const systemId = environment.systemId;
    const dataUpdatePermission = {
      "user_id": user.user_id,
      "system_id": systemId,
      "role": role,
      "active": false
    }

    return new Promise((resolve, reject) => {
      this.authService.updatePermission(dataUpdatePermission).then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve(false)
        }
      })
    })
  }

  selectRoleChange(role: string, user: User) {
    const systemId = environment.systemId;
    const body = {
      user_id: user.user_id,
      role: role,
      system_id: systemId
    }

    return new Promise((resolve, reject) => {
      this.authService.updateRoleMemberSelected(body).then((result: any) => {
        if (result.success) {
          resolve(result.data)
        } else {
          resolve(false)
        }
      })
    })
  }

}
