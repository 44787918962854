import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateCampaignDialogComponent } from '../../components/update-campaign-dialog/update-campaign-dialog.component';
import { CreateCampaignDialogComponent } from '../../components/create-campaign-dialog/create-campaign-dialog.component';
import { SettingApiService } from '../../services/api/setting-api.service';
import { ModalService } from '../../../../core/services/modal/modal.service';

interface CampaignGroup {
  id: string;
  name: string;
  note: string;
  priority: number;
  color: string;
}

interface PriceCampaign {
  id: string;
  name: string;
  campaign_group_id: string;
}


@Component({
  selector: 'app-setting-campaign',
  templateUrl: './setting-campaign.component.html',
  styleUrl: './setting-campaign.component.css'
})
export class SettingCampaignComponent implements OnInit {

  campaignGroups: CampaignGroup[] = []
  priceCampaigns: PriceCampaign[] = []


  constructor(
    public dialog: MatDialog,
    private modalService: ModalService,
    private settingApiService: SettingApiService
  ) {

  }


  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.getPriceCampaign(),
      this.getCampaignGroup()
    ])
  }

  async getPriceCampaign() {
    await this.settingApiService.getAllPriceCampaign().then((res: any) => {
      if (res.success) {
        this.priceCampaigns = res.data;
      }
    })
  }

  async getCampaignGroup() {
    await this.settingApiService.getAllCampaignGroup().then((res: any) => {
      if (res.success) {
        this.campaignGroups = res.data;
      }
    })
  }

  filterPriceCampaigns(campaignGroupId: string) {
    return this.priceCampaigns.filter(e => e.campaign_group_id === campaignGroupId);
  }

  openUpdateCampaignDialog(campaignGroup: CampaignGroup) {
    const filteredPriceCampaigns = this.priceCampaigns.filter(e => e.campaign_group_id === campaignGroup.id);

    const dialogRef = this.dialog.open(UpdateCampaignDialogComponent, {
      data: {
        id: campaignGroup.id,
        name: campaignGroup.name,
        note: campaignGroup.note,
        color: campaignGroup.color,
        priceCampaigns: JSON.parse(JSON.stringify(filteredPriceCampaigns))
      }
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.confirm) {

        const value = result.data;
        const dataUpdateCampaignGroup = {
          name: value.name,
          note: value.note,
          color: value.color
        }

        const responseCreate: any = await this.settingApiService.updateCampaignGroup(campaignGroup.id, dataUpdateCampaignGroup)
        if (!responseCreate.success) {
          this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถอัพเดต Campaign Price กรุณาลองใหม่อีกครั้งในภายหลัง')
          return;
        }

        let count = 0;
        for (const item of value.priceCampaign) {

          if (filteredPriceCampaigns.find(e => e.id === item.id)) {
            const responseUpdatePriceCampaign: any = await this.settingApiService.updatePriceCampaign(item.id, { name: item.name })
            if (responseUpdatePriceCampaign.success) count++;

          } else {
            const dataCreatePriceCampaign = {
              campaign_group_id: campaignGroup.id,
              name: item.name
            }
            const responseCreatePriceCampaign: any = await this.settingApiService.createPriceCampaign(dataCreatePriceCampaign)
            if (responseCreatePriceCampaign.success) count++;
          }

        }

        this.ngOnInit();
        this.modalService.openModal('success', 'สำเร็จ', `อัพเดต Campaign Price สำเร็จ ${count}/${value.priceCampaign.length}`)

      }
    });

  }

  openCreateCampaignDialog() {
    const dialogRef = this.dialog.open(CreateCampaignDialogComponent, {});
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.confirm) {

        const value = result.data;
        const dataCreateCampaignGroup = {
          name: value.name,
          note: value.note,
          priority: this.campaignGroups.length + 1,
          color: value.color
        }

        const responseCreate: any = await this.settingApiService.createCampaignGroup(dataCreateCampaignGroup)
        if (!responseCreate.success) {
          this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถสร้าง Campaign Price กรุณาลองใหม่อีกครั้งในภายหลัง')
          return;
        }

        const campaignGroupId = responseCreate.data.id;
        let count = 0;
        for (const item of value.priceCampaign) {
          const dataCreatePriceCampaign = {
            campaign_group_id: campaignGroupId,
            name: item.name
          }
          const responseCreatePriceCampaign: any = await this.settingApiService.createPriceCampaign(dataCreatePriceCampaign)
          console.log(responseCreatePriceCampaign.success);
          if (responseCreatePriceCampaign.success) count++;
        }

        this.ngOnInit();
        this.modalService.openModal('success', 'สำเร็จ', `สร้าง Campaign Price สำเร็จ ${count}/${value.priceCampaign.length}`)

      }
    });
  }

}
