import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.css'
})
export class SettingComponent implements OnInit {

  header: any;

  menus = [
    { key: 'channel', name: 'ร้านค้า', detail: 'ตั้งค่าช่องทางการสั่งซื้อ' },
    { key: 'permission', name: 'สิทธิ์การใช้งาน', detail: 'ตั้งค่าสิทธิ์การเข้าใช้งาน' },
    { key: 'campaign', name: 'แคมเปญ', detail: 'ตั้งค่าแคมเปญ' }
  ]

  menuSelected: any = this.menus[0];

  constructor(

  ) {
    this.header = document.querySelector("#header-content");
  }


  async ngOnInit(): Promise<void> {

  }

  selectedMenuOnChange(menu: any): void {
    this.menuSelected = menu;
  }

}
