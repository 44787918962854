<div class="w-full flex space-x-2 overflow-x-auto pb-2 xl:pb-0">
    <!-- Day Pass Buttons -->
    <button *ngFor="let option of dateOptions; trackBy: trackByFn" type="button"
        class="text-center text-gray-600 text-sm border rounded-lg px-3 py-1.5 flex-wrap whitespace-nowrap"
        [ngClass]="{'bg-primary-900 text-white': selectedOption?.value === option.id && selectedOption?.type === 'daypass'}"
        (click)="onSelectDateOption(option.id)">
        {{ option.name }}
    </button>
    <!-- Date Range Picker -->
    <div class="w-60">
        <mat-form-field class="w-full">
            <button class="text-gray-600 text-sm border rounded-lg py-1.5 pl-3 pr-10 relative"
                [ngClass]="{'!bg-secondary-900 !text-white': selectedOption?.type === 'date-range'}"
                (click)="dateRangePicker.open()">
                <div class="whitespace-nowrap">{{onDateSelected(selectedStartDate)}} ถึง
                    {{onDateSelected(selectedEndDate)}}</div>
                <div class="absolute inset-0 justify-end pr-2 flex items-center hover:cursor-pointer"
                    (click)="dateRangePicker.open()">
                    <fa-icon [icon]="faCalendar" [ngClass]="{' !text-white': selectedOption?.type === 'date-range'}"
                        class="text-lg text-secondary-500 flex justify-center items-center"></fa-icon>
                </div>
            </button>
            <mat-date-range-input class="!hidden" id="dateRangePicker" [rangePicker]="dateRangePicker" [max]="maxDate">
                <input matStartDate [(ngModel)]="selectedStartDate" [max]="maxDate"
                    [ngModelOptions]="{standalone: true}">
                <input matEndDate [(ngModel)]="selectedEndDate" [max]="maxDate" [ngModelOptions]="{standalone: true}"
                    (dateChange)="onDateRangeSelected()">
            </mat-date-range-input>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
        </mat-form-field>
    </div>
</div>