import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  roles = [
    { id: 'admin', name: 'Admin' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'purchase', name: 'Purchase' }
  ]

  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getRole(role: string) {
    return this.roles.find(e => e.id === role)?.name || role;
  }

  activatedRoute(url?: string) {
    // เข้าถึงค่า expectedRole จาก ActivatedRoute
    let index = -1;
    if (url) {
      index = this.router.config.findIndex((item: any) => item.path === url.slice(1));
    } else {
      index = this.router.config.findIndex((item: any) => item.path === this.router.url.slice(1));
    }
    if (index > -1) {
      const data: any = this.router.config[index].data || null;
      if (data && data.expectedRole) {
        const expectedRole: string[] = data.expectedRole
        return this.authService.user?.role ? expectedRole.includes(this.authService.user.role) : false;
      } else {
        return true
      }
    } else {
      return false
    }
  }

  updateRoleSelected(body: any) {
    const headers = this.authService.header()
    const endPoint = this.authService.getAPIAuthEndPoint()
    return new Promise((resolve, reject) => {
      return this.http.patch(`${endPoint}/user/update-role`, body, headers).subscribe((data) => {
        resolve(data);
      });
    })
  }

}
