import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts'
import * as shape from 'd3-shape';

interface Series {
  name: string;
  value: number;
}
interface Dataset {
  name: string;
  series: Series[]
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.css',
  encapsulation: ViewEncapsulation.None
})
export class LineChartComponent {

  @Input() chartId: string = '';
  @Input() datasets: Dataset[] = [];
  @Input() legendPosition = LegendPosition.Below;
  @Input() colorScheme: Color | string = 'vivid';

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = false;
  legendTitle: string = ' '
  // legendPosition = LegendPosition.Below
  curve = shape.curveMonotoneX;
  roundDomains: boolean = true;
  wrapTicks: boolean = true;

  // colorScheme = 'vivid';
  // colorScheme: Color = {
  //   domain: ['#3357FF', '#dd5882','#d41830','#FF5733', '#2af0ea',],
  //   group: ScaleType.Ordinal,
  //   selectable: true,
  //   name: 'custom Usege'
  // };

  constructor() {
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }


}
