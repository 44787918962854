<div class="content space-y-4">
    <div class="flex justify-between items-center">
        <div class="flex font-medium text-primary-400 gap-2">
            <div class="">
                <img src="assets/svg/price.svg" alt="" class="w-5">
            </div>
            <div class="">สต๊อกเซ็ตสินค้า</div>
        </div>
        <div class="">
            @if(canAccess()){
            <button class="btn-sm btn-primary" (click)="openSyncProductDialog()">ซิงค์รายการสินค้า</button>
            }
        </div>
    </div>
    <div class="card">
        <form [formGroup]="form" class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="w-full">
                <label class="text-sm">สินค้า</label>
                <input class="input" type="text" placeholder="กรอกชื่อ/รหัสสินค้า" formControlName="keyword">
            </div>
            <div class="">
                <label class="text-sm">หมวดหมู่</label>
                <mat-form-field class="w-full">
                    <mat-select class="" placeholder="เลือกหมวดหมู่" formControlName="category_id">
                        <mat-option value="">ทั้งหมด</mat-option>
                        @for (item of categories; track $index) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="">
                <label class="text-sm">จำนวนในคลัง</label>
                <div class="flex gap-2 items-center">
                    <input class="input" type="number" placeholder="ต่ำสุด" formControlName="min_stock">
                    <div class="">-</div>
                    <input class="input" type="number" placeholder="สูงสุด" formControlName="max_stock">
                </div>
            </div>
            <button class="btn-sm btn-primary w-fit self-end" (click)="submitForm()">ค้นหา</button>
        </form>
    </div>
    @if(loading){
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <app-loader></app-loader>
            <div class="font-medium">Loading</div>
        </div>
    </div>
    }
    @if (!loading && datasets.length === 0) {
    <div class="card">
        <div class="flex flex-col items-center py-6 space-y-3">
            <div class="h-32">
                <img src="assets/images/web_20.png" alt="" class="h-full">
            </div>
            <div class="font-medium">Data Not Found</div>
        </div>
    </div>
    }
    @else if (!loading && datasets.length > 0){
    <div class="card">
        <div>
            <div class="div-table">
                <table>
                    <thead>
                        <tr class="thead-tr divide-x">
                            <th class="text-left">
                                สินค้า
                            </th>
                            <th class="text-center">
                                จำนวนในคลัง
                            </th>
                            <!-- <th class="text-center">
                                จำนวนในแพลตฟอร์ม
                            </th> -->
                            @for (channelsHeader of channelsHeaders; track $index) {
                            <th>
                                {{channelsHeader}}
                            </th>
                            }
                            <th class="text-center">
                                จัดการ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of datasets; track $index) {
                        <tr class="tbody-tr divide-x">
                            <td class="">
                                <div class="flex items-center">
                                    <img [src]="'https://storage.maxxgadget.com/img/product/' + row.sku + '/1.jpg'"
                                        alt="" class="flex-none w-8 h-8 mr-2"
                                        onerror="this.onerror=null; this.src='assets/images/warranty.png'">
                                    <div class="w-full flex flex-col truncate">
                                        <span class="w-full truncate whitespace-nowrap">{{ row.name }}</span>
                                        <div class="flex gap-2 items-center">
                                            <span class="text-xs pr-2 text-blue-500">{{row.sku}}</span>
                                            <span class="label label-primary">{{row.category.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center w-36" [ngClass]="{'text-red-500':row.in_stock===0}">
                                {{row.in_stock | number}}
                            </td>
                            <!-- <td class="text-left border-r w-44">
                                <div class="space-y-2 w-44">
                                    @for (product of row.stocks; track $index) {
                                    <div class="flex justify-center items-center w-full"
                                        matTooltip="SKU: {{product.platform_sku}}">
                                        <div class="flex items-center">
                                            <div class="text-gray-500 text-xs pr-2">
                                                <span>{{product.channel_name}}:</span>
                                            </div>
                                        </div>
                                        <div class="font-semibold text-primary-500" [ngClass]="{'text-red-500':product.sellable===0}">{{product.sellable}}</div>
                                    </div>
                                    }
                                </div>
                            </td> -->
                            @for (channelsHeader of channelsHeaders; track $index) {
                            <td class="text-left border-r w-36">
                                <div class="space-y-2 w-36">
                                    @for (product of getStockByPlatform(channelsHeader, row.stocks); track $index) {
                                    <div class="flex justify-center items-center w-full"
                                        [matTooltip]="getTooltipText(product)" matTooltipClass="!w-44 whitespace-pre-line text-le">
                                        <div class="flex items-center">
                                            <div class="text-gray-500 text-xs pr-2 truncate">
                                                <span>{{product.channel_name}}:</span>
                                            </div>
                                        </div>
                                        <div class="font-semibold text-primary-500"
                                            [ngClass]="{'text-red-500':product.sellable===0}">{{product.sellable}}</div>
                                    </div>
                                    }
                                </div>
                            </td>
                            }
                            <td class="w-44">
                                <div class="flex justify-center w-44">
                                    <button class="btn-sm btn-light" (click)="openUpdateStockDialog(row.sku)"
                                        [disabled]="row.stocks.length <= 0">อัพเดตสต๊อก</button>
                                </div>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <app-pagination [callBackPageChange]="callBackPageChange" [pageSize]="pageSize" [pageIndex]="pageIndex"
                [length]="countDataSource"></app-pagination>
        </div>
    </div>
    }
</div>