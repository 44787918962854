import { Component } from '@angular/core';

@Component({
  selector: 'app-purchase-analysis',
  templateUrl: './purchase-analysis.component.html',
  styleUrl: './purchase-analysis.component.css'
})
export class PurchaseAnalysisComponent {
  state: string = 'sale';
}
