import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface TagDialogData {
  tags: string[];
}

@Component({
  selector: 'app-product-tag-management',
  templateUrl: './product-tag-management.component.html',
  styleUrls: ['./product-tag-management.component.css']
})
export class ProductTagManagementComponent {
  tags: string[] = [];
  tagForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProductTagManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TagDialogData
  ) {
    this.tagForm = this.fb.group({
      tag: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // รับค่า tags จาก dialog data
    if (this.data && this.data.tags && this.data.tags.length > 0) {
      this.tags = [...this.data.tags];
    }
  }

  addTag(): void {
    const newTag = this.tagForm.value.tag.trim();
    if (newTag && !this.tags.includes(newTag)) {
      this.tags = [...this.tags, newTag];
      this.tagForm.reset();
    }
  }

  removeTag(tag: string): void {
    this.tags = this.tags.filter(t => t !== tag);
  }

  save(): void {
    // ส่งค่า tags กลับพร้อมยืนยันการบันทึก
    this.dialogRef.close({
      confirm: true,
      tags: this.tags
    });
  }

  cancel(): void {
    // ปิด dialog โดยไม่บันทึกการเปลี่ยนแปลง
    this.dialogRef.close({
      confirm: false
    });
  }
}
