import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../../core/services/api/api.service';

@Component({
  selector: 'app-sync-product-dialog',
  templateUrl: './sync-product-dialog.component.html',
  styleUrl: './sync-product-dialog.component.css'
})
export class SyncProductDialogComponent {

  faCheckCircle = faCheckCircle;

  endpoint = environment.endpoint;

  syncProductList = [
    {
      name: '[Lazada] โหลดข้อมูลรหัสสินค้าจาก Platform',
      link: {
        url: `${this.endpoint}/product-platform/sync/product/lazada/all`,
        method: 'patch'
      },
      note: 'ทำงานทุก 1 ชั่วโมง (08:00, 09:00, ...,18:00)',
      delay: 0
    },
    {
      name: 'โหลดข้อมูลรายละเอียดสินค้า',
      link: {
        url: `${this.endpoint}/product/sync-all`,
        method: 'post'
      },
      note: 'ทำงานทุก 1 ชั่วโมง (08:03 ,08:03, ...,18:03)',
      delay: 0
    },
  ]

  syncStockList = [
    {
      name: 'โหลดข้อมูลจำนวนสินค้าล่าสุดในคลัง',
      link: {
        url: `${this.endpoint}/product/sync-stock`,
        method: 'post'
      },
      note: 'ทำงานทุก 5 นาที (00:00, 00:05, ...,23:55)',
      delay: 8000
    },
    {
      name: 'โหลดข้อมูลจำนวนสินค้าเซ็ตล่าสุดในคลัง',
      link: {
        url: `${this.endpoint}/product-set/sync-stock`,
        method: 'post'
      },
      note: 'ทำงานทุก 5 นาที (00:02, 00:07, ...,23:57)',
      delay: 0
    },
    {
      name: '[Lazada] Update stock ไปยัง Platform',
      link: {
        url: `${this.endpoint}/product-platform/sync/stock/lazada/all`,
        method: 'patch'
      },
      note: 'ทำงานทุก 5 นาที (00:03, 00:08, ...,23:58)',
      delay: 0
    },
  ]

  loading: any;
  called: string[] = [];

  constructor(
    private apiService: ApiService,
  ) {

  }

  callApi(event: any, delay: number, link: { url: string, method: string }) {

    if (this.loading) {
      return;
    }

    const id = event.target.id;
    this.loading = id;

    console.log(this.loading);

    if (link.method === 'post') {
      this.apiService.postCall(link.url, {}).then(async (res) => {
        await this.delay(delay)
        this.loading = undefined;
        this.called.push(id);
      })

    } else if (link.method === 'patch') {
      this.apiService.patchCall(link.url, {}).then(async (res) => {
        await this.delay(delay)
        this.loading = undefined;
        this.called.push(id);
      })

    } else if (link.method === 'get') {
      this.apiService.getCall(link.url, {}).then(async (res) => {
        await this.delay(delay)
        this.loading = undefined;
        this.called.push(id);
      })
    }

  }

  checkCalledApi(id: string) {
    return this.called.includes(id);
  }

  delay(time: number) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, time);
    })
  }


}
