import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from '../../../../core/services/util/util.service';

@Component({
  selector: 'app-update-product-set-check-list',
  templateUrl: './update-product-set-check-list.component.html',
  styleUrl: './update-product-set-check-list.component.css'
})
export class UpdateProductSetCheckListComponent implements OnInit {

  checkList: Array<{ channel: string, name: string, checked: boolean, user_id: string, date: number }> = []
  users: Array<{ user_id: string, name: string }> = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private util: UtilService,
    private ref: MatDialogRef<UpdateProductSetCheckListComponent>
  ) {
    console.log(data)
  }


  ngOnInit(): void {
    this.checkList = this.data.check_list;
    this.users = this.data.users;
    console.log(this.users)
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    this.ref.close({ confirm: true, data: this.checkList });
  }

  getUserName(userId: string) {
    return this.users.find(item => item.user_id === userId)?.name
  }

  getDateString(date: number) {
    return this.util.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }
}
