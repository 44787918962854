import { Component, OnInit } from '@angular/core';
import { DataAnalysisApiService } from '../../services/api/data-analysis-api.service';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { Color, ScaleType } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-data-analysis-sale',
  templateUrl: './data-analysis-sale.component.html',
  styleUrl: './data-analysis-sale.component.css'
})
export class DataAnalysisSaleComponent implements OnInit {

  channels: any[] = [];

  saleDatasets: { name: string, series: { name: string, value: number }[] }[] = [];

  formattedData: { date: string, channel: string, total_order: number, total_amount: number, subtotal: number, total_discount: number }[] = []

  selectedDate = { type: 'daypass', value: '0' };
  selectedChannel: any;
  toggleAll: boolean = true;

  summary = {
    totalOrder: 0,
    subtotal: 0,
    totalDiscount: 0,
    totalAmount: 0,
    avgAmount: 0
  }

  displayData: 'total_order' | 'total_amount' | 'total_discount' = 'total_order';

  colorScheme: Color = {
    domain: [],
    group: ScaleType.Ordinal,
    selectable: true,
    name: 'platform color'
  }

  loading = false

  constructor(
    private utilService: UtilService,
    private dataAnalysisApiService: DataAnalysisApiService,
    private platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.channels = this.platformService.channels
    this.getDataReport();
  }

  async getDataReport() {
    console.log(this.selectedChannel)
    console.log(this.selectedDate)

    this.loading = true;

    let filters: any = {}
    if (this.selectedChannel?.length > 0) {
      filters['channel'] = this.selectedChannel.toString()
    } else {
      filters['channel'] = this.channels.map((item) => item.shop_ref).toString()
    }

    if (this.selectedDate.type === 'daypass') {

      if (this.selectedDate.value === '0') {
        const date = this.utilService.dayjs().format('YYYY-MM-DD')
        filters = { ...filters, date }
        const result: any = await this.dataAnalysisApiService.getSaleReportByDate(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.time_period }));
        this.saleDatasets = this.formatData(this.formattedData)

      } else if (this.selectedDate.value === '1') {
        const date = this.utilService.dayjs().subtract(parseInt(this.selectedDate.value), 'day').format('YYYY-MM-DD')
        filters = { ...filters, date }
        const result: any = await this.dataAnalysisApiService.getSaleReportByDate(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.time_period }));
        this.saleDatasets = this.formatData(this.formattedData)

      } else {
        const startDate = this.utilService.dayjs().subtract(parseInt(this.selectedDate.value), 'day').format('YYYY-MM-DD')
        const endDate = this.utilService.dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        const dateRange = [startDate, endDate].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.dataAnalysisApiService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)
      }
    }
    if (this.selectedDate.type === 'date-range') {
      const startDate = this.utilService.dayjs(this.selectedDate.value.split(',')[0]).format('YYYY-MM-DD')
      const endDate = this.utilService.dayjs(this.selectedDate.value.split(',')[1]).format('YYYY-MM-DD')

      if (startDate != endDate) {
        const dateRange = [startDate, endDate].toString()
        filters = { ...filters, date: dateRange }
        const result: any = await this.dataAnalysisApiService.getSaleReportByDateRange(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.date }));
        this.saleDatasets = this.formatData(this.formattedData)

      } else {
        const date = this.utilService.dayjs(startDate).format('YYYY-MM-DD')
        filters = { ...filters, date }
        const result: any = await this.dataAnalysisApiService.getSaleReportByDate(filters)
        this.formattedData = result.data.map((item: any) => ({ ...item, date: item.time_period }));
        this.saleDatasets = this.formatData(this.formattedData)
      }
    }
    this.loading = false;
  }

  formatData(data: { date: string, channel: string, total_order: number, total_amount: number, subtotal: number, total_discount: number }[]) {

    this.summary = {
      totalOrder: 0,
      subtotal: 0,
      totalDiscount: 0,
      totalAmount: 0,
      avgAmount: 0
    }
    for (const item of data) {
      this.summary.totalOrder += item.total_order;
      this.summary.subtotal += item.subtotal;
      this.summary.totalDiscount += item.total_discount;
      this.summary.totalAmount += item.total_amount;
    }
    this.summary.totalOrder = parseFloat((this.summary.totalOrder).toFixed(2))
    this.summary.subtotal = parseFloat((this.summary.subtotal).toFixed(2))
    this.summary.totalDiscount = parseFloat((this.summary.totalDiscount).toFixed(2))
    this.summary.totalAmount = parseFloat((this.summary.totalAmount).toFixed(2))
    this.summary.avgAmount = parseFloat((this.summary.totalAmount / this.summary.totalOrder).toFixed(2))

    const uniqueDate = [...new Set(data.map(item => item.date))].sort()
    const result = []

    const channels: string[] = this.selectedChannel?.length > 0 ? this.selectedChannel : this.channels.map(item => item.shop_ref)

    this.colorScheme.domain = []

    for (const channel of channels) {

      const reportChannel = data.filter(item => item.channel === channel)
      console.log(reportChannel)
      const channelDetial = this.channels.find(item => item.shop_ref === channel)
      this.colorScheme.domain.push(channelDetial.color || '#32436a')

      const series = []
      for (const date of uniqueDate) {
        const reportDate = reportChannel.find(item => item.date === date)
        series.push({
          name: date,
          value: reportDate ? (reportDate[this.displayData] || 0) : 0
        })
      }

      result.push({
        name: channelDetial.platform_name,
        series
      })
    }
    return result
  }

  handleOptionSelected(selectedDate: { type: string, value: string }) {
    console.log('Selected Date Range:', selectedDate);
    this.selectedDate = selectedDate
  }

  formCreateString(value: any) {
    this.selectedChannel = value;
  }

  toggleAllSelection() {
    this.toggleAll = !this.toggleAll
    if (this.toggleAll) {
      this.selectedChannel = []
    } else {
      delete this.selectedChannel
    }
  }

  displaychannel() {
    return this.channels.filter(c => this.selectedChannel.includes(c.shop_ref)).map(c => c.platform_name).join()
  }

  displayDataOnchange(event: any) {
    this.displayData = event.value
    this.saleDatasets = this.formatData(this.formattedData)
  }
}
