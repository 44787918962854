<ngx-charts-line-chart 
    class="block w-full pb-20" 
    [scheme]="colorScheme" 
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel" 
    [xAxis]="xAxis" 
    [yAxis]="yAxis" 
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel" 
    [timeline]="timeline" 
    [results]="datasets" 
    [legend]="legend" 
    [legendTitle]="legendTitle"
    [curve]="curve" 
    [roundDomains]="roundDomains" 
    [wrapTicks]="wrapTicks" 
    [legendPosition]="legendPosition" 
    [autoScale]="true"
    (select)="onSelect($event)">
</ngx-charts-line-chart>