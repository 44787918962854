<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        อัพเดตโน๊ต
    </div>
    <div class="flex flex-col gap-4 w-full md:w-80 text-sm md:text-base">
        <textarea class="text-area" name="" id="" rows="4" [(ngModel)]="note"></textarea>
    </div>
    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-dialog-close class="btn-sm btn-light min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    ยืนยัน
                </div>
            </button>
        </div>
    </div>
</div>