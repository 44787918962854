<div class="content flex justify-center items-center" [ngStyle]="{'height': 'calc(100vh - ' + header.clientHeight + 'px)'}">
    <div class="text-center space-y-4 -mt-14">
        <!-- <div class="font-[1000] text-[150px] md:text-[250px] text-gray-800 relative flex justify-center">
            4<span class="text-gray-100">0</span>3
            <div class="flex flex-col justify-center items-center z-10 absolute bottom-16 md:bottom-28">
                <div>
                    <img src="assets/images/lock.png" class="w-28 md:w-48">
                </div>
            </div>
        </div> -->
        <img class="max-w-md" src="assets/images/rejected.png" alt="">
        <div>
            <h2 class="font-bold text-2xl">
                Forbidden
            </h2>
            <div class="text-gray-600">
                You don't have permission to view this page.
            </div>
            <div class="text-gray-600">
                Please contact the administrator if you believe this is an error.
            </div>
        </div>
    </div>
</div>