<div class="space-y-6">

    <div class="text-sm flex flex-col gap-5">
        @for (user of userDatasets; track $index) {
        <div class="flex flex-row gap-4 ">
            <div class="flex-1 flex gap-4">
                <img class="w-14 h-14 rounded-full" [src]="user.image" alt="user">
                <div class="w-full">
                    <div class="font-semibold">{{user.name}}</div>
                    <div class="text-gray-500">ส่งคำขอเมื่อ {{user.request_date}}</div>
                </div>
            </div>
            <div class="flex flex-wrap gap-3">
                <ng-container *ngFor="let role of permissionService.roles">
                    <ng-container *ngIf="checkRoleActive(role, user.permissions)">
                        <button class="flex items-center label-primary label gap-2 whitespace-nowrap h-6"
                            (click)="removeUserRole(role.id, user)">
                            <fa-icon [icon]="faCheck" class="flex items-center"></fa-icon>{{role.name}}
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!checkRoleActive(role, user.permissions)">
                        <button class="flex items-center label-light label gap-2 whitespace-nowrap opacity-70 h-6"
                            (click)="addUserRole(role.id, user)">
                            <fa-icon [icon]="faXmark" class="flex items-center"></fa-icon>{{role.name}}
                        </button>
                    </ng-container>
                </ng-container>
            </div>
            <div class="flex-none">
                <mat-form-field class="max-w-md">
                    <mat-select class="" [value]="user.role" (selectionChange)="selectionRoleChange($event,user)">
                        <mat-option value=""></mat-option>
                        @for (item of roles; track $index) {
                        <mat-option [value]="item.id">{{item.name}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        }
    </div>

</div>