import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-note-dialog',
  templateUrl: './update-note-dialog.component.html',
  styleUrl: './update-note-dialog.component.css'
})
export class UpdateNoteDialogComponent implements OnInit {

  note: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<UpdateNoteDialogComponent>
  ) {
    console.log(data)
  }


  ngOnInit(): void {
    this.note = this.data.note;
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    this.ref.close({ confirm: true, data: { note: this.note } });
  }


}
