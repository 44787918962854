import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from '../../services/util/util.service';
import { AuthService } from '../../services/auth/auth.service';
import { PermissionService } from '../../services/permission/permission.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {


  @Input('count')
  count: number = 0;
  @Input('countAll')
  countAll: number = 0;
  @Input('notifications')
  notifications: Array<any> = [];
  @Output('callBackOpenNotify')
  callBackOpenNotify: EventEmitter<object> = new EventEmitter<object>();
  @Output('callBackLoadNotify')
  callBackLoadNotify: EventEmitter<object> = new EventEmitter<object>();

  faBars = faBars;
  faUser = faUser;

  user = this.authService.user;

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public permissionService: PermissionService
  ) { }

  switchSidenav(): any {
    this.utilService.navbarOpen = !this.utilService.navbarOpen;
  }

  toggleMenu() {
    this.utilService.toggleNavbar()
  }

  // closeToggleMenu() {
  //   this.utilService.closeToggleNevbar()
  // }

  displayDatetime(date: number) {
    if (+this.utilService.dayjs() - date > (86400000 * 3)) {
      return this.utilService.dayjs(date).format('DD/MM/YYYY HH:mm')
    }
    return this.utilService.dayjs(date).fromNow()
  }

  openDetailDialog(item: any) {
    this.callBackOpenNotify.emit({ confirm: true, data: item })
  }

  loadMore($event: any) {
    $event.stopPropagation();
    this.callBackLoadNotify.emit({ confirm: true })
  }


}
