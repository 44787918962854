import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './core/pages/main/main.component';
import { DataAnalysisComponent } from './features/data-analysis/pages/data-analysis/data-analysis.component';
import { OrderComponent } from './features/order/pages/order/order.component';
import { ProductSetComponent } from './features/product-set/pages/product-set/product-set.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { SettingComponent } from './features/setting/pages/setting/setting.component';
import { ProductListComponent } from './features/product/pages/product-list/product-list.component';
import { ProductSetDetailComponent } from './features/product-set/pages/product-set-detail/product-set-detail.component';
import { PriceListComponent } from './features/price-list/pages/price-list/price-list.component';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { AuthGuardService } from './core/services/auth-guard/auth-guard.service';
import { AccessDeniedComponent } from './core/pages/access-denied/access-denied.component';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { ProductSetStockComponent } from './features/product-set/pages/product-set-stock/product-set-stock.component';
import { ProductStockComponent } from './features/product/pages/product-stock/product-stock.component';
import { CreateProductSetComponent } from './features/product-set/pages/create-product-set/create-product-set.component';


const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: MainComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'data-analysis', component: DataAnalysisComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'order', component: OrderComponent },
  { path: 'product-set/list', component: ProductSetComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'product-set/stock', component: ProductSetStockComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'product-set/create', component: CreateProductSetComponent, data: { expectedRole: ['admin', 'marketing'] }, canActivate: [AuthGuardService] },
  { path: 'product-set/detail', component: ProductSetDetailComponent, data: { expectedRole: ['admin', 'marketing'] }, canActivate: [AuthGuardService] },
  { path: 'product/list', component: ProductListComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'product/stock', component: ProductStockComponent, data: { expectedRole: ['admin', 'marketing', 'purchase'] }, canActivate: [AuthGuardService] },
  { path: 'price-list', component: PriceListComponent, data: { expectedRole: ['admin', 'marketing',] }, canActivate: [AuthGuardService] },
  { path: 'setting', component: SettingComponent, data: { expectedRole: ['admin', 'marketing'] }, canActivate: [AuthGuardService] },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: PageNotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
