import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from '../../../../core/services/util/util.service';

@Component({
  selector: 'app-data-analysis',
  templateUrl: './data-analysis.component.html',
  styleUrl: './data-analysis.component.css'
})
export class DataAnalysisComponent {

  state: string = 'sale';

  productSetDatasets: any[] = [
    {
      "name": "Shopee",
      "series": [
        {
          "name": "P01",
          "value": 7300000
        },
        {
          "name": "P02",
          "value": 8940000
        }
      ]
    }, {
      "name": "Shopee Life",
      "series": [
        {
          "name": "P01",
          "value": 7870000
        },
        {
          "name": "P02",
          "value": 8270000
        }
      ]
    }, {
      "name": "Lazada",
      "series": [
        {
          "name": "P01",
          "value": 5000002
        },
        {
          "name": "P02",
          "value": 5800000
        }
      ]
    }, {
      "name": "Tiktok",
      "series": [
        {
          "name": "P01",
          "value": 5000002
        },
        {
          "name": "P02",
          "value": 5800000
        }
      ]
    }
  ];

  constructor(
    private utilService: UtilService,
  ) { }

}
