import { Component, OnInit } from '@angular/core';
import { PlatformService } from './core/services/platform/platform.service';
import { CategoryService } from './core/services/category/category.service';
import { AuthService } from './core/services/auth/auth.service';
import { environment } from '../environments/environment';
import liff from '@line/liff';
import { UserService } from './core/services/user/user.service';
import { PlatformGroupService } from './core/services/platform-group/platform-group.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  title = 'platform-management';
  liffId: string = environment.liffId;

  constructor(
    private platformService: PlatformService,
    private platformGroupService: PlatformGroupService,
    private categoryService: CategoryService,
    public authService: AuthService,
    private userService: UserService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.authService.loaded = false;

    if (!localStorage.getItem('user')) {
      await this.login()
    }

    const userItem: any = localStorage.getItem('user')
    const profile = JSON.parse(userItem);

    await this.getAccessToken(profile)
    await Promise.all([
      this.loadPlatform(),
      this.loadPlatformGroup(),
      this.loadProductCategory(),
      this.getAllUserInfo()
    ])

    this.updateUserLineInfo(profile)
    console.log(this.authService.user);

    this.authService.loaded = true;

  }

  async getAccessToken(profile: { userId: string; displayName: string; pictureUrl: string; statusMessage: string; }) {
    await this.authService.getAccessToken(profile.userId, 'line', profile).then(async (result: any) => {
      if (result.access_token) {
        this.authService.token = result.access_token;
        this.authService.user = profile;
        this.authService.user.role = await this.getUserInfo() as string;
        this.authService.isLogin = true;
      }
    })
  }


  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.authService.getUserInfo().then((result: any) => {
        if (result.success && result.data) {
          const index = result.data.permissions.findIndex((item: any) => item.selected && item.active);
          if (index > -1) {
            resolve(result.data.permissions[index].role)
          } else {
            resolve(null)
          }
        } else {
          resolve(null)
        }
      })
    })
  }

  async updateUserLineInfo(profile: { userId: string; displayName: string; pictureUrl: string; statusMessage: string; }) {
    await this.authService.updateLineInfo(profile).then((res: any) => {
      console.log(res?.success);
    })
  }

  async login() {
    await liff.init({ liffId: this.liffId }).then(async () => {
      localStorage.setItem('method', 'line');
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        localStorage.setItem('user', JSON.stringify(profile));
      } else {
        liff.login();
      }
    }).catch((err: any) => {
      console.log(err.code, err.message);
    });
  }

  async loadPlatform() {
    await this.platformService.getAllPlatform({}).then((res: any) => {
      if (res.success) {
        this.platformService.channels = res.data.filter((item: any) => item.visible).map((e: any) => ({
          platform_id: e.platform_id,
          platform_name: e.platform_name,
          shop_ref: e.shop_ref,
          member_id: e.member_id,
          color: e.color,
          logo_url: e.logo_url,
          platform: e.platform,
          platform_group_id: e.platform_group_id
        }))
        this.platformService.channelsAll = res.data.map((e: any) => ({
          platform_id: e.platform_id,
          platform_name: e.platform_name,
          shop_ref: e.shop_ref,
          member_id: e.member_id,
          color: e.color,
          logo_url: e.logo_url,
          platform: e.platform,
          platform_group_id: e.platform_group_id
        }))
      }
    })
  }

  async loadPlatformGroup() {
    await this.platformGroupService.getAllPlatformGroup({}).then((res: any) => {
      if (res.success) {
        this.platformGroupService.platformGroup = res.data.map((e: any) => ({
          id: e.id,
          name: e.name,
          color: e.color
        }))
      }
    })
  }

  async loadProductCategory() {
    await this.categoryService.getAllCategory().then((res: any) => {
      if (res.success) {
        this.categoryService.categories = res.data.map((e: any) => ({ id: e.id, name: e.name })).sort((a: any, b: any) => a.name - b.name)
      }
    })
  }

  getAllUserInfo() {
    this.authService.getAllUserInfo().then((result: any) => {
      if (result.success) {
        this.userService.users = result.data.map((item: any) => ({
          user_id: item.user_id,
          name: item?.channels?.find((el: any) => el?.type === 'line')?.info?.displayName || ''
        }))
      } else {
        this.userService.users = []
      }
    })
  }


}
