import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  users: Array<{ user_id: string, name: string }> = [] /// user info

  constructor() { }
}
