import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../services/modal/modal.service';
import { environment } from '../../../../environments/environment';
import { PermissionService } from '../../services/permission/permission.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  faArrowRightFromBracket = faArrowRightFromBracket;

  user = this.authService.user;
  userInfo: any;
  system_id = environment.systemId

  constructor(
    public authService: AuthService,
    private modalService: ModalService,
    public permissionService: PermissionService,
  ) { }


  async ngOnInit(): Promise<void> {
    this.userInfo = await this.getUserInfo();
    console.log(this.userInfo)
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      this.authService.getUserInfo().then((result: any) => {
        console.log(result)
        if (result.success && result.data) {
          resolve(result.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  logout() {
    this.modalService.openModal('confirm', 'คุณต้องการออกจากระบบ?', '').then(res => {
      if (res) {
        this.authService.logout();
      }
    });
  }

  selectedPermission(role: string) {
    const body = {
      role,
      system_id: this.system_id
    }

    console.log(body)
    this.permissionService.updateRoleSelected(body).then((result: any) => {
      console.log(result)
      window.location.reload();
      if (result.success) {
        this.userInfo.permissions = result.data.permissions.filter((item: any) => item.system_id === this.system_id)
        if (this.authService.user) {
          this.authService.user.role = role;
        }
        const user: any = localStorage.getItem('user')
        localStorage.setItem('user', JSON.stringify({ ...JSON.parse(user), role }));
      } else {
        console.log('ERROR')
        this.modalService.openModal('fail', 'Ooop!', 'ไม่สามารถเปลี่ยนสิทธิ์ได้ในขณะนี้')
      }
    })
  }

}
