import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductApiService } from '../../services/api/product-api.service';
import { CategoryService } from '../../../../core/services/category/category.service';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { PlatformService } from '../../../../core/services/platform/platform.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.css'
})
export class ProductListComponent implements OnInit {

  faCheck = faCheck;
  faPlus = faPlus;

  form: FormGroup;
  loading: boolean = true;

  categories: Array<{ id: string; name: string; }> = this.categoryService?.categories || []
  products: Array<{ sku: string; name: string; category: Object; in_stock: number; prices: Object; }> = []
  priceList: Array<{ id: string; sku: string; }> = []
  datasets: any[] = []
  // stockPlatform: any[] = []

  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  channels: any[] = this.platformService?.channels || [];

  constructor(
    private fb: FormBuilder,
    private productApiService: ProductApiService,
    private categoryService: CategoryService,
    private modalService: ModalService,
    private authService: AuthService,
    private platformService: PlatformService
  ) {
    this.form = this.fb.group({
      keyword: [''],
      category_id: [''],
      min_stock: [null],
      max_stock: [null]
    });

  }


  ngOnInit(): void {
    this.getDataProduct()
  }

  getDataProduct() {
    this.loading = true;
    const value = this.form.getRawValue()
    const cleanFilter = Object.fromEntries(
      Object.entries(value).filter(([key, value]) => value !== null && value !== '')
    );
    const filters = {
      ...cleanFilter,
      fields: 'sku,name,category,in_stock,prices',
      limit: this.limit,
      offset: this.offset
    }
    console.log(filters);

    this.productApiService.getAllProduct(filters).then(async (res: any) => {
      console.log(res);
      if (res.data) {
        this.products = res.data;
        this.countDataSource = res.count;
        const uniqueSku = res.data.map((e: { sku: any; }) => e.sku)
        if (uniqueSku.length > 0) {
          this.priceList = await this.searchPriceList(uniqueSku)
          // this.stockPlatform = await this.getAllProductPlatform(uniqueSku)
        }

      } else {
        this.products = [];
        this.countDataSource = 0;
      }
      this.reformattingDataset()
      this.loading = false;
    })
  }

  async searchPriceList(sku: string[]) {
    const filters: any = { sku: sku.join(), is_last: true, fields: 'id,sku' }
    return await this.productApiService.searchPriceList(filters).then((res: any) => {
      if (res.success) {
        return res.data;
      }
    })
  }

  async getAllProductPlatform(sku: string[]) {
    const filters: any = { sku: sku.join(), fields: 'sku,channel,sellable' }
    return await this.productApiService.getAllProductPlatform(filters).then((res: any) => {
      if (res.success) {
        return res.data;
      }
    })
  }

  reformattingDataset() {
    const uniquePriceListSku = this.priceList.map(e => e.sku);
    this.datasets = this.products.map(e => ({ ...e, is_price_list: uniquePriceListSku.includes(e.sku) }));
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getDataProduct()
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getDataProduct()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  addProductPriceList(sku: string) {
    if (!this.canAccess()) {
      this.modalService.openModal('info', `ขออภัย`, 'สิทธิ์ของคุณไม่สามารถดำเนินการได้')
      return;
    }

    const data = { skus: [sku] }
    this.productApiService.createPriceList(data).then((res: any) => {
      if (res.success) {
        const index = this.datasets.findIndex(e => e.sku === sku)
        if (index !== -1) {
          this.datasets[index].is_price_list = true;
        }
      } else {
        this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มสินค้าใน Price List ได้ในขณะนี้')
      }
    })
  }

  canAccess() {
    return this.authService.user?.role ? ['admin', 'marketing'].includes(this.authService.user.role) : false;
  }

  // getStock(channel_id: string, sku: string) {
  //   return this.stockPlatform.find(e => e.channel === channel_id && e.sku === sku)?.sellable
  // }

}
