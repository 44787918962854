<div class=" bg-white w-fit max-w-96 h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        Check List
    </div>
    <div class="flex flex-col gap-4 w-full md:w-80 text-sm md:text-base">
        @for (item of checkList; track $index) {
        <div class="">
            <div class="w-full flex space-x-2">
                <input type="checkbox" [name]="item.channel" [id]="item.channel" [(ngModel)]="item.checked" class="" />
                <label [for]="item.channel" class="" [ngClass]="{'line-through': item.checked}">{{item.name}}</label>
            </div>
            <div class="flex pl-7 space-x-2">
                <div class="text-xs text-gray-500">{{getDateString(item.date)}}, {{getUserName(item.user_id)}}</div>
            </div>
        </div>
        }
    </div>

    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button mat-dialog-close class="btn-sm btn-light min-w-[8rem]" (click)="close()">
                <div class="w-full text-center">
                    ยกเลิก
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]" (click)="confirm()">
                <div class="w-full text-center">
                    อัพเดต
                </div>
            </button>
        </div>
    </div>
</div>