import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../core/services/util/util.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { UpdateNoteDialogComponent } from '../../components/update-note-dialog/update-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PriceListApiService } from '../../services/api/price-list-api.service';
import { environment } from '../../../../../environments/environment';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { ValidatingDialogComponent } from '../../components/validating-dialog/validating-dialog.component';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';

interface PriceList {
  sku: string;
  name?: string;
  cost: number;
  created: any;
  id: string;
  is_last: boolean;
  note: string;
  online_price: number;
  price_campaign: PriceCampaign[];
  retail_price: number;
  updated: any;
  visible: boolean;
}
interface PriceCampaign {
  id: string;
  price: number;
}

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrl: './price-list.component.css'
})
export class PriceListComponent implements OnInit {

  webConfig = environment.webConfig;

  faQuestionCircle = faQuestionCircle;
  faPlusCircle = faPlusCircle;

  displayedColumns: Array<{ id: string; name: string; note: string; priority: number; color: string; rowspan: number; }> = [];
  fieldSelected: any

  campaignGroup: Array<{ id: string; name: string; note: string; priority: number; color: string; }> = [];
  priceCampaign: Array<{ id: string; name: string; campaign_group_id: string; }> = []
  priceList: PriceList[] = []
  campaignData: Array<{ id: string; name: string; campaign_group_id: string; priority: number; color: string; }> = [];

  selectedRow: any;
  selectedPriceList: PriceList[] = []

  form: FormGroup;
  loading: boolean = true;

  dataSource: any[] = [];
  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  price: any;
  isEditing: any = [];

  constructor(
    private utilService: UtilService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private priceListApiService: PriceListApiService,
    private modalService: ModalService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      keyword: [''],
      visible: ['true'],
      is_price: [''],
    });
    this.displayedColumns = [
      { id: 'retail_price', name: 'Retail', note: '', priority: -4, color: '#fcf1cd', rowspan: 2 },
      { id: 'online_price', name: 'Online', note: '', priority: -3, color: '#fcf1cd', rowspan: 2 },
      { id: 'cost', name: 'ทุน (ช่อง5)', note: '', priority: -2, color: '#fff700', rowspan: 2 },
      { id: 'cost_platform', name: `ทุน (+${this.webConfig.percentPriceList}%)`, note: '', priority: -1, color: '#ff7d00', rowspan: 2 },
    ]
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([
      this.getPriceCampaign(),
      this.getCampaignGroup()
    ])
    this.setFormattingCampaign();
    this.setFormattingField();
    this.getPriceListData();
  }

  async getPriceCampaign() {
    await this.priceListApiService.getAllPriceCampaign().then((res: any) => {
      if (res.success) {
        this.priceCampaign = res.data;
      }
    })
  }

  async getCampaignGroup() {
    await this.priceListApiService.getAllCampaignGroup().then((res: any) => {
      if (res.success) {
        this.campaignGroup = res.data;
      }
    })
  }

  getPriceListData() {
    this.loading = true;
    const filters: any = { offset: this.offset, limit: this.limit, is_last: true }
    const value = this.form.getRawValue();

    if (value.keyword) {
      filters['keyword'] = value.keyword
    }
    if (value.visible) {
      filters['visible'] = value.visible
    }
    if (value.is_price) {
      filters['is_price'] = value.is_price
    }

    this.priceListApiService.getAllPriceList(filters).then((res: any) => {
      if (res.success) {
        this.priceList = res.data;
        this.countDataSource = res.count;
        this.setFormattingData();
      }
      this.loading = false;
    })
  }

  setFormattingNote(note: string) {
    return note?.replace(/\n/g, '<br>') || '-';
  }

  searchPriceList(sku: string) {
    this.selectedPriceList = []
    const filters: any = { sku: sku, is_last: false }
    this.priceListApiService.searchPriceList(filters).then((res: any) => {
      if (res.success) {
        this.selectedPriceList = res.data.sort((a: any, b: any) => b.created.date - a.created.date);
      }
    })
  }

  setFormattingCampaign() {
    this.campaignData = []
    for (const group of this.campaignGroup) {
      const filteredData = this.priceCampaign.filter(e => e.campaign_group_id === group.id).map(e => ({ ...e, priority: group.priority, color: group.color }))
      this.campaignData.push(...filteredData)
    }
    this.campaignData.sort((a, b) => a.priority - b.priority)
  }

  setFormattingField() {
    this.displayedColumns.push(...this.campaignGroup.map(e => ({ ...e, rowspan: 1 })))
    this.fieldSelected = this.displayedColumns.map(e => e.id)
  }

  setFormattingData() {
    this.dataSource = this.priceList;
  }

  getColSpan(id: string): number {
    return this.priceCampaign.filter(e => e.campaign_group_id === id)?.length || 1;
  }

  formCreateString(value: any) {
    this.fieldSelected = value;
  }

  checkDisplayColumn(id: string) {
    return this.fieldSelected.includes(id);
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getPriceListData()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  selectedRowOnChange(row: any) {
    if (this.selectedRow === row.id) {
      this.selectedRow = ''
    } else {
      this.selectedRow = row.id
      this.searchPriceList(row.sku)
    }
  }

  dateFormat(date: number) {
    return this.utilService.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

  calculateCostPlatform(cost: number): number {
    return cost * (100 + this.webConfig.percentPriceList) / 100
  }

  getProductPrice(productPriceCampaign: any[], campaignId: string) {
    return productPriceCampaign.find(e => e.id === campaignId)?.price || 0
  }

  toggleEditPrice(priceListId: string, campaignId: string) {
    this.price = null;
    this.isEditing[`${priceListId}${campaignId}`] = this.isEditing[`${priceListId}${campaignId}`] ? !this.isEditing[`${priceListId}${campaignId}`] : true;

    if (this.isEditing[`${priceListId}${campaignId}`]) {
      setTimeout(() => {
        document.getElementById('price-add')?.focus()
      }, 10)
    }
  }

  updatePrice(priceListId: string, campaignId: string) {
    setTimeout(() => {
      document.getElementById('price-add')?.blur()
    }, 10)

    if (!this.canAccess()) {
      this.modalService.openModal('info', `ขออภัย`, 'สิทธิ์ของคุณไม่สามารถแก้ไขได้')
      return;
    }

    if (this.price >= 0) {
      const body = {
        id: campaignId,
        price: this.price
      }
      this.priceListApiService.updatePrice(priceListId, body).then((response: any) => {
        console.log(response)
        if (response.success) {
          const index = this.dataSource.findIndex((item: any) => item.id === priceListId)
          if (index > -1) {
            const idx = this.dataSource[index].price_campaign.findIndex((e: any) => e.id === campaignId)
            if (idx > -1) {
              this.dataSource[index].price_campaign[idx] = body
            } else {
              this.dataSource[index].price_campaign.push(body)
            }
          }
        }
      })
    }
  }

  updateRetail(priceListId: string) {
    setTimeout(() => {
      document.getElementById('price-add')?.blur()
    }, 10)

    if (!this.canAccess()) {
      this.modalService.openModal('info', `ขออภัย`, 'สิทธิ์ของคุณไม่สามารถแก้ไขได้')
      return;
    }

    if (this.price >= 0) {
      this.priceListApiService.updatePriceList(priceListId, { retail_price: this.price }).then((response: any) => {
        console.log(response)
        if (response.success) {
          const index = this.dataSource.findIndex((item: any) => item.id === priceListId)
          if (index !== -1) {
            this.dataSource[index].retail_price = response.data.retail_price
          }
        }
      })
    }
  }

  openNoteDialog(priceListId: string, note: string) {
    if (!this.canAccess()) {
      return;
    }

    const dialogRef = this.dialog.open(UpdateNoteDialogComponent, {
      data: { note: note },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
      if (result.confirm) {
        this.priceListApiService.updatePriceList(priceListId, { note: result.data.note }).then((res: any) => {
          if (res.success) {
            const index = this.dataSource.findIndex(e => e.id === priceListId)
            if (index > -1) {
              this.dataSource[index].note = res.data.note;
            }
          }
        })
      }
    });
  }

  openValidateDialog() {
    this.modalService.openModal('confirm', 'Validate Price List', 'ระบบจะทำการซิงค์ข้อมูลรายการสินค้าที่มีการเปลี่ยนแปลงราคา ต้องการดำเนินการต่อหรือไม่?').then((result) => {
      if (result) {
        const dialogRef = this.dialog.open(ValidatingDialogComponent, { disableClose: true });
        this.priceListApiService.syncAllProduct().then((response: any) => {
          this.priceListApiService.validatePriceList().then((res: any) => {
            dialogRef.close()
            if (res.success) {
              this.modalService.openModal('success', 'สำเร็จ', 'ซิงค์ข้อมูลรายการสินค้าที่มีการเปลี่ยนแปลงราคาเรียบร้อยแล้ว')
              this.getPriceListData();
            } else {
              this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถซิงค์ข้อมูลได้ กรุณาลองใหม่อีกครั้งในภายหลัง')
            }
          })
        })
      }
    })
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getPriceListData();
  }


  callbackActiveToggle = (check: boolean, id: string) => {
    const priceListId = id.replace('toggle-', '')

    this.priceListApiService.updatePriceList(priceListId, { visible: check }).then((res: any) => {
      if (res.success) {
        const index = this.dataSource.findIndex(e => e.id === priceListId)
        if (index > -1) {
          this.dataSource[index].visible = res.data.visible;
        }
      }
    })

  }

  addPriceList(product: any) {
    if (!this.canAccess()) {
      this.modalService.openModal('info', `ขออภัย`, 'สิทธิ์ของคุณไม่สามารถแก้ไขได้')
      return;
    }

    this.modalService.openModal('confirm', `เพิ่มรายการ ${product.name}`, 'ระบบจะทำการเพิ่มข้อมูล Price List ใหม่ ด้วยราคาทุนเท่าเดิม ต้องการดำเนินการต่อหรือไม่?').then((result) => {
      if (result) {
        this.priceListApiService.createPriceList(product.sku).then((res: any) => {
          if (res.success) {
            this.modalService.openModal('success', 'สำเร็จ', 'เพิ่มข้อมูล Price List สำเร็จ')
            this.getPriceListData();
          } else {
            this.modalService.openModal('fail', 'เกิดข้อผิดพลาด', 'ไม่สามารถเพิ่มข้อมูลได้ กรุณาลองใหม่อีกครั้งในภายหลัง')
          }
        })
      }
    })
  }

  canAccess() {
    return this.authService.user?.role ? ['admin', 'marketing'].includes(this.authService.user.role) : false;
  }

}
