export const environment = {
    endpoint: 'https://mservice-dev.maxxgadget.com/platform-management',
    authEndpoint: 'https://mservice-dev.maxxgadget.com/authen',
    liffId: '1653990310-NxM3R45P',
    webConfig: {
        percentSetList: 30,
        costPriceIndex: 5,
        srpPriceIndex: 0,
        percentPriceList: 30
    },
    systemId: 'platform-management'
};