import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-campaign-dialog',
  templateUrl: './create-campaign-dialog.component.html',
  styleUrl: './create-campaign-dialog.component.css'
})
export class CreateCampaignDialogComponent implements OnInit {

  name: string = '';
  note: string = '';
  color: string = '#000000';

  tmpPriceCampaign: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<CreateCampaignDialogComponent>
  ) {

  }


  ngOnInit(): void {
   this.color = '#000000';

  }

  removePrice(priceId: string) {
    this.tmpPriceCampaign = this.tmpPriceCampaign.filter((e: any) => e.id !== priceId)
  }

  addPrice() {
    this.tmpPriceCampaign.push({ id: `new_${+new Date()}`, name: ' ' })
  }

  priceNameChange(priceId: string, event: any) {
    const idx = this.tmpPriceCampaign.findIndex((e: any) => e.id === priceId)
    this.tmpPriceCampaign[idx].name = event.target.value
  }

  close() {
    this.ref.close({ confirm: false });
  }

  confirm() {
    const dataCreate = {
      name: this.name,
      note: this.note,
      color: this.color,
      priceCampaign: this.tmpPriceCampaign.filter((e: any) => e.name)
    }
    this.ref.close({ confirm: true, data: dataCreate });
  }

}
