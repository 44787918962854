import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductApiService } from '../../services/api/product-api.service';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { CategoryService } from '../../../../core/services/category/category.service';
import { UpdateStockModalComponent, UpdateStockModalModel } from '../../components/update-stock-modal/update-stock-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SyncProductDialogComponent } from '../../components/sync-product-dialog/sync-product-dialog.component';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrl: './product-stock.component.css'
})
export class ProductStockComponent implements OnInit {
  loading: boolean = false;
  form: FormGroup;

  countDataSource = 0;
  pageSize = 10;
  pageIndex = 0;
  limit = 10;
  offset = 0;

  datasets: any[] = []
  categories: Array<{ id: string; name: string; }> = this.categoryService?.categories || []
  products: Array<{ sku: string; name: string; category: Object; in_stock: number; prices: Object; }> = []
  stockPlatform: any[] = []
  channels: any[] = this.platformService?.channels || [];
  channelsHeaders: any[] = []


  constructor(
    private fb: FormBuilder,
    private productApiService: ProductApiService,
    private platformService: PlatformService,
    private categoryService: CategoryService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      keyword: [''],
      category_id: [''],
      min_stock: [null],
      max_stock: [null]
    });
    this.channelsHeaders = [...new Set(this.channels.map(item => item.platform))]
  }

  ngOnInit(): void {
    this.getDataProduct()
  }

  getDataProduct() {
    this.loading = true;
    const value = this.form.getRawValue()
    const cleanFilter = Object.fromEntries(
      Object.entries(value).filter(([key, value]) => value !== null && value !== '')
    );
    const filters = {
      ...cleanFilter,
      fields: 'sku,name,category,in_stock,prices',
      limit: this.limit,
      offset: this.offset
    }
    console.log(filters);

    this.productApiService.getAllProduct(filters).then(async (res: any) => {
      console.log(res);
      if (res.data) {
        this.products = res.data;
        this.countDataSource = res.count;
        const uniqueSku = res.data.map((e: { sku: any; }) => e.sku)
        if (uniqueSku.length > 0) {
          this.stockPlatform = await this.getAllProductPlatform(uniqueSku)
        }

      } else {
        this.products = [];
        this.countDataSource = 0;
      }
      this.reformattingDataset()
      this.loading = false;
    })
  }

  async getAllProductPlatform(sku: string[]) {
    const filters: any = { sku: sku.join(), fields: 'sku,channel,sellable,platform_product_id,platform_sku,updated' }
    return await this.productApiService.getAllProductPlatform(filters).then((res: any) => {
      if (res.success) {
        return res.data;
      }
    })
  }

  callBackPageChange = (data: any): void => {
    if (this.offset !== data.offset || this.limit !== data.limit) {
      this.offset = data.offset;
      this.limit = data.limit;
      this.getDataProduct()
    }

    if (data.pageIndex !== undefined) {
      this.pageIndex = data.pageIndex;
    }
    if (data.limit !== undefined) {
      this.pageSize = data.limit;
    }
  }

  reformattingDataset() {
    this.datasets = this.products.map(item => ({
      ...item,
      stocks: this.stockPlatform.filter(el => el.sku === item.sku).map(el => ({
        ...el,
        channel_name: this.channels.find(channel => channel.shop_ref === el.channel)?.platform_name,
        channel_logo_url: this.channels.find(channel => channel.shop_ref === el.channel)?.logo_url,
        platform: this.channels.find(channel => channel.shop_ref === el.channel)?.platform
      }))
    }))
    console.log(this.datasets)
    console.log(this.channels)
  }

  submitForm() {
    this.offset = 0;
    this.pageIndex = 0;
    this.getDataProduct()
  }

  getStockByPlatform(platform: string, stocks: any[]) {
    return stocks.filter(item => item.platform === platform)
  }

  openUpdateStockDialog(sku: string) {
    const product = this.products.find(item => item.sku === sku)
    const stocks = this.stockPlatform.filter(item => item.sku === sku)
    if (stocks.length === 0) return
    console.log(this.channels)
    console.log(product)
    console.log(stocks)
    if (product) {
      const data: UpdateStockModalModel = {
        sku: sku,
        name: product?.name,
        stock: product?.in_stock,
        products: [{ sku }],
        channels: stocks.map(item => ({
          channel: item.channel,
          name: this.channels.find(el => el.shop_ref === item.channel)?.platform_name,
          platform: this.channels.find(el => el.shop_ref === item.channel)?.platform,
          stock: item.sellable,
          updated_date: item.updated.date
        }))
      }
      const dialogRef = this.dialog.open(UpdateStockModalComponent, {
        data,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (result.confirm) {

        }
      });
    }
  }

  openSyncProductDialog() {
    const dialogRef = this.dialog.open(SyncProductDialogComponent);

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    })
  }

  canAccess() {
    return this.authService.user?.role ? ['admin', 'marketing'].includes(this.authService.user.role) : false;
  }

}
