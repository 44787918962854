import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BaseChartDirective } from 'ng2-charts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter"
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MainComponent } from './core/pages/main/main.component';
import { HeaderComponent } from './core/components/header/header.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { ModalComponent } from './core/components/modal/modal.component';
import { DataAnalysisComponent } from './features/data-analysis/pages/data-analysis/data-analysis.component';
import { OrderComponent } from './features/order/pages/order/order.component';
import { ProductSetComponent } from './features/product-set/pages/product-set/product-set.component';
import { LoaderComponent } from './core/components/loader/loader.component';
import { LineChartComponent } from './core/components/line-chart/line-chart.component';
import { PaginationComponent } from './core/components/pagination/pagination.component';
import { MonthYearPickerComponent } from './core/components/month-year-picker/month-year-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupBarChartComponent } from './core/components/group-bar-chart/group-bar-chart.component';
import { ProfileComponent } from './core/pages/profile/profile.component';
import { SelectDateOptionComponent } from './core/components/select-date-option/select-date-option.component';
import { DataAnalysisProductComponent } from './features/data-analysis/components/data-analysis-product/data-analysis-product.component';
import { ToggleComponent } from './core/components/toggle/toggle.component';
import { DataAnalysisProductOverviewComponent } from './features/data-analysis/components/data-analysis-product-overview/data-analysis-product-overview.component';
import { DataAnalysisSaleComponent } from './features/data-analysis/components/data-analysis-sale/data-analysis-sale.component';
import { SettingComponent } from './features/setting/pages/setting/setting.component';
import { ProductListComponent } from './features/product/pages/product-list/product-list.component';
import { DataAnalysisProductSetComponent } from './features/data-analysis/components/data-analysis-product-set/data-analysis-product-set.component';
import { ProductSetDetailComponent } from './features/product-set/pages/product-set-detail/product-set-detail.component';
import { PriceListComponent } from './features/price-list/pages/price-list/price-list.component';
import { UpdateNoteDialogComponent } from './features/price-list/components/update-note-dialog/update-note-dialog.component';
import { ValidatingDialogComponent } from './features/price-list/components/validating-dialog/validating-dialog.component';
import { SettingCampaignComponent } from './features/setting/pages/setting-campaign/setting-campaign.component';
import { UpdateCampaignDialogComponent } from './features/setting/components/update-campaign-dialog/update-campaign-dialog.component';
import { CreateCampaignDialogComponent } from './features/setting/components/create-campaign-dialog/create-campaign-dialog.component';
import { UpdateProductSetNameComponent } from './features/product/components/update-product-set-name/update-product-set-name.component';
import { UpdateProductSetCheckListComponent } from './features/product/components/update-product-set-check-list/update-product-set-check-list.component';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { SettingPermissionComponent } from './features/setting/pages/setting-permission/setting-permission.component';
import { AccessDeniedComponent } from './core/pages/access-denied/access-denied.component';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { SettingChannelComponent } from './features/setting/pages/setting-channel/setting-channel.component';
import { UpdateChannelDialogComponent } from './features/setting/components/update-channel-dialog/update-channel-dialog.component';
import { ProductSetStockComponent } from './features/product-set/pages/product-set-stock/product-set-stock.component';
import { ProductStockComponent } from './features/product/pages/product-stock/product-stock.component';
import { UpdateStockModalComponent } from './features/product/components/update-stock-modal/update-stock-modal.component';
import { SyncProductDialogComponent } from './features/product/components/sync-product-dialog/sync-product-dialog.component';
import { CreateProductSetComponent } from './features/product-set/pages/create-product-set/create-product-set.component';
import { UpdateStockSetModalComponent } from './features/product-set/components/update-stock-set-modal/update-stock-set-modal.component';
import { PriceListMappingComponent } from './features/price-list/pages/price-list-mapping/price-list-mapping.component';
import { StockPromotionListComponent } from './features/stock-promotion/pages/stock-promotion-list/stock-promotion-list.component';
import { AddStockPromotionDialogComponent } from './features/product/components/add-stock-promotion-dialog/add-stock-promotion-dialog.component';
import { PurchaseAnalysisComponent } from './features/purchase-analysis/pages/purchase-analysis/purchase-analysis.component';
import { PurchaseAnalysisProductComponent } from './features/purchase-analysis/components/purchase-analysis-product/purchase-analysis-product.component';
import { PurchaseAnalysisProductOverviewComponent } from './features/purchase-analysis/components/purchase-analysis-product-overview/purchase-analysis-product-overview.component';
import { PurchaseAnalysisSaleComponent } from './features/purchase-analysis/components/purchase-analysis-sale/purchase-analysis-sale.component';
import { ProductTagManagementComponent } from './features/purchase-analysis/components/product-tag-management/product-tag-management.component';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    NavbarComponent,
    ModalComponent,
    DataAnalysisComponent,
    OrderComponent,
    ProductSetComponent,
    LoaderComponent,
    LineChartComponent,
    PaginationComponent,
    MonthYearPickerComponent,
    GroupBarChartComponent,
    ProfileComponent,
    SelectDateOptionComponent,
    DataAnalysisProductComponent,
    ToggleComponent,
    DataAnalysisProductOverviewComponent,
    DataAnalysisSaleComponent,
    SettingComponent,
    CreateProductSetComponent,
    ProductListComponent,
    DataAnalysisProductSetComponent,
    ProductSetDetailComponent,
    PriceListComponent,
    UpdateNoteDialogComponent,
    ValidatingDialogComponent,
    SettingCampaignComponent,
    UpdateCampaignDialogComponent,
    CreateCampaignDialogComponent,
    UpdateProductSetNameComponent,
    UpdateProductSetCheckListComponent,
    ForbiddenComponent,
    SettingPermissionComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    SettingChannelComponent,
    UpdateChannelDialogComponent,
    ProductSetStockComponent,
    ProductStockComponent,
    UpdateStockModalComponent,
    SyncProductDialogComponent,
    UpdateStockSetModalComponent,
    StockPromotionListComponent,
    AddStockPromotionDialogComponent,
    PriceListMappingComponent,
    StockPromotionListComponent,
    PurchaseAnalysisComponent,
    PurchaseAnalysisProductComponent,
    PurchaseAnalysisProductOverviewComponent,
    PurchaseAnalysisSaleComponent,
    ProductTagManagementComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    BaseChartDirective,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    FormsModule,
    NgxChartsModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  providers: [
    provideAnimationsAsync(),
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {

  }
}
