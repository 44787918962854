import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  activatedRoute(url?: string) {
    // เข้าถึงค่า expectedRole จาก ActivatedRoute
    let index = -1;
    if (url) {
      index = this.router.config.findIndex((item: any) => item.path === url.slice(1));
    } else {
      index = this.router.config.findIndex((item: any) => item.path === this.router.url.slice(1));
    }
    if (index > -1) {
      const data: any = this.router.config[index].data || null;
      if (data && data.expectedRole) {
        const expectedRole: string[] = data.expectedRole
        return this.authService.user?.role ? expectedRole.includes(this.authService.user.role) : false;
      } else {
        return true
      }
    } else {
      return false
    }
  }

}
