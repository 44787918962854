<div class="bg-white w-screen max-w-lg h-fit flex flex-col justify-center items-center p-6 rounded-lg space-y-6">
    <div>
        <div class="font-semibold text-center">
            ต้องการอัพเดตสต๊อก
        </div>
        <div class="font-semibold text-center">
            {{productDetials.sku}} - {{productDetials.name}}
        </div>
    </div>
    <div class="flex flex-col gap-4 w-full py-6">
        <div class="flex justify-between">
            <div>จำนวนในคลัง</div>
            @if (updateData) {
            @if (updateStockResult === undefined) {
            <div class="flex justify-end space-x-2 items-center">
                <mat-spinner [diameter]="20"></mat-spinner>
                <div>อัพเดตข้อมูล</div>
            </div>
            }
            @if (updateStockResult === true){
            <div class="text-lime-500">สำเร็จ</div>
            }
            @if (updateStockResult === false){
            <div class="text-red-500">ไม่สำเร็จ</div>
            }
            } @else {
            <div>{{this.productDetials.stock}}</div>
            }
        </div>
        @for (channel of productDetials.channels; track $index) {
        <div class="flex justify-between">
            <div>{{channel.name}}</div>
            @if (updateData) {

            @if (updateResult[channel.channel] === undefined) {
            <div class="flex justify-end space-x-2 items-center">
                <mat-spinner [diameter]="20"></mat-spinner>
                <div>อัพเดตข้อมูล</div>
            </div>
            }
            @if (updateResult[channel.channel] === true){
            <div class="text-lime-500">สำเร็จ</div>
            }
            @if (updateResult[channel.channel] === false){
            <div class="text-red-500">ไม่สำเร็จ</div>
            }
            } @else {
            <div>{{channel.stock}}</div>
            }
        </div>
        }
    </div>

    <div class="flex justify-center space-x-2 pt-4">
        <div>
            <button class="btn-sm btn-primary min-w-[8rem]" type="button" (click)="onConfirmUpdate()"
                [disabled]="updating">
                <div class="w-full text-center">
                    {{ updating ? 'กำลังอัพเดต': updateSuccess ? 'อัพเดตอีกครั้ง' : 'ยืนยันอัพเดต'}}
                </div>
            </button>
        </div>
        <div>
            <button class="btn-sm btn-light min-w-[8rem]" type="button" (click)="close()">
                <div class="w-full text-center">
                    เสร็จสิ้น
                </div>
            </button>
        </div>

    </div>

</div>