<div class="content gap-4 grid lg:grid-cols-3">
    <div class="relative col-span-2">
        <div class="h-32 rounded-t-2xl bg-gradient-to-r from-cyan-500 to-blue-500"></div>
        <div class="h-32 bg-white rounded-b-2xl shadow-sm"></div>
        <div class="absolute top-20 right-0 w-full">
            <div class="md:flex justify-between px-6">
                <div class="flex items-end space-x-4">
                    <div
                        class="flex justify-center items-center w-32 h-32 rounded-full bg-white relative border-4 border-gray-200">
                        <img alt="profile" class="rounded-full aspect-square" [src]="user?.pictureUrl"
                            onerror="this.onerror=null; this.src='assets/images/user.png'">
                    </div>
                    <div>
                        <div class="text-xl font-bold">{{user?.displayName}}</div>
                        <div class="text-base text-secondary-500"> {{permissionService.getRole(user?.role||'')}} </div>
                    </div>
                </div>
                <div class="mt-4 flex items-end space-x-4">
                    <button (click)="logout()" class="btn btn-light w-full flex justify-center items-center space-x-2">
                        <fa-icon [icon]="faArrowRightFromBracket" class=""></fa-icon>
                        <div>ออกจากระบบ</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white border border-basic-100 rounded-2xl p-6 col-span-1">
        <div class="mb-4">
            สิทธิ์การใช้งาน
        </div>
        <div *ngIf="userInfo?.permissions" class="flex flex-col gap-3">
            <div *ngFor="let permission of userInfo.permissions">
                <button class="!w-full h-10 !text-base flex items-center justify-centerb btn"
                    [ngClass]="{'label-primary': permission.selected, 'label-light opacity-60': !permission.selected}"
                    *ngIf="permission.active" (click)="selectedPermission(permission.role)">
                    {{permissionService.getRole(permission?.role || '')}}
                </button>
            </div>
        </div>
    </div>
</div>