import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductApiService } from '../../../product/services/api/product-api.service';
import { faChevronLeft, faCopy, faPencil, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../core/services/modal/modal.service';
import { Location } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { UpdateProductSetNameComponent } from '../../../product/components/update-product-set-name/update-product-set-name.component';
import { PlatformService } from '../../../../core/services/platform/platform.service';
import { UtilService } from '../../../../core/services/util/util.service';
import { UserService } from '../../../../core/services/user/user.service';

interface ProductSet {
  set_sku: string;
  name: string;
  active: boolean;
  products: ProductInfo[]
  total_price: number;
  total_srp: number;
  current_total_srp: number;
  price_change: boolean;
  mark_price_change: boolean;


}
interface ProductInfo {
  sku: string;
  name: string;
  qty: number;
  cost: number;
  cost_percent: number;
  srp_price: number;
  price: number;
  current_cost: number;
  current_srp_price: number;
  current_cost_percent: number;
}


@Component({
  selector: 'app-product-set-detail',
  templateUrl: './product-set-detail.component.html',
  styleUrl: './product-set-detail.component.css'
})
export class ProductSetDetailComponent implements OnInit {
  faCopy = faCopy
  faPencil = faPencil
  faPlusCircle = faPlusCircle

  faChevronLeft = faChevronLeft

  setSku = ''

  webConfig = environment.webConfig;
  costPriceIndex = this.webConfig.costPriceIndex
  srpPriceIndex = this.webConfig.srpPriceIndex
  percent = this.webConfig.percentSetList;

  productSet: Array<ProductSet> = [];
  currentProductSet: any = {}
  loading = false;

  checkList: Array<{ channel: string, name: string, checked: boolean, user_id: string, date: number }> = []
  channels: any[] = [];
  filteredChannels: any[] = [];
  users: any[] = [];
  selectedChannel = ''

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productApiService: ProductApiService,
    private modalService: ModalService,
    private location: Location,
    public dialog: MatDialog,
    private platformService: PlatformService,
    private util: UtilService,
    private userService: UserService
  ) {
    this.channels = this.platformService?.channels || []
    this.users = this.userService.users
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.route.queryParams.subscribe(async (params) => {
      this.setSku = params['set_sku'];
      await this.loadData()
      this.loading = false;
    });
  }

  async loadData() {
    const response: any = await this.productApiService.getSameProductSet(this.setSku)
    console.log(response)
    if (response.success) {
      const productSet = []
      for (const set of response.data) {
        productSet.push(this.formatDataProductDetail(set))
      }
      this.productSet = productSet
      this.currentProductSet = this.productSet.find(item => item.set_sku === this.setSku)
      this.checkList = this.currentProductSet?.check_list || []
      this.setFilterdChannel()
    } else {
      this.router.navigate(['/product-set/list'], {})
    }
  }

  formatDataProductDetail(productSet: any) {
    const products = []
    let current_total_srp = 0
    let total_srp = 0

    for (const product of productSet.products) {
      const cost_percent = product.cost + ((this.percent / 100) * product.cost)
      const current_cost = productSet.product_info.find((item: any) => item.sku === product.sku)?.prices[this.costPriceIndex];
      const current_srp_price = productSet.product_info.find((item: any) => item.sku === product.sku)?.prices[this.srpPriceIndex]
      const current_cost_percent = current_cost + ((this.percent / 100) * current_cost)

      total_srp += (product.srp_price * product.qty)
      current_total_srp += (current_srp_price * product.qty)

      products.push({
        ...product,
        name: productSet.product_info.find((item: any) => item.sku === product.sku)?.name,
        cost_percent,
        current_cost,
        current_srp_price,
        current_cost_percent
      })
    }

    return {
      ...productSet,
      products,
      total_srp,
      current_total_srp
    }
  }

  copyProduct() {
    const setSku = this.productSet[0]?.set_sku
    this.router.navigate(['/product-set/create'], { queryParams: { action: 'copy', data: setSku } })
  }

  callbackActiveToggle = (event: boolean, id: string) => {
    const setSku = id.split('-')[2]
    this.productApiService.updateProductSet(setSku, { active: event }).then((res: any) => {
      if (res.success && res.data) {
        this.modalService.openModal('success', 'บันทึกสำเร็จ', '')
      } else {
        this.modalService.openModal('fail', 'บันทึกไม่สำเร็จ', '').then(res => {
          const element: any = document.querySelector(`#${id}`)
          element.checked = !event
        })
      }
      this.loadData()
    })
  }

  back() {
    this.location.back();
  }

  showPriceChangeDetail(setSku: string) {
    this.router.navigate(['/product-set/detail'], { queryParams: { set_sku: setSku } })
  }

  async openNameDialog(name: string) {
    const dialogRef = this.dialog.open(UpdateProductSetNameComponent, {
      data: {
        value: name,
        modalName: 'อัพเดตชื่อเซ็ตสินค้า',
        displayCheck: true,
        checkName: 'อัพเดตทั้งหมดที่เซ็ตเหมือนกัน',
        type: 'text'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(result);
      if (result.confirm) {
        if (result.data.updateAll) {
          for (const productset of this.productSet) {
            await this.productApiService.updateProductSet(productset.set_sku, { name: result.data.value })
          }
          this.loadData()
        } else {
          this.productApiService.updateProductSet(this.setSku, { name: result.data.value }).then((res: any) => {
            if (res.success) {
              this.loadData()
            }
          })
        }
      }
    });
  }

  async openNoteDialog(note: string) {
    const dialogRef = this.dialog.open(UpdateProductSetNameComponent, {
      data: {
        value: note,
        modalName: 'อัพเดตหมายเหตุ',
        displayCheck: false,
        type: 'textarea'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      console.log(result);
      if (result.confirm) {
        this.productApiService.updateProductSet(this.setSku, { note: result.data.value }).then((res: any) => {
          if (res.success) {
            this.loadData()
          }
        })
      }
    });
  }

  async selectChannel(chennel: string) {
    console.log(chennel)
    console.log(this.channels)
    console.log(this.selectedChannel)
    const newCheckList = {
      channel: chennel,
      checked: false,
      name: this.channels.find(item => item.shop_ref === chennel)?.platform_name
    }

    const updateResponse: any = await this.productApiService.addNewCheckListProductSet(this.currentProductSet.set_sku, newCheckList)
    if (updateResponse.success) {
      this.checkList = updateResponse.data?.check_list || []
      this.setFilterdChannel()
    } else {

    }
    this.selectedChannel = ''
  }

  setFilterdChannel() {
    const mappedChannel = this.checkList.map(item => item.channel)
    this.filteredChannels = this.channels.filter(item => !mappedChannel.includes(item.shop_ref))
  }

  async checkChannel(channel: string) {
    const updateData = {
      "check_list": [
        {
          "channel": channel,
          "checked": this.checkList.find(item => item.channel === channel)?.checked
        }
      ]
    }
    const updateResponse: any = await this.productApiService.updateProductSetCheckList(this.currentProductSet.set_sku, updateData)
    console.log(updateResponse)
    if (updateResponse.success) {
      this.loadData()
    } else {

    }
  }

  async removeCheckList(channel: string) {
    console.log(this.checkList)
    const updateResponse: any = await this.productApiService.removeCheckListProductSet(this.currentProductSet.set_sku, channel)
    console.log(updateResponse)
    if (updateResponse.success) {
      this.checkList = updateResponse.data?.check_list || []
      this.setFilterdChannel()
    } else {

    }
  }

  getCheckedCheckLict() {
    return this.checkList.filter(item => item.checked).length
  }

  getUserName(userId: string) {
    return this.users.find(item => item.user_id === userId)?.name
  }

  getDateString(date: number) {
    return this.util.dayjs(date).format('DD/MM/YYYY, HH:mm')
  }

}
